import React, { useEffect, useState } from 'react'
import { Link , useNavigate, useParams } from 'react-router-dom';
import bg5 from '../../assets/images/hero/bg5.jpg';
import image from '../../assets/images/team/01.jpg';
import shree_logo from '../../assets/images/company/shree-logo.png';
import circle_logo from '../../assets/images/company/circle-logo.png';
import Navbar from '../../components/Navbar'
import CandidateDetailComp from '../../components/candidate-detail-comp';
import Footer from '../../components/Footer';
import {Settings, FileText, Gift, Globe, Home, Mail, MapPin, Phone, Server } from 'react-feather';
import { LuMail, BiLogoDribbble, AiOutlineBehance, BiLogoLinkedin, FaFacebookF, IoLogoTwitter,FaInstagram, FiFileText} from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';
import { CandidateList } from '../../data/data';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCandidates, getAppliedCandidates, getCandidateInfo, setLoader, uploadFiles } from '../../actions';
import { getAllCandidatesSelector, getSelectedCandidateSelector, isLoggedInSelector } from '../../reducers/selectors/user.selector';
import { getUserIdSelector } from '../../reducers/selectors/user.selector';
import CandidatesList from '../allcandidates/candidateslist';
import { getAppliedCandidatesSelector, getListingsSelector } from '../../reducers/selectors/listings.selector';

export default function Applications() {
    const params = useParams();
    const listingId = params.listingId;

    // const id = '65761416f663060da05afa0c'

    
    const candidateList = useSelector(getAppliedCandidatesSelector);
    const candidate = useSelector(getSelectedCandidateSelector);
    

    useEffect(() => {
        console.log('0...',candidateList);
        dispatch(setLoader(true));
        dispatch(getAppliedCandidates(listingId));
    },[])

    useEffect(() => {
        console.log('0...',candidateList);
    },[candidateList])

    const dispatch = useDispatch();
    const userId = useSelector(getUserIdSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);
    const listings = useSelector(getListingsSelector);
    const navigate = useNavigate();
    const [selectedListing, setSelectedListing] = useState();
    useEffect(() => {
        const filtered = listings?.filter(l => l.id === listingId);
        if(filtered){
            setSelectedListing(filtered[0]);
        }
    },[listings])

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>

                {isLoggedIn && <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        {/* {compFrom === 'srch' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/searchcandidates">Search Candidates</Link></li>}
                        {compFrom === 'app' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to={`/applications/${listingId}`}>Applications</Link></li>}
                        {compFrom === 'all' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to={`/allcandidates`}>All Candidates</Link></li>} */}
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">{selectedListing?.title+' Applications'} </li>
                    </ul>
                </div>}
            </section>
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
            {candidateList && <CandidatesList allCandidates={candidateList} listingId={listingId} compFrom={'app'}/>}
            </section>


            
            {/* <section className='md:pb-24 pb-16'>
                <ExploreJob/>
            </section> */}
            <Footer />


        </>
    )
}
