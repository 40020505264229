import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar';
import bg5 from "../../assets/images/hero/bg5.jpg"
import team1 from "../../assets/images/team/01.jpg"
import { Link } from 'react-router-dom';
import { Twitter, Facebook, Instagram, Linkedin, Youtube, Loader } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getPasswordChangeMessageSelector, getSubscriptionResponseSelector, getUserIdSelector, getUserInfoSelector } from '../../reducers/selectors/user.selector';
import { cancelSubscription, changePassword, resetPasswordChangeMessage, setLoader, updateJobNotificaitonsStatus, updateUserInfo } from '../../actions';
import { MdPayment, MdSubscriptions } from 'react-icons/md';
import { products } from '../../constants';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import Footer from '../Footer';

export default function Profile() {

    const [editPersonalDetails, setEditPersonalDetails] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [linkedInUrl, setLinkedInUrl] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [editSocialDetails, setEditSocialDetails] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState('');


    const userInfo = useSelector(getUserInfoSelector);
    const userId = useSelector(getUserIdSelector);
    const passwordChangeMessage = useSelector(getPasswordChangeMessageSelector);
    const loader = useSelector(activateLoaderSelector);
    const subscriptionResponse = useSelector(getSubscriptionResponseSelector);

    const dispatch = useDispatch();


    useEffect(() => {
        dispatch(resetPasswordChangeMessage());
    }, [])
    useEffect(() => {
        setFirstName(userInfo.firstName);
        setLastName(userInfo.lastName);
        setEmail(userInfo.emailId);
        setPhone(userInfo.mobile);
        setTwitterUrl(userInfo?.twitterUrl);
        setFacebookUrl(userInfo?.facebookUrl);
        setLinkedInUrl(userInfo?.linkedInUrl);
        setYoutubeUrl(userInfo?.youtubeUrl);
        setInstagramUrl(userInfo?.instagramUrl);

        const selectedProduct = products.filter(p => p.productId === userInfo?.subscriptionInfo?.subscriptionPlanId);
        if(selectedProduct && selectedProduct.length > 0){
            setSelectedSubscription(selectedProduct[0]);
        }
        
    }, [userInfo])

    useEffect(() => {
        if (!editPersonalDetails) {
            setFirstName(userInfo.firstName);
            setLastName(userInfo.lastName);
            setEmail(userInfo.emailId);
            setPhone(userInfo.mobile);
        }
    }, [editPersonalDetails])

    useEffect(() => {
        if (passwordChangeMessage) {
            console.log('password....')
        }
    }, [passwordChangeMessage])


    const updatePersonalDetails = () => {
        console.log('1...')
        if (firstName !== userInfo.firstName || lastName !== userInfo.lastName || phone !== userInfo.mobile) {
            console.log('2....')
            let payload = {
                userId: userInfo.userId,
                firstName,
                lastName,
                phone,
            }
            dispatch(setLoader(true));
            dispatch(updateUserInfo(payload))
            setEditPersonalDetails(false);
        }
    }

    const changeUserPassword = () => {
        dispatch(setLoader(true));
        dispatch(changePassword(userInfo.userId));
    }

    const updateSocialDetails = () => {
        if (twitterUrl !== userInfo.twitterUrl ||
            instagramUrl !== userInfo.instagramUrl ||
            facebookUrl !== userInfo.facebookUrl ||
            youtubeUrl !== userInfo.facebookUrl ||
            linkedInUrl !== userInfo.linkedInUrl) {
            const payload = {
                userId: userInfo.userId,
                twitterUrl,
                facebookUrl,
                youtubeUrl,
                linkedInUrl,
                instagramUrl
            }
            dispatch(setLoader(true));
            dispatch(updateUserInfo(payload))
            setEditSocialDetails(false);
        }

    }

    const cancelSub = () => {
        console.log('c...',userInfo.userId)
        dispatch(setLoader(true));
        dispatch(cancelSubscription(userInfo.userId))
    }

    const updateJobEmailNotificaitons = () => {
        dispatch(setLoader(true));
        dispatch(updateJobNotificaitonsStatus(userId));
    }


    return (
        <>
            <Navbar navClass='justify-end' isContainerFluid={true} />
            {loader && <Loader/>}
            <section className="relative table md:pt-40 pt-36 w-full bg-gradient-to-b from-emerald-600/20 dark:from-emerald-600/40 via-emerald-600/10 dark:via-emerald-600/20 to-transparent">
                <div className="lg:container container-fluid">
                    <div className="profile-banner relative text-transparent">
                        <input id="pro-banner" name="profile-banner" type="file" className="hidden" />
                        <div className="relative shrink-0">
                            <img src={bg5} className="h-64 w-full object-cover lg:rounded-xl shadow dark:shadow-gray-700" id="profile-banner" alt="" />
                            <label className="absolute inset-0 cursor-pointer" htmlFor="pro-banner"></label>
                        </div>
                    </div>
                    <div className="md:flex mx-4 -mt-12">
                        <div className="md:w-full">
                            <div className="relative flex items-end">
                                <div className="profile-pic text-center">
                                    <input id="pro-img" name="profile-image" type="file" className="hidden" />
                                    <div>
                                        <div className="relative h-28 w-28 max-w-[112px] max-h-[112px] mx-auto">
                                            <img src={team1} className="rounded-full shadow dark:shadow-gray-800 ring-4 ring-slate-50 dark:ring-slate-800" id="profile-image" alt="" />
                                            <label className="absolute inset-0 cursor-pointer" htmlFor="pro-img"></label>
                                        </div>
                                    </div>
                                </div>
                                <div className="ms-4">
                                    <h5 className="text-lg font-semibold">{userInfo?.userType === 'admin' ? userInfo?.firstName : userInfo?.firstName + ' ' + userInfo?.lastName}</h5>
                                    {/* <p className="text-slate-400">Web Designer</p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-16">
                    <div className="grid grid-cols-1 gap-[30px]">
                        <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <h5 className="text-lg font-semibold mb-4">Details :</h5>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                {userInfo?.userType !== 'admin' && <><div>
                                    <label className="form-label font-medium">First Name : <span className="text-red-600">*</span></label>
                                    {!editPersonalDetails && <p className="form-label font-medium">{firstName}</p>}
                                    {editPersonalDetails && <input type="text" value={firstName} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="First Name:" id="firstname" name="name" required=""
                                        onChange={(e) => { setFirstName(e.target.value) }} />}
                                </div>
                                    <div>
                                        <label className="form-label font-medium">Last Name : <span className="text-red-600">*</span></label>
                                        {!editPersonalDetails && <p className="form-label font-medium">{lastName}</p>}
                                        {editPersonalDetails && <input type="text" value={lastName} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="First Name:" id="firstname" name="name" required=""
                                            onChange={(e) => { setLastName(e.target.value) }} />}
                                    </div></>}
                                {userInfo?.userType === 'admin' && <div>
                                    <label className="form-label font-medium">Organization Name : <span className="text-red-600">*</span></label>
                                    {!editPersonalDetails && <p className="form-label font-medium">{firstName}</p>}
                                    {editPersonalDetails && <input type="text" value={firstName} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="First Name:" id="firstname" name="name" required=""
                                        onChange={(e) => { setFirstName(e.target.value) }} />}
                                </div>}
                                <div>
                                    <label className="form-label font-medium">Email : <span className="text-red-600">*</span></label>
                                    {!editPersonalDetails && <p className="form-label font-medium">{email}</p>}
                                </div>
                                <div>
                                    <label className="form-label font-medium">Phone : <span className="text-red-600">*</span></label>
                                    {!editPersonalDetails && <p className="form-label font-medium">{phone}</p>}
                                    {editPersonalDetails && <input type="number" value={phone} className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="First Name:" id="firstname" name="name" required="" />}
                                </div>

                            </div>

                            <div className="grid grid-cols-1">
                                <div className="mt-5">
                                    <label className="form-label font-medium">Intro : </label>
                                    <textarea name="comments" id="comments" className="form-input border border-slate-100 dark:border-slate-800 mt-2 textarea" placeholder="Intro :"></textarea>
                                </div>
                            </div>
                            <div className="grid grid-cols-1">
                                <div className="mt-5">
                                <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                            checked={userInfo?.enableJobNotifications}
                                                            onChange={() => updateJobEmailNotificaitons()}
                                                            />
                                                        <span className="ms-2 text-slate-400">Enable Job Email Notifications</span>
                                                    </label>
                                </div>
                            </div>
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                {editPersonalDetails && <div>
                                    <button id="submitpersonal" name="submitpersonal" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5"
                                        onClick={() => updatePersonalDetails()}>Save Changes</button>
                                </div>}
                                <div>
                                    <button id="editpersonal" name="editpersonal" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5"
                                        onClick={() => { setEditPersonalDetails(!editPersonalDetails) }}>{editPersonalDetails ? 'Stop Edit' : 'Edit'}</button>
                                </div>
                            </div>
                        </div>

                        {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                <div>
                                    <h5 className="text-lg font-semibold mb-4">Contact Info :</h5>

                                    <form>
                                        <div className="grid grid-cols-1 gap-4">
                                            <div>
                                                <label className="form-label font-medium">Phone No. :</label>
                                                <input name="number" id="number" type="number" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Phone :" />
                                            </div>

                                            <div>
                                                <label className="form-label font-medium">Website :</label>
                                                <input name="url" id="url" type="url" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Url :" />
                                            </div>
                                        </div>

                                        <button className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5">Add</button>
                                    </form>
                                </div>

                                <div>
                                    <h5 className="text-lg font-semibold mb-4">Change password :</h5>
                                    <form>
                                        <div className="grid grid-cols-1 gap-4">
                                            <div>
                                                <label className="form-label font-medium">Email :</label>
                                                <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Old password" required="" 
                                                onClick={() => changeUserPassword()}/>
                                            </div>

                                            <div>
                                                <label className="form-label font-medium">New password :</label>
                                                <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="New password" required="" />
                                            </div>

                                            <div>
                                                <label className="form-label font-medium">Re-type New password :</label>
                                                <input type="password" className="form-input border border-slate-100 dark:border-slate-800 mt-2" placeholder="Re-type New password" required="" />
                                            </div>
                                        </div>

                                        <button className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5">Save password</button>
                                    </form>
                                </div>
                            </div>
                        </div> */}

                        <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <h5 className="text-lg font-semibold mb-4">Social Media :</h5>

                            <div className="md:flex">
                                <div className="md:w-1/3">
                                    <span className="font-medium">Twitter</span>
                                </div>

                                <div className="md:w-2/3 mt-4 md:mt-0">
                                    <form>
                                        <div className="form-icon relative">
                                            <Twitter className="w-4 h-4 absolute top-5 start-4" />
                                            {!editSocialDetails && <p className="form-label font-medium mt-1 ps-12">{twitterUrl ? twitterUrl : ' - - - '}</p>}
                                            {editSocialDetails && <input type="text" value={twitterUrl} className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Twitter Profile Name" id="twitter_name" name="name" required=""
                                                onChange={(e) => { setTwitterUrl(e.target.value) }} />}
                                        </div>
                                    </form>

                                    <p className="text-slate-400 mt-1">Add your Twitter username (e.g. jennyhot).</p>
                                </div>
                            </div>

                            <div className="md:flex mt-8">
                                <div className="md:w-1/3">
                                    <span className="font-medium">Facebook</span>
                                </div>

                                <div className="md:w-2/3 mt-4 md:mt-0">
                                    <form>
                                        <div className="form-icon relative">
                                            <Facebook className="w-4 h-4 absolute top-5 start-4" />
                                            {!editSocialDetails && <p className="form-label font-medium mt-1 ps-12">{facebookUrl ? facebookUrl : ' - - - '}</p>}
                                            {editSocialDetails && <input type="text" value={facebookUrl} className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Twitter Profile Name" id="twitter_name" name="name" required=""
                                                onChange={(e) => { setFacebookUrl(e.target.value) }} />}
                                        </div>
                                    </form>

                                    <p className="text-slate-400 mt-1">Add your Facebook username (e.g. jennyhot).</p>
                                </div>
                            </div>

                            <div className="md:flex mt-8">
                                <div className="md:w-1/3">
                                    <span className="font-medium">Instagram</span>
                                </div>

                                <div className="md:w-2/3 mt-4 md:mt-0">
                                    <form>
                                        <div className="form-icon relative">
                                            <Instagram className="w-4 h-4 absolute top-5 start-4" />
                                            {!editSocialDetails && <p className="form-label font-medium mt-1 ps-12">{instagramUrl ? instagramUrl : ' - - - '}</p>}
                                            {editSocialDetails && <input type="text" value={instagramUrl} className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Twitter Profile Name" id="twitter_name" name="name" required=""
                                                onChange={(e) => { setInstagramUrl(e.target.value) }} />}
                                        </div>
                                    </form>

                                    <p className="text-slate-400 mt-1">Add your Instagram username (e.g. jennyhot).</p>
                                </div>
                            </div>

                            <div className="md:flex mt-8">
                                <div className="md:w-1/3">
                                    <span className="font-medium">Linkedin</span>
                                </div>

                                <div className="md:w-2/3 mt-4 md:mt-0">
                                    <form>
                                        <div className="form-icon relative">
                                            <Linkedin className="w-4 h-4 absolute top-5 start-4" />
                                            {!editSocialDetails && <p className="form-label font-medium mt-1 ps-12">{linkedInUrl ? linkedInUrl : ' - - - '}</p>}
                                            {editSocialDetails && <input type="text" value={linkedInUrl} className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Twitter Profile Name" id="twitter_name" name="name" required=""
                                                onChange={(e) => { setLinkedInUrl(e.target.value) }} />}
                                        </div>
                                    </form>

                                    <p className="text-slate-400 mt-1">Add your Linkedin username.</p>
                                </div>
                            </div>

                            <div className="md:flex mt-8">
                                <div className="md:w-1/3">
                                    <span className="font-medium">Youtube</span>
                                </div>

                                <div className="md:w-2/3 mt-4 md:mt-0">
                                    <form>
                                        <div className="form-icon relative">
                                            <Youtube className="w-4 h-4 absolute top-5 start-4" />
                                            {!editSocialDetails && <p className="form-label font-medium mt-1 ps-12">{youtubeUrl ? youtubeUrl : ' - - - '}</p>}
                                            {editSocialDetails && <input type="text" value={youtubeUrl} className="form-input border border-slate-100 dark:border-slate-800 mt-2 ps-12" placeholder="Twitter Profile Name" id="twitter_name" name="name" required=""
                                                onChange={(e) => { setYoutubeUrl(e.target.value) }} />}
                                        </div>
                                    </form>

                                    <p className="text-slate-400 mt-1">Add your Youtube url.</p>
                                </div>
                            </div>

                            <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
                                {editSocialDetails && <div>
                                    <button id="submitpersonal" name="submitpersonal" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5"
                                        onClick={() => updateSocialDetails()}>Save Changes</button>
                                </div>}
                                <div>
                                    <button id="editpersonal" name="editpersonal" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md mt-5"
                                        onClick={() => { setEditSocialDetails(!editSocialDetails) }}>{editSocialDetails ? 'Stop Edit' : 'Edit'}</button>
                                </div>
                            </div>
                        </div>

                        {(userInfo?.stripeStatus === 'active' || userInfo?.stripeStatus === 'trialing' || subscriptionResponse?.id) && <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <h5 className="text-lg font-semibold mb-4">Subscription Details :</h5>

                            <div className="md:flex">
                                <div className="md:w-1/3">
                                    <span className="font-medium">Subscription</span>
                                </div>

                                <div className="md:w-2/3 mt-4 md:mt-0">
                                    {userInfo?.userType !== 'recr' && <div className="form-icon relative">
                                        {/* <MdPayment className="w-4 h-4 absolute top-5 start-4" /> */}
                                        <p className="form-label font-medium mt-1 ps-8">{selectedSubscription.productName + ' (' + selectedSubscription.noOfUsers + ' Users, $' + selectedSubscription.productPrice + ' per Month )'}</p>
                                    </div>}
                                    {userInfo?.userType === 'recr' && <div className="form-icon relative">
                                        {/* <MdPayment className="w-4 h-4 absolute top-5 start-4" /> */}
                                        <p className="form-label font-medium mt-1 ps-8">{'Recruiter'}</p>
                                    </div>}
                                </div>
                            </div>
                            {userInfo && userInfo.userType === 'admin' && <div className="md:flex mt-8">
                                <div className="md:w-1/3">
                                    <span className="font-medium">Cancel Subscription</span>
                                </div>

                                <div className="md:w-2/3 mt-4 md:mt-0">
                                        <div className="form-icon relative">
                                        <button type="submit" id="submit" name="send" className="btn rounded-md bg-red-600 hover:bg-red-100 border-red-100 hover:border-red-100 text-white"
                                            onClick={() => cancelSub()}>Cancel Subscription</button>
                                        </div>
                                </div>
                            </div>}
                        </div>}

                        {/* <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <h5 className="text-lg font-semibold mb-5">Marketing Notifications :</h5>

                            <div className="flex justify-between pb-4">
                                <h6 className="mb-0 font-medium">There is a sale or promotion</h6>
                                <div className="">
                                    <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti5" />
                                    <label className="form-check-label" htmlFor="noti5"></label>
                                </div>
                            </div>
                            <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                <h6 className="mb-0 font-medium">Company news</h6>
                                <div className="">
                                    <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" id="noti6" />
                                    <label className="form-check-label" htmlFor="noti6"></label>
                                </div>
                            </div>
                            <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                <h6 className="mb-0 font-medium">Weekly jobs</h6>
                                <div className="">
                                    <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti7" />
                                    <label className="form-check-label" htmlFor="noti7"></label>
                                </div>
                            </div>
                            <div className="flex justify-between py-4 border-t border-gray-100 dark:border-gray-700">
                                <h6 className="mb-0 font-medium">Unsubscribe News</h6>
                                <div className="">
                                    <input className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" type="checkbox" value="" defaultChecked id="noti8" />
                                    <label className="form-check-label" htmlFor="noti8"></label>
                                </div>
                            </div>
                        </div> */}

                        {userInfo && userInfo.userType === 'admin' && <div className="p-6 rounded-md shadow dark:shadow-gray-800 bg-white dark:bg-slate-900">
                            <h5 className="text-lg font-semibold mb-5 text-red-600">Delete Account :</h5>

                            <p className="text-slate-400 mb-4">Do you want to delete the account? Please press below "Delete" button</p>

                            <Link to="#" className="btn bg-red-600 hover:bg-red-700 text-white rounded-md">Delete</Link>
                        </div>}
                    </div>
                </div>
            </section>
            <Footer/>
        </>
    )
}