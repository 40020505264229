import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Link, useNavigate } from "react-router-dom";
import { DollarSign } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIdSelector, getOrgIdOfuserSelector, getUserInfoSelector } from '../../reducers/selectors/user.selector';
import { postListings, setLoader } from '../../actions';
import { Editor, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import DraftEditor from '../drafteditor';
import Toolbar from '../drafteditor/toolbar';
import { stateToHTML } from 'draft-js-export-html';


export default function PostJob() {

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [jobType, setJobType] = useState('');
    const [categories, setCategories] = useState('');
    const [postedDate, setPostedDate] = useState('');
    const [durationInMonths, setDurationInMonths] = useState();
    const [salaryType, setSalaryType] = useState('');
    const [salaryRangeFrom, setSalaryRangeFrom] = useState();
    const [salaryRangeTo, setSalaryRangeTo] = useState();
    const [skills, setSkills] = useState('');
    const [qualifications, setQualifications] = useState('');
    const [experience, setExperience] = useState('');
    const [industries, setIndustries] = useState('');
    const [addressLineOne, setAddressLineOne] = useState('');
    const [addressLineTwo, setAddressLineTwo] = useState('');
    const [city, setCity] = useState('');
    const [livingState, setLivingState] = useState('');
    const [country, setCountry] = useState('');
    const [listingType, setListingType] = useState('detailed');

    const [durationOther, setDurationOther] = useState();
    const [verifyDisable, setVerifyDisable] = useState(true);

    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [resEditorState, setResEditorState] = useState(() => EditorState.createEmpty());
    const [quaEditorState, setQuaEditorState] = useState(() => EditorState.createEmpty());

    const orgId = useSelector(getOrgIdOfuserSelector);
    const userId = useSelector(getUserIdSelector);
    const userInfo = useSelector(getUserInfoSelector);

    const dispatch = useDispatch();

    const usaStates = 'Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, Florida, Georgia, Hawaii, Idaho, Illinois, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont, Virginia, Washington, West Virginia, Wisconsin, Wyoming';
    const jobCategories = 'Software Developer,Web Developer,Mobile App Developer,Frontend Developer,Backend Developer,Full Stack Developer,DevOps Engineer,Quality Assurance Engineer,Systems Engineer,Database Administrator,Cloud Engineer,Data Scientist,Machine Learning Engineer,UI/UX Designer,Product Manager,Scrum Master,Project Manager,Technical Writer,Security Engineer,Network Engineer,Support Engineer,Technical Support Specialist,Business Analyst,Solution Architect,Enterprise Architect,IT Consultant,Embedded Systems Engineer,Game Developer,IT Trainer,Data Analyst,IT Manager,Automation Engineer,Release Engineer,IT Operations Manager,Computer Programmer,Software Engineer in Test,AI Engineer,Robotics Engineer,IT Auditor,IT Compliance Officer,System Administrator,Network Administrator,Technical Recruiter,Agile Coach,IT Sales Representative,UX Researcher,Chatbot Developer,Blockchain Developer,IT Analyst,ITIL Specialist,Other';

    useEffect(() => {
        console.log('u...');
        if (listingType === 'detailed') {
            if (title && editorState.getCurrentContent().hasText()
                && resEditorState.getCurrentContent().hasText()
                && quaEditorState.getCurrentContent().hasText()
                && qualifications !== ''
                && categories !== ''
                && jobType !== ''
                && durationInMonths !== ''
                && salaryType && salaryRangeFrom > 0
                && salaryRangeTo > 0
                && experience > 0
                && industries !== ''
                && addressLineOne !== ''
                && city !== ''
                && livingState !== ''
            ) {
                setVerifyDisable(false);
            }
        } else if (listingType === 'quick') {
            if (title && editorState.getCurrentContent().hasText()) {
                setVerifyDisable(false);
            }
        }

    }, [title, editorState, resEditorState, quaEditorState, categories, jobType, qualifications && durationInMonths, salaryType, salaryRangeFrom, salaryRangeTo, experience, industries, addressLineOne, city, livingState])

    useEffect(() => {
        const contentState = editorState.getCurrentContent();
        const contentRaw = convertToRaw(contentState);

        // Convert to HTML
        const contentHtml = stateToHTML(contentState);
        console.log('1...', contentRaw)
        console.log('2...', contentHtml)
    }, [editorState])

    const navigate = useNavigate();
    const applyJob = () => {
        const payload = {
            "orgId": userInfo?.userType !== 'recr' ? orgId : userId,
            userId,
            "title": title,
            "description": stateToHTML(editorState.getCurrentContent()),
            "responsibilities": stateToHTML(resEditorState.getCurrentContent()),
            "qualificationsAndSkills": stateToHTML(quaEditorState.getCurrentContent()),
            qualifications: listingType === 'detailed' ? qualifications && qualifications.split(',') : [],
            "categories": categories,
            "jobType": jobType,
            "postedDate": new Date(),
            "durationInMonths": durationInMonths,
            "salaryType": salaryType,
            "salaryRangeFrom": salaryRangeFrom,
            "salaryRangeTo": salaryRangeTo,
            "experience": experience,
            "industries": listingType === 'detailed' ? industries.split(',') : [],
            "addressLineOne": addressLineOne,
            "addressLineTwo": addressLineTwo,
            "city": city,
            "livingState": livingState,
            "country": 'USA',
            "postedUserType": userInfo?.userType,
            "listingType": listingType,
            "status": 'active'
        }
        console.log('p...', payload)
        dispatch(setLoader(true));
        dispatch(postListings({ "listing": payload }));
        resetJobForm();
        navigate('/mylistings');
    }

    const resetJobForm = () => {
        setTitle('');
        setDescription('');
        setAddressLineOne('');
        setAddressLineTwo('');
        setCategories('');
        setCity('');
        setCountry('');
        setDescription('');
        setDurationInMonths('');
        setDurationOther();
        setExperience('');
        setIndustries('');
        setJobType('');
        setPostedDate('');
        setSalaryRangeFrom('');
        setSalaryRangeTo('');
        setQualifications('');
        setSalaryType('');
        setLivingState('');
    }
    const handleCategories = (value) => {
        setCategories(value);
    }

    const handleDuration = (value) => {
        if (value === 'O') {
            setDurationOther(value);
        } else {
            setDurationInMonths(Number(value));
            setDurationOther('');
        }
    }

    const handleEditor = (newEditorState) => {
        setEditorState(newEditorState);
    };
    const handleResEditor = (newResEditorState) => {
        setResEditorState(newResEditorState);
    };
    const handleQuaEditor = (newQuaEditorState) => {
        setQuaEditorState(newQuaEditorState);
    };
    const handleOptionChange = () => {

    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <div className="relative">
                <div className="shape absolute start-0 end-0 sm:-bottom-px -bottom-[2px] overflow-hidden z-1 text-slate-50 dark:text-slate-800">
                    <svg className="w-full h-auto" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative bg-slate-50 dark:bg-slate-800 lg:py-24 py-16">
                <div className="container">
                    <div className="lg:flex justify-center">
                        <div className="">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <div className="grid grid-cols-12 gap-4 mt-4 w-full mt-1">
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <h5 className="text-lg font-semibold">Enter Job Details:</h5>

                                    </div>

                                    <div className="md:col-span-3 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="inline-flex items-center">
                                            <input type="radio"
                                                className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                name="radio-colors" value="3" defaultChecked={listingType === 'detailed'}
                                                onChange={() => { setListingType('detailed') }} />
                                            <span className="ms-2 text-slate-400">Detailed</span>
                                        </label>
                                    </div>

                                    <div className="md:col-span-3 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="inline-flex items-center">
                                            <input type="radio"
                                                className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                name="radio-colors" value="3" defaultChecked={listingType === 'quick'}
                                                onChange={() => { setListingType('quick') }} />
                                            <span className="ms-2 text-slate-400">Easy Job Post</span>
                                        </label>
                                    </div>
                                </div>

                                {listingType === 'detailed' && <>
                                    <div className="grid grid-cols-12 gap-4 mt-4">
                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Job Title:</label>
                                            <input value={title} required id="RegisterName" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Web Developer"
                                                onChange={(e) => { setTitle(e.target.value) }} />
                                        </div>
                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Job Description:</label>
                                            <Toolbar editorState={editorState} setEditorState={setEditorState} />
                                            <div style={{ border: '1px solid black', minHeight: 200 }}>
                                                <Editor customStyleMap={{ border: '1px solid black' }} editorState={editorState} onChange={handleEditor} />
                                            </div>
                                        </div>
                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Responsibilities & Duties:</label>
                                            <Toolbar editorState={resEditorState} setEditorState={setResEditorState} />
                                            <div style={{ border: '1px solid black', minHeight: 200 }}>
                                                <Editor customStyleMap={{ border: '1px solid black' }} editorState={resEditorState} onChange={handleResEditor} />
                                            </div>
                                        </div>
                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Qualitfications & Skills:</label>
                                            <Toolbar editorState={quaEditorState} setEditorState={setQuaEditorState} />
                                            <div style={{ border: '1px solid black', minHeight: 200 }}>
                                                <Editor customStyleMap={{ border: '1px solid black' }} editorState={quaEditorState} onChange={handleQuaEditor} />
                                            </div>
                                        </div>

                                        <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold">Categories:</label>
                                            <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                                onChange={(e) => handleCategories(e.target.value)}>
                                                <option value=""></option>
                                                {jobCategories && jobCategories.split(',').map((c, index) => (<option key={index} value={c}>{c}</option>))}
                                            </select>
                                        </div>

                                        <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold">Job Type:</label>
                                            <select value={jobType} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                                onChange={(e) => { setJobType(e.target.value) }}>
                                                <option value=""></option>
                                                <option value="Contract">Contract</option>
                                                <option value="Part time">Part Time</option>
                                                <option value="Freelancer">Freelancer</option>
                                                <option value="Remote Work">Remote Work</option>
                                                <option value="Office Work">Office Work</option>
                                            </select>

                                        </div>

                                        <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold">Salary:</label>
                                            <select value={salaryType} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                                onChange={(e) => { setSalaryType(e.target.value) }}>
                                                <option value=""></option>
                                                <option value="Hourly">Hourly</option>
                                                <option value="Monthly">Monthly</option>
                                            </select>

                                        </div>

                                        <div className="md:col-span-3 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold md:invisible md:block hidden">Min:</label>
                                            <div className="relative mt-1">
                                                <span className="w-10 h-10 bg-slate-50 dark:bg-slate-800 border border-slate-100 dark:border-slate-800 absolute top-0 start-0 overflow-hidden rounded">
                                                    <DollarSign className="w-4 h-4 absolute top-3 start-3"></DollarSign>
                                                </span>
                                                <input value={salaryRangeFrom} type="number" className="form-input border border-slate-100 dark:border-slate-800 ps-12" placeholder="min" name="minsalary"
                                                    onChange={(e) => { setSalaryRangeFrom(e.target.value) }} />
                                            </div>
                                        </div>

                                        <div className="md:col-span-3 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold md:invisible md:block hidden">Max:</label>
                                            <div className="relative mt-1">
                                                <span className="w-10 h-10 bg-slate-50 dark:bg-slate-800 border border-slate-100 dark:border-slate-800 absolute top-0 start-0 overflow-hidden rounded">
                                                    <DollarSign className="w-4 h-4 absolute top-3 start-3"></DollarSign>
                                                </span>
                                                <input value={salaryRangeTo} type="number" className="form-input border border-slate-100 dark:border-slate-800 ps-12" placeholder="max" name="maxsalary"
                                                    onChange={(e) => { setSalaryRangeTo(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 mt-8">
                                        <h5 className="text-lg font-semibold">Skill & Experience:</h5>
                                    </div>

                                    <div className="grid grid-cols-12 gap-4 mt-4">
                                        {/* <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="Skillname">Skills:</label>
                                        <input value={skills} id="Skillname" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Web Developer"
                                            onChange={(e) => { setSkills(e.target.value) }} />
                                    </div>*/}

                                        <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="Qualificationname">Educational Qualification:</label>
                                            <input value={qualifications} id="Qualificationname" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Bachelors"
                                                onChange={(e) => { setQualifications(e.target.value) }} />
                                        </div>

                                        <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="Experiencename">Experience:</label>
                                            <input value={experience} id="Experiencename" type="number" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="5"
                                                onChange={(e) => { setExperience(e.target.value) }} />
                                        </div>

                                        <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold">Industry:</label>
                                            <input value={industries} id="Experiencename" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Banking,Finance"
                                                onChange={(e) => { setIndustries(e.target.value) }} />

                                        </div>
                                        <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold">Duration (In Months):</label>
                                            <select value={durationInMonths} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                                onChange={(e) => { handleDuration(e.target.value) }}>
                                                <option value=""></option>
                                                <option value="3">3 Months</option>
                                                <option value="6">6 Months</option>
                                                <option value="12">12 Months</option>
                                                <option value="O">Other</option>
                                            </select>
                                            {durationOther === 'O' && <input id="duration1" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Duration"
                                                onChange={(e) => { setDurationInMonths(e.target.value) }} />}
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1 mt-8">
                                        <h5 className="text-lg font-semibold">Address:</h5>
                                    </div>

                                    <div className="grid grid-cols-12 gap-4 mt-4">
                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="Address">Address:</label>
                                            <input value={addressLineOne} id="Address1" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Address Line 1"
                                                onChange={(e) => { setAddressLineOne(e.target.value) }} />
                                        </div>
                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="Address">Address:</label>
                                            <input value={addressLineTwo} id="Address2" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Address Line 2"
                                                onChange={(e) => { setAddressLineTwo(e.target.value) }} />
                                        </div>
                                        <div className="md:col-span-4 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold">City:</label>
                                            <input id="city" value={city} type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="New York"
                                                onChange={(e) => { setCity(e.target.value) }} />
                                        </div>

                                        <div className="md:col-span-4 col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold">State:</label>
                                            <select value={livingState} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                                onChange={(e) => { setLivingState(e.target.value) }}>
                                                <option value=""></option>
                                                {usaStates && usaStates.split(',').map(st => (<option value={st}>{st}</option>))}

                                            </select>

                                        </div>
                                    </div>
                                </>}
                                {listingType === 'quick' && <>
                                    <div className="grid grid-cols-12 gap-4 mt-4">

                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Job Title:</label>
                                            <input value={title} required id="RegisterName" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Web Developer"
                                                onChange={(e) => { setTitle(e.target.value) }} />
                                        </div>
                                        <div className="col-span-12 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Job Details (Enter Job Description, Candidate Skills & Experience, Candidate Responsibilities, Salary, Client Informaiton):</label>
                                            <Toolbar editorState={editorState} setEditorState={setEditorState} />
                                            <div style={{ border: '1px solid black', minHeight: 200 }}>
                                                <Editor customStyleMap={{ border: '1px solid black' }} editorState={editorState} onChange={handleEditor} />
                                            </div>
                                        </div>
                                    </div>
                                </>}

                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    {verifyDisable && <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <button disabled={verifyDisable} type="submit" id="submit" name="send" className="btn rounded-md bg-red-600 hover:bg-red-100 border-red-100 hover:border-red-100 text-white"
                                            onClick={() => applyJob()}>Post Now</button>
                                    </div>}
                                    {!verifyDisable && <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <button disabled={verifyDisable} type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white"
                                            onClick={() => applyJob()}>Post Now</button>
                                    </div>}
                                    <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <button type="submit" id="submit" name="reset" className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white"
                                            onClick={() => resetJobForm()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}