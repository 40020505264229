import { utilConstants } from "../constants";


const initialState = {
    activateLoader:false,
}

export function utilReducer(state = initialState, action) {

    switch (action.type) {

        case utilConstants.SET_LOADER:
            return {
                ...state,
                activateLoader:action.payload
            }
        default:
            return state;
    }
}