import React, { useState } from 'react'
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import Select from 'react-select'

import facebook_logo from '../../assets/images/company/facebook-logo.png';
import google_logo from '../../assets/images/company/google-logo.png';
import android from '../../assets/images/company/android.png';
import bg6 from '../../assets/images/hero/bg6.png';
import Navbar from '../../components/Navbar'
import PopularCategories from '../../components/Popular-Categories';
import PopularJobsfour from '../../components/Popular-Jobs-four';
import BestCompanies from '../../components/Best-companies';
import Feature from '../../components/Feature';
import News from '../../components/News';
import Footer from '../../components/Footer';
import JobCounter from '../../components/Job-counter';
import {BiBriefcaseAlt2, PiMapPin,} from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';
import MillionsJob from '../../components/Millions-job';
import { useNavigate } from 'react-router-dom';

const options = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: ' Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
];

const usaStates = 'Alabama,Alaska,Arizona,Arkansas,California,Colorado,Connecticut,Delaware,Florida,Georgia,Hawaii,Idaho,Illinois,Indiana,Iowa,Kansas,Kentucky,Louisiana,Maine,Maryland,Massachusetts,Michigan,Minnesota,Mississippi,Missouri,Montana,Nebraska,Nevada,New Hampshire,New Jersey,New Mexico,New York,North Carolina,North Dakota,Ohio,Oklahoma,Oregon,Pennsylvania,Rhode Island,South Carolina,South Dakota,Tennessee,Texas,Utah,Vermont,Virginia,Washington,West Virginia,Wisconsin,Wyoming';

export default function Welcome() {
    const navigate = useNavigate();
    const [isOpen, setOpen] = useState(false)
    const [searchLocation, setSearchLocation] = useState('');
    
    const handleLocation = (selectedOption) => {
        setSearchLocation(selectedOption);
    }
    return (
        <>
            <Navbar navClass='justify-end' isContainerFluid={true} />
            <section className="relative table md:pt-60 pt-36 w-full bg-gradient-to-b from-emerald-600/20 dark:from-emerald-600/40 via-emerald-600/10 dark:via-emerald-600/20 to-transparent">
                <div className="container z-1">
                    <div className="grid grid-cols-1 text-center relative">
                        <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">Built for corp-to-corp, <br /> all recruiters at one place</h4>
                        <p className="text-slate-400 text-lg max-w-xl mx-auto">Find Jobs, Employment & Career Opportunities.</p>

                        <div className="d-flex" id="reserve-form">
                            <div className="md:w-5/6 mx-auto">
                                <div className="lg:col-span-10 mt-8">
                                    <div className="bg-white dark:bg-slate-900 border-0 shadow rounded-md p-3">
                                        <form action="#">
                                            <div className="registration-form text-dark text-start">
                                                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                                    <div className="filter-search-form relative filter-border">
                                                        <BiBriefcaseAlt2 className="icons"/>
                                                        <input name="name" type="text" id="job-keyword" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" placeholder="Search your Keywords" />
                                                    </div>

                                                    <div className="filter-search-form relative filter-border">
                                                        <PiMapPin className="icons"/>
                                                        <Select className="filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={options}
                                                    onChange={handleLocation} 
                                                    value={searchLocation}/>
                                                    </div>

                                                    <input type="submit" id="search" name="search" style={{ height: '60px' }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100" value="Search" 
                                                    onClick={() => {navigate('/signup')}}/>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-4">
                            <span className="text-slate-400"><span className="text-slate-900 dark:text-white">Popular Searches :</span> Designer, Developer, Web, IOS, PHP Senior Engineer</span>
                        </div>

                        <div className="absolute -top-20 start-1/2 -translate-x-1/2">
                            <div className="w-10 h-10 animate-[bounce_2s_infinite] bg-white dark:bg-slate-900 flex items-center justify-center shadow dark:shadow-gray-700 rounded-md">
                                <img src={facebook_logo} className="h-6 w-6" alt="" />
                            </div>
                        </div>

                        <div className="absolute top-[40%] start-10">
                            <div className="w-10 h-10 animate-[spin_5s_linear_infinite] bg-white dark:bg-slate-900 flex items-center justify-center shadow dark:shadow-gray-700 rounded-md">
                                <img src={google_logo} className="h-6 w-6" alt="" />
                            </div>
                        </div>

                        <div className="absolute top-[40%] end-1">
                            <div className="w-10 h-10 bg-white dark:bg-slate-900 flex items-center justify-center shadow dark:shadow-gray-700 rounded-md">
                                <img src={android} className="h-6 w-6" alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="grid grid-cols-1">
                        <img src={bg6} alt="" />
                    </div>
                </div>
            </section>
            <section className="relative md:py-24 py-16">
                <JobCounter />
                <PopularCategories />
                <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} />
                <div className="container md:mt-24 md:pb-16 mt-16">
                    <MillionsJob/>
                </div>
            </section>

            {/* <section className="relative md:pb-24 pb-16 overflow-hidden">
                <PopularJobsfour />
                <BestCompanies />
                <Feature />
                <News />

              <ExploreJob/>
            </section > */}
            <Footer />
        </>
    )
}