export function isLoggedInSelector({ userReducer }) {
    if (userReducer && userReducer.isLoggedIn) return true;
    else return false;
}

export function getLoginAttemptMessageSelector({ userReducer }) {
    if (userReducer && userReducer.loginAttemptMessage) return userReducer.loginAttemptMessage;
    else return '';
}

export function getUserInfoSelector({ userReducer }) {
    if (userReducer && userReducer.userInfo) return userReducer.userInfo;
    else return {};
}

export function getEmployerInfoSelector({ userReducer }) {
    if (userReducer && userReducer.employerInfo) return userReducer.employerInfo;
    else return {};
}

export function getUserIdSelector({ userReducer }) {
    if (userReducer && userReducer.userInfo && userReducer.userInfo.id) return userReducer.userInfo.id;
    else return '';
}

export function getOrgDetailsOfuserSelector({ userReducer }) {
    if (userReducer && userReducer.orgDetails) return userReducer.orgDetails;
    else return {};
}

export function getOrgIdOfuserSelector({ userReducer }) {
    if (userReducer && userReducer.orgDetails && userReducer.orgDetails.id) return userReducer.orgDetails.id;
    else return '';
}

export function userRegRequestSelector({ userReducer }) {
    if (userReducer && userReducer.userRegRequest) return true;
    else return false;
}  

export function getNewlyAddedCandidateSelector({ userReducer }) {
    if (userReducer && userReducer.newlyAddedCandidate) return userReducer.newlyAddedCandidate;
    else return {};
} 

export function getAllCandidatesSelector({ userReducer }) {
    if (userReducer && userReducer.candidatesList) return userReducer.candidatesList;
    else return [];
} 
export function getHasMoreSearchCandidatesSelector({ userReducer }) {
    if (userReducer && userReducer.hasMoreSearchCandidates) return userReducer.hasMoreSearchCandidates;
    else return false;
}

export function getSelectedCandidateSelector({ userReducer }) {
    if (userReducer && userReducer.selectedCandidate) return userReducer.selectedCandidate;
    else return {};
} 

export function getUsersSelector({ userReducer }) {
    if (userReducer && userReducer.users) return userReducer.users;
    else return [];
} 

export function getUserSubscriptionInfo({ userReducer }) {
    if (userReducer && userReducer.users && userReducer.user.subscriptionInfo) return userReducer.user.subscriptionInfo;
    else return {};
} 

export function getUserSubscriptionPlanId({ userReducer }) {
    if (userReducer && userReducer.userInfo && userReducer.userInfo.subscriptionInfo && userReducer.userInfo.subscriptionInfo.subscriptionPlanId) return userReducer.userInfo.subscriptionInfo.subscriptionPlanId;
    else return '';
}

export function getPasswordChangeMessageSelector({ userReducer }) {
    if (userReducer && userReducer.passwordChangeMessage ) return userReducer.passwordChangeMessage;
    else return '';
}

export function getSubscriptionResponseSelector({ userReducer }) {
    if (userReducer && userReducer.subscriptionResponse ) return userReducer.subscriptionResponse;
    else return false;
}