import React, { useEffect, useState } from 'react'
import ModalVideo from 'react-modal-video'
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import Select from 'react-select'

import facebook_logo from '../../assets/images/company/facebook-logo.png';
import google_logo from '../../assets/images/company/google-logo.png';
import android from '../../assets/images/company/android.png';
import bg6 from '../../assets/images/hero/bg6.png';
import Navbar from '../../components/Navbar'
import PopularCategories from '../../components/Popular-Categories';
import PopularJobsfour from '../../components/Popular-Jobs-four';
import BestCompanies from '../../components/Best-companies';
import Feature from '../../components/Feature';
import News from '../../components/News';
import Footer from '../../components/Footer';
import JobCounter from '../../components/Job-counter';
import { BiBriefcaseAlt2, BiCheckCircle, PiMapPin, } from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';
import MillionsJob from '../../components/Millions-job';

import { Link, useNavigate } from "react-router-dom";
import logo from '../../assets/images/logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { isLoggedInSelector, userRegRequestSelector } from '../../reducers/selectors/user.selector';
import { attemptOrgRegistration, attemptUserRegistration, userRegStatusRequest } from '../../actions';

import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

const options = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: ' Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
];

const usaStates = 'Alabama,Alaska,Arizona,Arkansas,California,Colorado,Connecticut,Delaware,Florida,Georgia,Hawaii,Idaho,Illinois,Indiana,Iowa,Kansas,Kentucky,Louisiana,Maine,Maryland,Massachusetts,Michigan,Minnesota,Mississippi,Missouri,Montana,Nebraska,Nevada,New Hampshire,New Jersey,New Mexico,New York,North Carolina,North Dakota,Ohio,Oklahoma,Oregon,Pennsylvania,Rhode Island,South Carolina,South Dakota,Tennessee,Texas,Utah,Vermont,Virginia,Washington,West Virginia,Wisconsin,Wyoming';

export default function Signup() {


    const dispatch = useDispatch();

    const userRegRequest = useSelector(userRegRequestSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);

    const [orgName, setOrgName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [terms, setTerms] = useState(false);
    const [regType, setRegType] = useState('org');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(userRegStatusRequest());
    }, [])
    
    useEffect(() => {
        if (userRegRequest) {
            toast.success("Registration is successful.Please check email to activate account.", { position: toast.POSITION.TOP_RIGHT })
            dispatch(userRegStatusRequest());
            navigate('/login');
        }
    }, [userRegRequest])

    const orgReg = () => {
        if (regType === 'org') {
            const payload = {
                'orgName': orgName,
                'emailId': email,
                'mobile': mobile,
                'password': password,
                'role': regType === 'org' ? 'admin' : 'recr'
            }
            dispatch(attemptOrgRegistration(payload))
        }
        if (regType === 'recr') {
            const payload = {
                'firstName': firstName,
                'lastName': lastName,
                'emailId': email,
                'mobile': mobile,
                'password': password,
                'role': 'recr',
                'createdBy': email
            }
            dispatch(attemptUserRegistration(payload))
        }

    }

    return (
        <>
            <Navbar navClass='justify-end' isContainerFluid={true} />
            <ToastContainer autoClose={8000} />
            <section className="relative table md:pt-40 pt-36 w-full bg-gradient-to-b from-emerald-600/20 dark:from-emerald-600/40 via-emerald-600/10 dark:via-emerald-600/20 to-transparent">
                <div className="container z-1">


                    <div className="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px] z-1">
                        <div className="counter-box text-center">
                        {regType === 'org' && <div className="grid grid-cols-1 text-center relative">
                                <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">Our Organization plan, <br /> gives full access to multiple users</h4>
                                <p className="text-slate-400 text-lg max-w-xl mx-auto">Find Jobs, Post Jobs, Add Candidates, Apply Job.</p>
                            </div>}
                            {regType === 'recr' && <div className="grid grid-cols-1 text-center relative">
                                <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">Our Recruiter plan, <br /> completely free to use.</h4>
                                <p className="text-slate-400 text-lg max-w-xl mx-auto">Post Jobs,Find Candidates,Find Applications</p>
                            </div>}    
                        </div>


                        <div className="group border border-transparent relative shadow hover:shadow-md dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900 transition-all duration-500">
                            {regType === 'org' && <div className="p-6 py-8">
                                <h6 className="text-lg font-bold uppercase mb-5 text-emerald-600">{'Organization Price Chart'}</h6>

                                <div className="mb-5">
                                    <span className="text-sm font-semibold self-end mb-1 ">{3 + ' Users - $99/month'}<h3 className="text-sm font-bold text-red-600">Free First 3 Months(Offer ends by 30 APR 2024)</h3></span><br/>
                                    <span className="text-sm font-semibold self-end mb-1 border-t border-gray-100 dark:border-gray-700">{6 + ' Users - $149/month'}<h3 className="text-sm font-bold text-red-600">Free First 3 Months(Offer ends by 30 APR 2024)</h3></span><br/>
                                    <span className="text-sm font-semibold self-end mb-1 border-t border-gray-100 dark:border-gray-700">{10 + ' Users - $199/month'}<h3 className="text-sm font-bold mb-8 text-red-600">7 Days Free Trial</h3></span>
                                    <span className="text-sm font-semibold self-end mb-1 border-t border-gray-100 dark:border-gray-700">{20 + ' Users - $299/month'}<h3 className="text-sm font-bold mb-8 text-red-600">7 Days Free Trial</h3></span>
                                </div>

                                <ul className="list-none text-slate-400 border-t border-gray-100 dark:border-gray-700 pt-5">
                                    {'Post Job,Job Search,Add Candidates,Apply Jobs'.split(',').map(a => (<li className="my-2 flex items-center"><BiCheckCircle className="text-emerald-600 text-xl me-2" /> {a}</li>))}
                                </ul>
                                {/* <div className="mb-1">
                                    <input type="submit" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value={isLoggedIn ? "Subscribe" : "Get Started"} />
                                </div> */}
                                {/* <Link to="#" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md mt-5">Get Started</Link> */}
                            </div>}
                            {regType === 'recr' && <div className="p-6 py-8 mt-1">
                                <h6 className="text-lg font-bold uppercase mb-5 text-emerald-600">{'Recruiter'}</h6>

                                <div className="mb-5">
                                    <span className="text-sm font-semibold self-end mb-1 ">{1 + ' User'}<h3 className="text-sm font-bold text-red-600">Free Access</h3></span><br/>
                                </div>

                                <ul className="list-none text-slate-400 border-t border-gray-100 dark:border-gray-700 pt-5">
                                    {'Post Job,Search Candidates,Find Applications'.split(',').map(a => (<li className="my-2 flex items-center"><BiCheckCircle className="text-emerald-600 text-xl me-2" /> {a}</li>))}
                                </ul>
                                {/* <div className="mb-1">
                                    <input type="submit" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value={isLoggedIn ? "Subscribe" : "Get Started"} />
                                </div> */}
                                {/* <Link to="#" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md mt-5">Get Started</Link> */}
                            </div>}
                        </div>


                        <div className="counter-box text-center">
                            <div className="p-4">
                                {/* <Link to="#">
                                    <img src={logo} className="mx-auto h-[24px] block dark:hidden" alt="" />
                                    <img src={logo} className="mx-auto h-[24px] dark:block hidden" alt="" />
                                </Link> */}
                                <h5 className="my-6 text-xl font-semibold">Signup</h5>
                                <div className="grid grid-cols-1">
                                    <div className="mb-1 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="RegisterName">Register As:</label>
                                        <select value={regType} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => { setRegType(e.target.value) }}>
                                            <option value="org">Organization</option>
                                            <option value="recr">Recruiter</option>
                                        </select>
                                    </div>

                                    {regType === 'org' && <><div className="mb-1 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="RegisterName">Company Name:</label>
                                        <input id="RegisterName" type="text" className="form-input mt-3 rounded-md" placeholder="Stars LLC"
                                            onChange={(e) => { setOrgName(e.target.value) }} />
                                    </div></>}

                                    {regType === 'recr' && <><div className="mb-1 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="RegisterName">First Name:</label>
                                        <input id="'firstName" type="text" className="form-input mt-3 rounded-md" placeholder=""
                                            onChange={(e) => { setFirstName(e.target.value) }} />
                                    </div>
                                        <div className="mb-1 ltr:text-left rtl:text-right">
                                            <label className="font-semibold" htmlFor="RegisterName">Last Name:</label>
                                            <input id="LlstName" type="text" className="form-input mt-3 rounded-md" placeholder=""
                                                onChange={(e) => { setLastName(e.target.value) }} />
                                        </div>
                                    </>}

                                    <div className="mb-1 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                        <input id="LoginEmail" type="email" className="form-input mt-3 rounded-md" placeholder="name@example.com"
                                            onChange={(e) => { setEmail(e.target.value) }} />
                                    </div>

                                    <div className="mb-1 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginEmail">Mobile:</label>
                                        <input id="LoginMobile" type="number" className="form-input mt-3 rounded-md" placeholder="2123242124"
                                            onChange={(e) => { setMobile(e.target.value) }} />
                                    </div>

                                    <div className="mb-1 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                        <input id="LoginPassword" type="password" className="form-input mt-3 rounded-md" placeholder="Password"
                                            onChange={(e) => { setPassword(e.target.value) }} />
                                    </div>

                                    <div className="mb-1 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginPassword">Confirm Password:</label>
                                        <input id="LoginPassword" type="password" className="form-input mt-3 rounded-md" placeholder="Comfirm Password"
                                            onChange={(e) => { setConfirmPassword(e.target.value) }} />
                                    </div>

                                    <div className="mb-1">
                                        <div className="flex items-center w-full mb-0">
                                            <input className="form-checkbox text-emerald-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="AcceptT&C"
                                                onChange={() => setTerms(!terms)} />
                                            <label className="form-check-label text-black-400" htmlFor="AcceptT&C">I Accept <Link to="#" className="text-emerald-600">Terms And Condition</Link></label>
                                        </div>
                                    </div>

                                    <div className="mb-1">
                                        <input type="submit" disabled={!terms} className={terms == true ? "btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" :
                                            "btn bg-emerald-600 hover:bg-zinc-400 bg-zinc-300 hover:bg-zinc-400 text-white rounded-md w-full"} value="Register"
                                            onClick={() => orgReg()} />
                                    </div>

                                    <div className="text-center">
                                        <span className="text-black-400 me-2">Already have an account ? </span> <Link to="/login" className="text-black dark:text-white font-bold">Sign in</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="grid grid-cols-1">
                        <img src={bg6} alt="" />
                    </div>
                </div>
            </section>


            {/* <section className="relative md:pb-24 pb-16 overflow-hidden">
                <PopularJobsfour />
                <BestCompanies />
                <Feature />
                <News />

              <ExploreJob/>
            </section > */}
            <Footer />
        </>
    )
}