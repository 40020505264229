export const serviceConstants = {

    //local
    // USER_URL : 'http://localhost:5001/jobslyn/user',
    // LISTINGS_URL:'http://localhost:5002/jobslyn/listings',
    // STRIPE_PUBLISH_KEY:'pk_test_51OOv0iB0WIXRW3ZA2rwowCLuPhkQOMgC3tEZ2dpegFc4uokelycSMinCAohFzXtMsQpjTSPWInx6pafkpZPouWxe00Z8LaJ4JU'
    //dev
    USER_URL : 'https://d1nbvnm0y136sp.cloudfront.net/jobslyn/user',
    LISTINGS_URL:'https://d22ahvvhhs1q7f.cloudfront.net/jobslyn/listings',
    STRIPE_PUBLISH_KEY:'pk_test_51OOv0iB0WIXRW3ZA2rwowCLuPhkQOMgC3tEZ2dpegFc4uokelycSMinCAohFzXtMsQpjTSPWInx6pafkpZPouWxe00Z8LaJ4JU'
    //prod
    // USER_URL : 'https://d199b01rd8rcyu.cloudfront.net/jobslyn/user',
    // LISTINGS_URL:'https://d1nvr3k5jueqbe.cloudfront.net/jobslyn/listings',
    // STRIPE_PUBLISH_KEY:'pk_live_51OOv0iB0WIXRW3ZADDabRdgxbe1RyWTkrJNWhygb5yplh0AZIIu3jRRAAnaAMq45UNs7SLzjP4q9OwI16H8PNPYd00LXkJZwnn'
}

// // dev
export const products = [
    {
        'productId': 'price_1OP1dKB0WIXRW3ZAtnB8aNDj',
        'productName': 'Standard',
        'productTitle': 'Standard',
        'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
        'noOfUsers': 3,
        'productPrice': 99
    },
    {
        'productId': 'price_1OR6fUB0WIXRW3ZAcDAGVcGz',
        'productName': 'Essential',
        'productTitle': 'Essential',
        'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
        'noOfUsers': 6,
        'productPrice': 149
    },
    {
        'productId': 'price_1OR6g5B0WIXRW3ZASgkW24dd',
        'productName': 'Executive',
        'productTitle': 'Executive',
        'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
        'noOfUsers': 10,
        'productPrice': 199
    },
    {
        'productId': 'price_1OR6ggB0WIXRW3ZAVcXnTmWn',
        'productName': 'Premium',
        'productTitle': 'Premium',
        'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
        'noOfUsers': 20,
        'productPrice': 299
    }
];

// prod
// export const products = [
//     {
//         'productId': 'price_1OTX8JB0WIXRW3ZAsRlZe9xq',
//         'productName': 'Standard',
//         'productTitle': 'Standard',
//         'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
//         'noOfUsers': 3,
//         'productPrice': 99
//     },
//     {
//         'productId': 'price_1OTX8RB0WIXRW3ZATwGfw8Am',
//         'productName': 'Essential',
//         'productTitle': 'Essential',
//         'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
//         'noOfUsers': 6,
//         'productPrice': 149
//     },
//     {
//         'productId': 'price_1OTX8XB0WIXRW3ZAUt2dHKxL',
//         'productName': 'Executive',
//         'productTitle': 'Executive',
//         'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
//         'noOfUsers': 10,
//         'productPrice': 199
//     },
//     {
//         'productId': 'price_1OTX8dB0WIXRW3ZAdsXb1LQ7',
//         'productName': 'Premium',
//         'productTitle': 'Premium',
//         'activities': ['Post Jobs', 'Job Search', 'Add Candidates', 'Apply Jobs'],
//         'noOfUsers': 20,
//         'productPrice': 299
//     }
// ];