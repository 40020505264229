import { put, takeLatest } from "redux-saga/effects";
import { listingsConstants, serviceConstants } from "../constants";
import Transport from "../transport/rabbitboard.transport";
import * as listingsActions from '../actions/listings.action';
import data from './data.json';
import { setLoader } from "../actions";

function* getListingsSaga(action) {
    try {
        // const listings = data.listings;
        // yield put(listingsActions.listingsSuccess(data));
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL,
        '',
        {},
        "GET"
      );
      console.log("attemptLoginSaga RESPONSE ", response);
      if (response) {
        yield put(listingsActions.listingsSuccess(response));
      } else {
        yield put(listingsActions.listingsFailure(response));
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("attemptLoginSaga Saga ERROR ", e);
      yield put(listingsActions.listingsFailure(e));
      yield put(setLoader(false));
      throw e;
    }
  }

  function* searchListingsSaga(action) {
    try {
        // const listings = data.listings;
        // yield put(listingsActions.listingsSuccess(data));
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+`/search?searchKey=${action.payload.searchKey}&location=${action.payload.location}&listingType=${action.payload.listingType}`,
        '',
        {},
        "GET"
      );
      console.log("attemptLoginSaga RESPONSE ", response);
      if (response) {
        yield put(listingsActions.searchListingsSuccess(response));
      } else {
        yield put(listingsActions.searchListingsFailure(response));
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("attemptLoginSaga Saga ERROR ", e);
      yield put(listingsActions.searchListingsFailure(e));
      yield put(setLoader(false));
      throw e;
    }
  }

  function* getUserListingsSaga(action) {
    try {
        // const listings = data.listings;
        // yield put(listingsActions.listingsSuccess(data));
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'/mylistings/'+action.payload,
        '',
        {},
        "GET"
      );
      console.log("attemptLoginSaga RESPONSE ", response);
      if (response) {
        yield put(listingsActions.getUserListingsSuccess(response));
      } else {
        yield put(listingsActions.getUserListingsFailure(response));
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("attemptLoginSaga Saga ERROR ", e);
      yield put(listingsActions.getUserListingsFailure(e));
      yield put(setLoader(false));
      throw e;
    }
  }

  function* getOrgListingsSaga(action) {
    try {
        // const listings = data.listings;
        // yield put(listingsActions.listingsSuccess(data));
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'/orglistings/'+action.payload,
        '',
        {},
        "GET"
      );
      console.log("attemptLoginSaga RESPONSE ", response);
      if (response) {
        yield put(listingsActions.getOrgListingsSuccess(response));
      } else {
        yield put(listingsActions.getOrgListingsFailure(response));
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("attemptLoginSaga Saga ERROR ", e);
      yield put(listingsActions.getOrgListingsFailure(e));
      yield put(setLoader(false));
      throw e;
    }
  }

  function* postListingSaga(action) {
    try {
      console.log('saga...',action.payload)
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'/postlisting',
        '',
        action.payload,
        "POST"
      );
      console.log("attemptRegisterSaga RESPONSE ", response);
  
      if (response) {
        yield put(listingsActions.postListingsSuccess(response));
        // yield put(listingsActions.getListings())
      } else {
        yield put(
          listingsActions.postListingsFailure(
            response && response.message && response.message.message
              ? response.message.message
              : "Registration Request Failed, Please check details and try again"
          )
        );
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("attemptRegisterSaga Saga ERROR ", e);
      yield put(
        listingsActions.postListingsFailure("Request Failed, Please try again.")
      )
      yield put(setLoader(false));
    }
  }

  function* deleteListingSaga(action) {
    console.log('2..',action.payload)
    try {
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+`/delete/${action.payload.userId}/${action.payload.listingId}`,
        '',
        {},
        "PUT"
      );
  
      if (response) {
        yield put(listingsActions.deleteListingSuccess(response));
        // yield put(listingsActions.getListings())
      } else {
        yield put(
          listingsActions.deleteListingSuccess(
            response && response.message && response.message.message
              ? response.message.message
              : "Lising delete is Failed, Please check details and try again"
          )
        );
      }
      yield put(setLoader(false));
    } catch (e) {
      yield put(
        listingsActions.deleteListingFailure("Request Failed, Please try again.")
      )
      yield put(setLoader(false));
    }
  }

  function* updateListingSaga(action) {
    try {
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'update',
        '',
        action.payload,
        "PUT"
      );
  
      if (response) {
        yield put(listingsActions.editListingSuccess(response));
        // yield put(listingsActions.getListings())
      } else {
        yield put(
          listingsActions.editListingFailure(
            response && response.message && response.message.message
              ? response.message.message
              : "Edit lising is Failed, Please check details and try again"
          )
        );
      }
      yield put(setLoader(false));
    } catch (e) {
      yield put(
        listingsActions.editListingFailure("Edit listing Failed, Please try again.")
      )
      yield put(setLoader(false));
    }
  }

  function* applyListingSaga(action) {
    try {
      console.log('saga...',action.payload)
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'/apply',
        '',
        action.payload,
        "POST"
      );
      console.log("attemptRegisterSaga RESPONSE ", response);
  
      if (response) {
        yield put(listingsActions.applyListingsSuccess(response));
        // yield put(listingsActions.getListings())
      } else {
        yield put(
          listingsActions.applyListingsFailure(
            response && response.message && response.message.message
              ? response.message.message
              : "Registration Request Failed, Please check details and try again"
          )
        );
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("attemptRegisterSaga Saga ERROR ", e);
      yield put(
        listingsActions.applyListingsFailure("Request Failed, Please try again.")
      )
      yield put(setLoader(false));
    }
  }

  function* updateAppliedStatusSaga(action) {
    try {
      console.log('saga...',action.payload)
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'/updateappliedlistings',
        '',
        action.payload,
        "POST"
      );
      console.log("updateAppliedListingSaga RESPONSE ", response);
  
      if (response) {
        yield put(listingsActions.updateAppliedStatusSuccess(response));
        // yield put(listingsActions.getListings())
      } else {
        yield put(
          listingsActions.updateAppliedStatusFailure(
            response && response.message && response.message.message
              ? response.message.message
              : "Registration Request Failed, Please check details and try again"
          )
        );
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("updateAppliedListingSaga Saga ERROR ", e);
      yield put(
        listingsActions.updateAppliedStatusFailure("Request Failed, Please try again.")
      )
      yield put(setLoader(false));
    }
  }

  function* getAppliedCandidatesSaga(action) {
    try {
      console.log('saga...',action.payload)
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'/appliedcandidates/'+action.payload,
        '',
        {},
        "GET"
      );
      console.log("getAppliedCandidatesSaga RESPONSE ", response);
  
      if (response) {
        yield put(listingsActions.getAppliedCandidatesSuccess(response));
        // yield put(listingsActions.getListings())
      } else {
        yield put(
          listingsActions.getAppliedCandidatesFailure(
            response && response.message && response.message.message
              ? response.message.message
              : "Registration Request Failed, Please check details and try again"
          )
        );
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("attemptRegisterSaga Saga ERROR ", e);
      yield put(
        listingsActions.getAppliedCandidatesFailure("Request Failed, Please try again.")
      )
      yield put(setLoader(false));
    }
  }

  function* searchListingsById(action) {
    try {
      console.log('saga...',action.payload)
      const response = yield Transport.genericOperation(
        serviceConstants.LISTINGS_URL+'/searchbyid/'+action.payload,
        '',
        {},
        "GET"
      );
      console.log("searchListingsById RESPONSE ", response);
  
      if (response) {
        yield put(listingsActions.searchListingByIdSuccess(response));
        // yield put(listingsActions.getListings())
      } else {
        yield put(
          listingsActions.searchListingByIdFailure(
            response && response.message && response.message.message
              ? response.message.message
              : "Registration Request Failed, Please check details and try again"
          )
        );
      }
      yield put(setLoader(false));
    } catch (e) {
      console.error("searchListingsById Saga ERROR ", e);
      yield put(
        listingsActions.searchListingById("Request Failed, Please try again.")
      )
      yield put(setLoader(false));
    }
  }

  export default function* listingsSaga() {
    try {
      yield takeLatest(listingsActions.getListings, getListingsSaga);
      yield takeLatest(listingsActions.postListings, postListingSaga);
      yield takeLatest(listingsActions.getOrgListings, getOrgListingsSaga);
      yield takeLatest(listingsActions.getUserListings, getUserListingsSaga);
      yield takeLatest(listingsActions.applyListings, applyListingSaga);
      yield takeLatest(listingsActions.getAppliedCandidates, getAppliedCandidatesSaga);
      yield takeLatest(listingsActions.searchListings, searchListingsSaga);
      yield takeLatest(listingsActions.updateAppliedStatus, updateAppliedStatusSaga);
      yield takeLatest(listingsActions.deleteListing, deleteListingSaga);
      yield takeLatest(listingsActions.editListing, updateListingSaga);
    } catch {
      yield;
    }
  }