import { userConstants } from "../constants";


const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    dob: '',
    isLoggedIn: false,
    loginAttemptMessage: '',
    userInfo: {},
    userDetails: {},
    userRegRequest: false,
    newlyAddedCandidate: {},
    uploadStatus: false,
    candidatesList: [],
    selectedCandidate: {},
    users: [],
    userSubscriptionResponse: {},
    passwordChangeMessage: '',
    postContactusResponse: {},
    subscriptionResponse: '',
    hasMoreSearchCandidates: false,
    employerInfo: ''
}

export function userReducer(state = initialState, action) {

    switch (action.type) {
        case userConstants.LOGIN_REQUEST:
            return {
                ...state,
                loginAttemptMessage: ''
            }
        case userConstants.ATTEMPT_LOGIN_SUCCESS:
            console.log('1......', action)
            return {
                ...state,
                isLoggedIn: true,
                userInfo: action.payload.user,
                orgDetails: action.payload.orgDetails,
                loginAttemptMessage: ''
            }
        case userConstants.ATTEMPT_LOGIN_FAILURE:
            return {
                ...state,
                isLoggedIn: false,
                userInfo: {},
                orgDetails: {},
                loginAttemptMessage: action.payload.message
            }
        case userConstants.RESET_LOGIN_ATTEMPT_MESSAGE:
            console.log('1......', action)
            return {
                ...state,
                loginAttemptMessage: ''
            }
        case userConstants.ATTEMPT_ORG_REG:
            return {
                ...state
            }
        case userConstants.ATTEMPT_ORG_REG_SUCCESS:
            return {
                ...state,
                userRegRequest: true
            }
        case userConstants.ATTEMPT_ORG_REG_FAILURE:
            return {
                ...state,
                userRegRequest: false
            }
        case userConstants.ATTEMPT_USER_REG:
            return {
                ...state
            }
        case userConstants.ATTEMPT_USER_REG_SUCCESS:
            return {
                ...state,
                userRegRequest: true
            }
        case userConstants.ATTEMPT_USER_REG_FAILURE:
            return {
                ...state,
                userRegRequest: false
            }
        case userConstants.ATTEMPT_LOGOUT:
            return {
                ...state,
                isLoggedIn: false
            }
        case userConstants.USER_REG_STATUS_REQUEST:
            return {
                ...state,
                userRegRequest: false
            }
        case userConstants.ADD_CANDIDATE_REQUEST:
            return {
                ...state,
            }
        case userConstants.ADD_CANDIDATE_REQUEST_SUCCESS:
            return {
                ...state,
                newlyAddedCandidate: action.payload.candidate
            }
        case userConstants.ADD_CANDIDATE_REQUEST_FAILURE:
            return {
                ...state,
                newlyAddedCandidate: action.payload.message
            }
        case userConstants.UPLOAD_FILES_REQUEST:
            return {
                ...state,
            }
        case userConstants.UPLOAD_FILES_SUCCESS:
            return {
                ...state,
                newlyAddedCandidate: {},
                uploadStatus: true
            }
        case userConstants.UPLOAD_FILES_FAILURE:
            return {
                ...state,
            }
        case userConstants.RESET_UPLOAD_FILES_STATUS:
            return {
                ...state,
                uploadStatus: false
            }
        case userConstants.RESET_NEWLY_ADDED_CANDIDATE_STATUS:
            return {
                ...state,
                newlyAddedCandidate: {}
            }
        case userConstants.GET_ALL_CANDIDATES:
            return {
                ...state,
            }
        case userConstants.GET_ALL_CANDIDATES_SUCCESS:
            return {
                ...state,
                candidatesList: action.payload.allCandidates
            }
        case userConstants.GET_ALL_CANDIDATES_FAILURE:
            return {
                ...state,
                candidatesList: []
            }
        case userConstants.SEARCH_CANDIDATE:
            return {
                ...state,
            }
        case userConstants.SEARCH_CANDIDATE_SUCCESS:
            return {
                ...state,
                candidatesList: action.payload.allCandidates,
                hasMoreSearchCandidates: action.payload.hasMore
            }
        case userConstants.SEARCH_CANDIDATE_FAILURE:
            return {
                ...state,
                candidatesList: []
            }
        case userConstants.GET_CANDIDATE_INFO:
            return {
                ...state,
            }
        case userConstants.GET_CANDIDATE_INFO_SUCCESS:
            return {
                ...state,
                selectedCandidate: action.payload.candidate
            }
        case userConstants.GET_CANDIDATE_INFO_FAILURE:
            return {
                ...state,
            }
        case userConstants.GET_USERS:
            return {
                ...state,
            }
        case userConstants.GET_USERS_SUCCESS:
            return {
                ...state,
                users: action.payload.users
            }
        case userConstants.GET_USERS_FAILURE:
            return {
                ...state,
                users: []
            }
        case userConstants.GET_EMPLOYER_INFO:
            return {
                ...state
            }
        case userConstants.GET_EMPLOYER_INFO_SUCCESS:
            return {
                ...state,
                employerInfo: action.payload
            }
        case userConstants.GET_EMPLOYER_INFO_FAILURE:
            return {
                ...state
            }
        case userConstants.CREATE_NEW_USER:
            return {
                ...state,
            }
        case userConstants.CREATE_NEW_USER_SUCCESS:
            return {
                ...state,
                users: action.payload.users
            }
        case userConstants.CREATE_NEW_USER_FAILURE:
            return {
                ...state,
            }
        case userConstants.DELETE_USER:
            return {
                ...state,
            }
        case userConstants.DELETE_USER_SUCCESS:
            return {
                ...state,
                users: action.payload.users
            }
        case userConstants.DELETE_USER_FAILURE:
            return {
                ...state,
            }
        case userConstants.ACTIVATE_USER:
            return {
                ...state,
            }
        case userConstants.ACTIVATE_USER_SUCCESS:
            return {
                ...state,
                users: action.payload.users
            }
        case userConstants.ACTIVATE_USER_FAILURE:
            return {
                ...state,
            }
        case userConstants.STRIPE_CREATE_SUBSCRIPTION:
            return {
                ...state,
            }
        case userConstants.STRIPE_CREATE_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subscriptionResponse: action.payload,
                userInfo: action.payload.user
            }
        case userConstants.STRIPE_CREATE_SUBSCRIPTION_FAILURE:
            return {
                ...state,
            }
        case userConstants.UPDATE_USER_INFO:
            return {
                ...state,
            }
        case userConstants.UPDATE_USER_INFO_SUCCESS:
            return {
                ...state,
                userInfo: action.payload.user
            }
        case userConstants.UPDATE_USER_INFO_FAILURE:
            return {
                ...state,
            }
        case userConstants.UPDATE_JOB_NOTIFICATIONS_STATUS:
            return {
                ...state,
            }
        case userConstants.UPDATE_JOB_NOTIFICATIONS_STATUS_SUCCESS:
            return {
                ...state,
                userInfo: action.payload.user
            }
        case userConstants.UPDATE_JOB_NOTIFICATIONS_STATUS_FAILURE:
            return {
                ...state,
            }
        case userConstants.CHANGE_PASSWORD:
            return {
                ...state,
            }
        case userConstants.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordChangeMessage: action.payload && action.payload.message
            }
        case userConstants.CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                passwordChangeMessage: action.payload && action.payload.message
            }
        case userConstants.POST_CONTACTUS:
            return {
                ...state,
            }
        case userConstants.POST_CONTACTUS_SUCCESS:
            return {
                ...state,
                postContactusResponse: action.payload && action.payload.message
            }
        case userConstants.POST_CONTACTUS_FAILURE:
            return {
                ...state
            }
        case userConstants.CANCEL_SUBSCRIPTION:
            return {
                ...state,
            }
        case userConstants.CANCEL_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                userInfo: action.payload.user
            }
        case userConstants.CANCEL_SUBSCRIPTION_FAILURE:
            return {
                ...state
            }
        case userConstants.RESET_PASSWORD_CHANGE_MESSAGE:
            return {
                ...state,
                passwordChangeMessage: ''
            }
        case userConstants.SEND_ADD_CANDIDATE_REQUEST:
            return {
                ...state
            }
        case userConstants.SEND_ADD_CANDIDATE_REQUEST_SUCCESS:
            return {
                ...state
            }
        case userConstants.SEND_ADD_CANDIDATE_REQUEST_FAILURE:
            return {
                ...state
            }
        case userConstants.RESET_USER_STATE:
            return initialState;
        default:
            return state;
    }
}