import { sampleConstants } from "../constants/sample.constants";


const initialState = {
    email:'',
    firstName:'',
    lastName:'',
    dob:'',
}

export function sampleReducer(state = initialState, action){

    switch(action.type){
        
        case sampleConstants.SAVE_MY_EMAIL:
            console.log('1......',action)
            return {
                ...state,
                email: action.payload.email,
                lastName: action.payload.lastName,
                firstName: action.payload.firstName
            }
        case sampleConstants.SAVE_DOB:
            console.log('a....',action.type)
            console.log('2....')
            return {
                ...state,
                dob: action.payload.dob
            }    
        default:
            return state;    
    }
}