import React, { useState } from 'react'
import contact from '../../assets/images/svg/contact.svg';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom';
import {PiMapPin, BsTelephone, LuMail} from "../../assets/icons/vander"
import { useDispatch, useSelector } from 'react-redux';
import { postContactus, setLoader } from '../../actions';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import Loader from '../loader';
import { toast,ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function Contact() {

    const[name,setName] = useState('');
    const[email,setEmail] = useState('');
    const[question,setQuestion] = useState('');
    const[comment,setComment] = useState('');

    const loader = useSelector(activateLoaderSelector);

    const dispatch = useDispatch();
    const sendComment = () => {
        const payload = {
            name,
            email,
            question,
            comment
        }
        dispatch(setLoader(false));
        dispatch(postContactus(payload));
        toast.success("Your comment posted Succesfully. One of our sales person will reach out to you. Thanks.",{position:toast.POSITION.TOP_RIGHT})
        resetState();
    }
    
    const resetState = () => {
        setName('');
        setEmail('');
        setQuestion('');
        setComment('');
    }

    if(loader){
        <Loader/>
    }
    return (
        <>
            <Navbar navClass='justify-end' isContainerFluid={true} />
            {/* <div className="container-fluid relative mt-20">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title="contact-frame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{ border: 0 }} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div> */}
            <section className="relative lg:py-24 py-16">
                <ToastContainer autoClose={8000}/>
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold">Get in touch !</h3>

                                    
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="font-semibold">Your Name:</label>
                                                <input name="name" value={name} id="name" type="text" className="form-input mt-2" placeholder="Name :" 
                                                onChange={(e) => setName(e.target.value)}/>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="font-semibold">Your Email:</label>
                                                <input name="email" value={email} id="email" type="email" className="form-input mt-2" placeholder="Email :" 
                                                onChange={(e) => setEmail(e.target.value)}/>
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                                <input name="subject" value={question} id="subject" className="form-input mt-2" placeholder="Subject :" 
                                                onChange={(e) => setQuestion(e.target.value)}/>
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                                <textarea name="comments" value={comment} id="comments" className="form-input mt-2 textarea" placeholder="Message :"
                                                onChange={(e) => setComment(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <button disabled={!name || !email || !question || !comment}type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                                            onClick={() => sendComment()}>Send Message</button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container lg:mt-24 mt-16">
                    <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px]">
                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <BsTelephone/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Phone</h5>
                                <p className="text-black-400 mt-3">Please reach out to Recruiters Place in usual office hours 8 AM to 5 PM.</p>

                                <div className="mt-5">
                                    <Link to="tel:+16162008251" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">+1 616 200 8251</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <LuMail/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Email</h5>
                                <p className="text-black-400 mt-3">Send an email to below address and one our sales executive will reach out to you.</p>

                                <div className="mt-5">
                                    <Link to="mailto:contact@recruitersplace.com" className="btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">contact@recruitersplace.com</Link>
                                </div>
                            </div>
                        </div>

                        <div className="text-center px-6">
                            <div className="relative text-transparent">
                                <div className="w-14 h-14 bg-emerald-600/5 text-emerald-600 rounded-xl text-2xl flex align-middle justify-center items-center mx-auto shadow-sm dark:shadow-gray-800">
                                    <PiMapPin/>
                                </div>
                            </div>

                            <div className="content mt-7">
                                <h5 className="title h5 text-lg font-semibold">Location</h5>
                                <p className="text-black-400 mt-3">77 Manroe Center ST NW , Suite 600, <br /> Grand Rapids, MI, USA 49525</p>

                                {/* <div className="mt-5">
                                    <Link to="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                                        data-type="iframe" className="video-play-icon read-more lightbox btn btn-link text-emerald-600 hover:text-emerald-600 after:bg-emerald-600 transition duration-500">View on Google map</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}
