import { listingsConstants } from "../constants/listings.constants";


const initialState = {
    listings: [],
    userListings: [],
    orgListings: [],
    appliedListingResponse: {},
    appliedCandidates: [],
    deletedListing: '',
    modifiedListing: ''
}

export function listingsReducer(state = initialState, action) {

    switch (action.type) {

        case listingsConstants.LISTINGS_REQUEST:
            return {
                ...state,
            }
        case listingsConstants.LISTINGS_REQUEST_SUCCESS:
            return {
                ...state,
                listings: action.payload.listings
            }
        case listingsConstants.LISTINGS_REQUEST_FAILURE:
            return {
                ...state,
            }
        case listingsConstants.POST_LISTINGS_REQUEST:
            return {
                ...state,
            }
        case listingsConstants.POST_LISTINGS_SUCCESS:
            console.log('lis..', action);
            return {
                ...state,
                listings: action.payload.listings,
                orgListings: action.payload.listings
            }
        case listingsConstants.POST_LISTINGS_FAILURE:
            return {
                ...state,
            }
        case listingsConstants.GET_USER_LISTINGS:
            return {
                ...state,
            }
        case listingsConstants.GET_USER_LISTINGS_SUCCESS:
            return {
                ...state,
                listings: action.payload.listings
            }
        case listingsConstants.GET_USER_LISTINGS_FAILURE:
            return {
                ...state,
            }
        case listingsConstants.GET_ORG_LISTINGS:
            return {
                ...state,
            }
        case listingsConstants.GET_ORG_LISTINGS_SUCCESS:
            return {
                ...state,
                listings: action.payload.listings
            }
        case listingsConstants.GET_ORG_LISTINGS_FAILURE:
            return {
                ...state
            }
        case listingsConstants.APPLY_LISTING:
            return {
                ...state,
            }
        case listingsConstants.APPLY_LISTING_SUCCESS:
            return {
                ...state,
                appliedListingResponse: action.payload
            }
        case listingsConstants.APPLY_LISTING_FAILURE:
            return {
                ...state
            }
        case listingsConstants.RESET_APPLY_LISTING_RESPONSE:
            return {
                ...state,
                appliedListingResponse: {}
            }
        case listingsConstants.GET_APPLIED_CANDIDATES:
            return {
                ...state,
            }
        case listingsConstants.GET_APPLIED_CANDIDATES_SUCCESS:
            return {
                ...state,
                appliedCandidates: action.payload.candidateList
            }
        case listingsConstants.GET_APPLIED_CANDIDATES_FAILURE:
            return {
                ...state
            }
        case listingsConstants.SEARCH_LISTINGS:
            return {
                ...state,
            }
        case listingsConstants.SEARCH_LISTINGS_SUCCESS:
            return {
                ...state,
                listings: action.payload.listings
            }
        case listingsConstants.SEARCH_LISTINGS_FAILURE:
            return {
                ...state
            }
        case listingsConstants.SEARCH_LISTING_BY_ID:
            return {
                ...state,
            }
        case listingsConstants.SEARCH_LISTING_BY_ID_SUCCESS:
            return {
                ...state,
                listings: action.payload.listings
            }
        case listingsConstants.SEARCH_LISTING_BY_ID_FAILURE:
            return {
                ...state
            }
        case listingsConstants.DELETE_LISTING:
            return {
                ...state,
            }
        case listingsConstants.DELETE_LISTING_SUCCESS:
            return {
                ...state,
                deletedListing: action.payload.listings && action.payload.listings[0] 
            }
        case listingsConstants.DELETE_LISTING_FAILURE:
            return {
                ...state
            }
        case listingsConstants.EDIT_LISTING:
            return {
                ...state,
            }
        case listingsConstants.EDIT_LISTING_SUCCESS:
            return {
                ...state,
                modifiedListing: action.payload.listings && action.payload.listings[0]
            }
        case listingsConstants.EDIT_LISTING_FAILURE:
            return {
                ...state
            }
        case listingsConstants.RESET_LISTINGS_STATE:
            return initialState;
        default:
            return state;
    }
}