import logo_light from '../assets/images/logo-light.png';
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo_icon_40 from '../assets/images/logo-icon-40.png';
import logo_icon_40_white from '../assets/images/logo-icon-40-white.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
import logo from '../assets/images/logo.jpg';
import onlylogo from '../assets/images/onlylogo.jpg';

import image from '../assets/images/team/05.jpg';
import { LuSearch } from "../assets/icons/vander";
import { User, Settings, Lock, LogOut } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { attemptLogout, resetUserState } from '../actions/user.action';
import { getSubscriptionResponseSelector, getUserInfoSelector, isLoggedInSelector } from '../reducers/selectors/user.selector';
import { resetListingsState } from '../actions';

export default function NavbarIndividual(props) {
    const [isDropdown, openDropdown] = useState(true);
    const { navClass, topnavClass, isContainerFluid } = props;
    const [isOpen, setMenu] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    

    // window.addEventListener("scroll", windowScroll);
    useEffect(() => {
        // const navbar = document.getElementById("topnav");
        // navbar?.classList.add("nav-sticky");
        windowScroll();
        activateMenu();
    }, []);


    function windowScroll() {
        const navbar = document.getElementById("topnav");
        navbar?.classList.add("nav-sticky");
        // if (
        //     document.body.scrollTop >= 50 ||
        //     document.documentElement.scrollTop >= 50
        // ) {
        //     if (navbar !== null) {
        //         navbar?.classList.add("nav-sticky");
        //     }
        // } else {
        //     if (navbar !== null) {
        //         navbar?.classList.add("nav-sticky");

        //         // navbar?.classList.remove("nav-sticky");
        //     }
        // }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }


    const logout = () => {
        dispatch(resetUserState());
        dispatch(resetListingsState());
        dispatch(attemptLogout());
    }
    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }
    return (
        <nav id="topnav" className={`is-sticky ${topnavClass}`}>
            <div className={`${isContainerFluid === true ? 'container-fluid md:px-8 px-3' : 'container'}`}>
                <Link className="logo" to="/">

                    <div className="block sm:hidden">
                        <img src={onlylogo} className="h-10 inline-block dark:hidden" alt="" />
                        <img src={onlylogo} className="h-10 hidden dark:inline-block" alt="" />
                    </div>

                    {navClass && navClass.includes('nav-light') ?

                        <div className="sm:block hidden">
                            <span className="inline-block dark:hidden">
                                <img src={logo} className="h-[24px] l-dark" alt="" />
                                <img src={logo} className="h-[24px] l-light" alt="" />
                            </span>
                            <img src={logo} className="h-[24px] hidden dark:inline-block" alt="" />
                        </div> :
                        <div className="sm:block hidden">
                            <img src={logo} className="h-[24px] inline-block dark:hidden" alt="" />
                            <img src={logo} className="h-[24px] hidden dark:inline-block" alt="" />
                        </div>
                    }
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </nav>

    )
}
