import React, { useEffect } from 'react'
import Select from 'react-select'
import Navbar from '../../components/Navbar'
import Feature from '../../components/Feature'
import Footer from '../../components/Footer'
import JobListCompThree from '../../components/job-list-comp-three'
import { BiBriefcaseAlt2, LuMapPin, PiMapPin } from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';
import { useDispatch, useSelector } from 'react-redux'
import { getUsersSelector } from '../../reducers/selectors/user.selector'
import { getUserIdSelector, getUserInfoSelector } from '../../reducers/selectors/user.selector'
import { activateUser, deleteUser, getUsers } from '../../actions/user.action'
import { setLoader } from '../../actions'
import { Link, useNavigate } from 'react-router-dom'
import { Bookmark } from 'react-feather'
import { activateLoaderSelector } from '../../reducers/selectors/util.selector'
import Loader from '../loader'
import MessageFullCard from '../MessageFullCard'

const optionsOne = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AZ', label: ' Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'GM', label: 'Gambia' },
]
const optionsTwo = [
    { value: '1', label: 'Full Time' },
    { value: '2', label: 'Part Time' },
    { value: '3', label: 'Freelancer' },
    { value: '4', label: 'Remote Work' },
    { value: '5', label: 'Office Work' },
]
export default function Users() {

    const userId = useSelector(getUserIdSelector);
    const userInfo = useSelector(getUserInfoSelector);

    const users = useSelector(getUsersSelector);
    const loader = useSelector(activateLoaderSelector)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(getUsers(userInfo.userId));
    }, [])

    // useEffect(() => {

    // }, [users])

    if (loader) {
        return <Loader />
    }

    const deleteU = (id) => {
        dispatch(setLoader(true));
        dispatch(deleteUser({ 'adminId': userInfo.userId, 'userId': id }))
    }
    const activateU = (id) => {
        dispatch(setLoader(true));
        dispatch(activateUser({ 'adminId': userInfo.userId, 'userId': id }))
    }
    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover"></section>
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
            <div className="container z-1" style={{ paddingBottom: 20 }}>
                
            {users && users.length > 0 && <div className="lg:col-span-10">
                                    <div className="registration-form text-dark text-start">
                                        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                            <input type="submit" id="search" name="search" style={{ height: '60px' }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100" value="Add User"
                                            onClick={() => {navigate('/adduser')}}/>
                                        </div>
                    </div>
                </div>}
            </div>
                <div className="container">
                    <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        {users && users.map((item, index) => (
                            <div className="group relative overflow-hidden bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-700 dark:hover:shadow-gray-700 hover:-mt-2 rounded-md transition-all duration-500 h-fit" key={index}>
                                <div className="p-6">
                                    <div className="flex items-center">
                                        {/* <div className="w-14 h-14 min-w-[56px] flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                        <img src={item.image} className="h-8 w-8" alt="" />
                                    </div> */}

                                        <div className="ms-3">
                                            <Link className="inline-block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500 me-1">{item.firstName + ' ' + item.lastName}</Link>
                                            <span className="inline-block text-sm text-slate-400">{item.emailId}</span>
                                            <div>
                                                <span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">{item.title}</span>
                                                {/* <span className="text-sm font-medium inline-block me-1">{item.deta} <span className="text-slate-400">{'sssss'}</span></span>
                                                <span className="text-sm font-medium inline-block me-1">{item.deta2} <span className="text-slate-400">{'2222222'}</span></span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {item.status === 'active' && <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 lg:flex justify-between items-center">
                                    {/* <Link to="/job-apply" className="btn btn-sm rounded-md bg-red-600 hover:bg-red-700 border-red-600 hover:border-red-700 text-white md:ms-2 w-full lg:w-auto lg:mt-0 mt-4">{'Delete'}</Link> */}
                                    <button type="submit" id="submit" name="send" className="btn rounded-md bg-red-600 hover:bg-red-100 border-red-100 hover:border-red-100 text-white"
                                        onClick={() => deleteU(item.emailId)}>Delete</button>
                                </div>}
                                {item.status === 'deactive' && <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 lg:flex justify-between items-center">
                                    <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-100 border-emerald-100 hover:border-emerald-100 text-white"
                                        onClick={() => activateU(item.emailId)}>Activate</button>
                                </div>}
                            </div>
                        ))}

                        

                    </div>
                </div>
                {!users || users.length === 0 && <div className="container z-1" style={{ paddingBottom: 20 }}>
                        <MessageFullCard
                            headerTitle={'No Users added so far.'}
                            text={'Add Users'} 
                            linkTo={'adduser'}
                            buttonText={'Add User'}/>
                    </div>}

            </section>

            <Footer />

        </>
    )
}
