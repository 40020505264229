import React, { useState } from 'react'
import ab05 from '../../assets/images/about/ab05.jpg';
import Navbar from '../../components/Navbar'
import MillionsJob from '../../components/Millions-job'
import Feature from '../../components/Feature'
import JobCounter from '../../components/Job-counter';
import QuesAnswer from '../../components/Question-Answer';
import ExploreJob from '../../components/Explore-job';
import Footer from '../../components/Footer';
import { Link } from "react-router-dom";
import{BiCheckCircle} from "../../assets/icons/vander"

export default function Aboutus() {
    const [isOpen, setOpen] = useState(false)

    return (
        <>
            <Navbar navClass='justify-end' isContainerFluid={true} />
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover">
            </section>
            
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
                <div className="container md:pb-16">
                    <MillionsJob />
                </div>

                <Feature />
                <div className="container md:mt-24 mt-16">
                    <div className="grid grid-cols-1">
                        <div className="relative overflow-hidden bg-emerald-600 rounded-md shadow dark:shadow-gray-700">
                            <div className="grid md:grid-cols-2 items-center gap-[30px]">

                                <div>
                                    <div className="text-white p-4">
                                        <h4 className="leading-normal text-4xl mb-3 font-semibold">Get the job of your <br /> dreams quickly.</h4>

                                        <p className="text-white/70 text-lg max-w-xl">Search all the open positions on the web. Get your own personalized salary estimate. Read reviews on over 30000+ companies worldwide.</p>

                                        <ul className="list-none text-white/50 mt-4">
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2"/> Digital Marketing Solutions for Tomorrow</li>
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2"/>  Our Talented & Experienced Marketing Agency</li>
                                            <li className="mb-1 flex items-center"><BiCheckCircle className="text-white text-xl me-2"/>  Create your own skin to match your brand</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container md:mt-24 mt-16">
                    <JobCounter />
                </div>
                <QuesAnswer />
                <ExploreJob />
            </section>
            <Footer />
            {/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="S_CGed6E610" onClose={() => setOpen(false)} /> */}

        </>
    )
}