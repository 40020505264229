import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Bookmark } from "react-feather";
// import {LuMapPin, MdKeyboardArrowLeft, MdKeyboardArrowRight} from "../assets/icons/vander"
import { jobData } from '../../data/data';
import { LuMapPin, AiOutlineClockCircle, MdKeyboardArrowLeft, MdKeyboardArrowRight, AiOutlineStar } from "../../assets/icons/vander"
import MessageFullCard from '../MessageFullCard';
import CommonModel from '../commonmodel';
import ApplyListing from '../models/applylistingmodel';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCandidates } from '../../actions';
import { getUserIdSelector } from '../../reducers/selectors/user.selector';
import 'draft-js/dist/Draft.css';


export default function JobList(props) {

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedListing, setSelectedListing] = useState('');

    const [pageNum, setPageNum] = useState(1);
    const [fromNum, setFromNum] = useState(0);
    const [toNum, setToNum] = useState(0);

    const [numOfListingsPerPage, setNumOfListingsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(4);
    const [numOfPages, setNumOfPages] = useState(1);

    useEffect(() => {
        if (props.listings && props.listings.length > 0) {
            setNumOfPages(props.listings % numOfListingsPerPage);
        } else {
            setNumOfPages(0);
        }

    }, [])

    useEffect(() => {
        if(pageNum == 1){
            if(props.listings.length >= numOfListingsPerPage){
                setFromNum(1);
                setToNum(pageNum * numOfListingsPerPage);
            }else{
                setFromNum(1);
                setToNum(props.listings.length)
            }
            
        }
        if(pageNum > 1){
            setFromNum(((pageNum -1)*numOfListingsPerPage)+1);
            
            if(props.listings.length / pageNum < numOfListingsPerPage){
                setToNum(((pageNum -1)*numOfListingsPerPage) + props.listings.length % numOfListingsPerPage);
            }else{
                setToNum(pageNum * numOfListingsPerPage);
            }
            
        }
    },[pageNum])

    const userId = useSelector(getUserIdSelector);
    const navigate = useNavigate();

    const openModal = (listing) => {
        setSelectedListing(listing);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const showApplications = (id) => {
        navigate('/applications/' + id);
    }

    const increasePageNum = () => {
        setStartIndex((pageNum + 1));
        setPageNum(pageNum + 1);
    }
    const decreasePageNum = () => {
        if (pageNum - 1 > 0) {
            setPageNum(pageNum - 1);
        }
    }

    return (
        <>
            {isModalOpen && <ApplyListing
                header={'Apply For the Job!'}
                userId={userId}
                closeModal={closeModal}
                closeButtonText={'Close'}
                applyButtonText={'Apply'}
                listing={selectedListing} />}

            <div className="lg:col-span-8 md:col-span-6">
            {props.listings && props.listings.length > 0 && <div className="flex justify-between mb-10">
                    {/* Element on the left edge */}
                    <div className="text-left">
                    {pageNum > 1 && <button to="#" className="w-[90px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                            onClick={() => decreasePageNum()}>
                            <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" /> PREV
                        </button>}
                    </div>

                    {props.listings && props.listings.length > 0 && <div className="text-center">
                        <p>{fromNum+' - '+toNum} / {props.listings.length}</p>
                    </div>}

                    {/* Element on the right edge */}
                    <div className="text-right">
                        {(props.listings.length > ((pageNum) * numOfListingsPerPage)) && <button to="#" className="w-[90px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                            onClick={() => increasePageNum()}>
                            NEXT <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                        </button>}
                    </div>
                </div>}
                <div className="grid grid-cols-1 gap-[30px] mt-5">
                    {props.listings && props.listings.slice(fromNum-1,toNum).map((item, index) => {
                        return <>
                        {item.status === 'active' && <div className="group relative overflow-hidden bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-gray-700 dark:hover:shadow-gray-700 hover:-mt-2 rounded-md transition-all duration-500 h-fit" key={index}>
                            <div className="p-6">
                                <div className="flex items-center">
                                    <div className="w-14 h-14 min-w-[56px] flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                        <img src={item.orgDetails && item.orgDetails.orgLogo} className="h-8 w-8" alt="" />
                                    </div>

                                    <div className="ms-3">
                                        <Link to={`/jdetails/${item.id}`} className="inline-block text-[16px] font-semibold hover:text-emerald-600 transition-all duration-500 me-1">{item.title}</Link>
                                        <span className="inline-block text-sm text-slate-400">{item.orgName}</span>
                                        {item.listingType !== 'quick' && <div>
                                            <span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">{item.jobType}</span>
                                            <span className="text-sm font-medium inline-block me-1">Est. time: <span className="text-slate-400">{item.durationInMonths + ' months'}</span></span>
                                            <span className="text-sm font-medium inline-block me-1">Hourly: <span className="text-slate-400">{item.salaryRangeFrom + ' - ' + item.salaryRangeTo}</span></span>
                                        </div>}
                                    </div>
                                </div>

                                <p className="text-slate-400 py-3">
                                    <div className="draft-js-content" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                    {/* {item.description && item.description.length > 150 ? item.description.slice(0, 150) + '... ' : item.description}<Link to="/jdetails">show more</Link> */}
                                </p>

                                <div>
                                    {item.skills && item.skills.map((skill, index) => (
                                        <span className="bg-slate-100 dark:bg-slate-800 inline-block text-slate-900 dark:text-slate-300 text-xs px-2.5 py-0.5 font-semibold rounded-full me-1">{skill}</span>
                                    ))}
                                </div>
                            </div>

                            <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 lg:flex justify-between items-center">
                            
                                <Link to={`/jdetails/${item.id}`} className="btn btn-sm rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full lg:w-auto lg:mt-0 mt-4">{'Details'}</Link>
                                {item.listingType !== 'quick' && <div className="lg:inline-block flex justify-between">
                                    {/* <span className="inline-block me-1 font-semibold"><i className="mdi mdi-check-decagram mdi-18px text-blue-500 me-1"></i>{'Verified'}</span>
                                    <ul className="list-none inline-block me-1 text-yellow-400 space-x-0.5">
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                        <li className="inline"><i className="mdi mdi-star text-lg"></i></li>
                                    </ul> */}
                                    <span className="inline-flex me-1 items-center text-slate-400"><LuMapPin className="text-[18px] text-slate-900 dark:text-white me-1" />{item.city + '-' + item.livingState + ',' + item.country}</span>
                                </div>}
                                {/* <Link onClick={openModal} className="btn btn-sm rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full lg:w-auto lg:mt-0 mt-4">{'Apply'}</Link> */}
                                {props.srcComponent === 'home' && <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-100 border-emerald-100 hover:border-emerald-100 text-white"
                                    onClick={() => openModal(item)}>Apply</button>}
                                {props.srcComponent === 'mylistings' && (item.appliedCandidates && item.appliedCandidates.length > 0) && <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-100 border-emerald-100 hover:border-emerald-100 text-white"
                                    onClick={() => showApplications(item.id)}>{'Applications  - '+item?.appliedCandidates?.length}</button>}
                            </div>
                            {item.orgDetails && item.orgDetails.orgVerified === true ? <span className="w-24 text-white p-1 text-center absolute ltr:-rotate-45 rtl:rotate-45 -start-[30px] top-3 bg-yellow-400 flex justify-center"><AiOutlineStar /></span> : ""}
                            <span className="inline-block text-sm text-black-400 absolute top-0 end-0 m-3">{item.postedDate.substring(0,item.postedDate.indexOf('T'))}</span>
                            {/* <Link to="#" className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white absolute top-0 end-0 m-3"><Bookmark className="h-4 w-4"></Bookmark></Link> */}
                        </div>}
                        </>
                    })}
                    {props.listings.length === 0 &&
                        <MessageFullCard
                            headerTitle={'No listings posted.'} />

                    }
                </div>
                {props.listings && props.listings.length > 0 && <div className="flex justify-between mb-10">
                    {/* Element on the left edge */}
                    <div className="text-left">
                    {pageNum > 1 && <button to="#" className="w-[90px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                            onClick={() => decreasePageNum()}>
                            <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" /> PREV
                        </button>}
                    </div>

                    {props.listings && props.listings.length > 0 && <div className="text-center">
                        <p>{fromNum+' - '+toNum} / {props.listings.length}</p>
                    </div>}

                    {/* Element on the right edge */}
                    <div className="text-right">
                        {(props.listings.length > ((pageNum) * numOfListingsPerPage)) && <button to="#" className="w-[90px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                            onClick={() => increasePageNum()}>
                            NEXT <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                        </button>}
                    </div>
                </div>}
            </div>
        </>
    )
}