import { combineReducers } from "@reduxjs/toolkit";
import { sampleReducer } from "./sample.reducer";
import { userReducer } from "./user.reducer";
import { listingsReducer } from "./listings.reducer";
import {utilReducer} from './util.reducer';
import {whitelistReducer} from './whitelist.reducer';

const rootReducer = () =>
    combineReducers({
        sampleReducer: sampleReducer,
        whitelistReducer: whitelistReducer,
        userReducer: userReducer,
        listingsReducer: listingsReducer,
        utilReducer: utilReducer
    });

export default rootReducer;    