import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../../components/Navbar'
import JobListCompTwo from '../../components/job-list-comp-two'
import Feature from '../../components/Feature'
import Footer from '../../components/Footer'
import { LuSearch } from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';

import { BiBriefcaseAlt2, PiMapPin } from "../../assets/icons/vander"
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { getListings, getOrgListings, getUserListings, setLoader } from '../../actions';
import { getListingsSelector } from '../../reducers/selectors/listings.selector';
import JobListCompThree from '../job-list-comp-three';
import JobListCompFour from '../job-list-comp-four';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import Loader from '../loader';
import JobList from '../jobslist';
import { getUserInfoSelector } from '../../reducers/selectors/user.selector';
import MessageFullCard from '../MessageFullCard';

const optionsOne = [
    { value: 'AF', label: 'Afghanistan' },
    { value: 'AZ', label: ' Azerbaijan' },
    { value: 'BS', label: 'Bahamas' },
    { value: 'BH', label: 'Bahrain' },
    { value: 'CA', label: 'Canada' },
    { value: 'CV', label: 'Cape Verde' },
    { value: 'DK', label: 'Denmark' },
    { value: 'DJ', label: 'Djibouti' },
    { value: 'ER', label: 'Eritrea' },
    { value: 'EE', label: 'Estonia' },
    { value: 'GM', label: 'Gambia' },
]

const optionsTwo = [
    { value: '1', label: 'Full Time' },
    { value: '2', label: 'Part Time' },
    { value: '3', label: 'Freelancer' },
    { value: '4', label: 'Remote Work' },
    { value: '5', label: 'Office Work' },
]

const usaStates = 'Alabama,Alaska,Arizona,Arkansas,California,Colorado,Connecticut,Delaware,Florida,Georgia,Hawaii,Idaho,Illinois,Indiana,Iowa,Kansas,Kentucky,Louisiana,Maine,Maryland,Massachusetts,Michigan,Minnesota,Mississippi,Missouri,Montana,Nebraska,Nevada,New Hampshire,New Jersey,New Mexico,New York,North Carolina,North Dakota,Ohio,Oklahoma,Oregon,Pennsylvania,Rhode Island,South Carolina,South Dakota,Tennessee,Texas,Utah,Vermont,Virginia,Washington,West Virginia,Wisconsin,Wyoming';

export default function MyListings() {
    const [selectedOption, setSelectedOption] = useState('1');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const listingsOriginal = useSelector(getListingsSelector);
    const loader = useSelector(activateLoaderSelector);
    const userInfo = useSelector(getUserInfoSelector);
    const [usaStatesList, setUsaStatesList] = useState([]);

    const [listings,setListings] = useState([]);
    const [orgName, setOrgName] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [jobType, setJobType] = useState([]);
    const [salary, setSalary] = useState('');
    const [fullTimeChecked, setFullTimeChecked] = useState(false);
    const [contractChecked, setContractChecked] = useState(false);
    const [remoteChecked, setRemoteChecked] = useState(false);
    const [partTimeChecked, setPartTimeChecked] = useState(false);
    const [hourlyChecked, setHourlyChecked] = useState(false);
    const [monthlyChecked, setMonthlyChecked] = useState(false);



    useEffect(() => {
        console.log('u info...', userInfo)
        if (userInfo && userInfo.userType === 'admin') {
            dispatch(setLoader(true));
            dispatch(getOrgListings(userInfo.orgId));
        } else {
            dispatch(setLoader(true));
            dispatch(getUserListings(userInfo.id))
        }
    }, [])

    useEffect(() => {
        setListings(listingsOriginal);
    },[listingsOriginal])
    useEffect(() => {
        if (userInfo && userInfo.userType === 'admin') {
            dispatch(setLoader(true));
            dispatch(getOrgListings(userInfo.orgId));

        } else {
            dispatch(setLoader(true));
            dispatch(getUserListings(userInfo.id))
        }
        setListings(listingsOriginal);
    }, [userInfo]);

    useEffect(() => {

        if (!orgName && !category && !location && !fullTimeChecked && !contractChecked && !partTimeChecked && !monthlyChecked && !hourlyChecked) {
            setListings(listingsOriginal);
        } else {

            let filteredListings = [];
            let dupListings = [];
            if (orgName) {
                filteredListings = listingsOriginal.filter(l => l.orgName.toLowerCase().includes(orgName.toLowerCase()));
                dupListings = [...dupListings, ...filteredListings];
            }
            if (category) {
                filteredListings = listingsOriginal.filter(l => l.categories.toLowerCase() === category.toLowerCase());
                dupListings = [...dupListings, ...filteredListings];
            }
            if (location) {
                filteredListings = listingsOriginal.filter(l => l.livingState.toLowerCase() === location.toLowerCase());
                dupListings = [...dupListings, ...filteredListings];
            }
            if (fullTimeChecked) {
                filteredListings = listingsOriginal.filter(li => li.jobType === 'Full Time');
                dupListings = [...dupListings, ...filteredListings];

            }
            if (partTimeChecked) {
                filteredListings = listingsOriginal.filter(li => li.jobType === 'Part Time');
                dupListings = [...dupListings, ...filteredListings];
            }
            if (contractChecked) {
                filteredListings = listingsOriginal.filter(li => li.jobType === 'Contract');
                dupListings = [...dupListings, ...filteredListings];

            }
            if (hourlyChecked) {
                filteredListings = listingsOriginal.filter(li => li.salaryType === 'Hourly');
                dupListings = [...dupListings, ...filteredListings];
            }
            if (monthlyChecked) {
                filteredListings = listingsOriginal.filter(li => li.salaryType === 'Monthly');
                dupListings = [...dupListings, ...filteredListings];
            }
            const uniqueIds = new Set();
            let finalListings = [];
            dupListings.filter(obj => {
                if (!uniqueIds.has(obj.id)) {
                    uniqueIds.add(obj.id);
                    finalListings = [...finalListings, obj]
                }
            });
            setListings(finalListings);

        }

    }, [orgName, category, location, salary, fullTimeChecked, contractChecked, partTimeChecked, monthlyChecked, hourlyChecked])

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    if (loader) {
        return <Loader />
    }
    
    const navigateToPostJobs = () => {
        navigate('/jobposting');
    }

    const handleJobType = (value) => {
        console.log('e....', value)
        if (value === 'Full Time') {
            if (!fullTimeChecked) {
                setFullTimeChecked(!fullTimeChecked);
                setJobType([...jobType, value]);
            } else {
                setFullTimeChecked(!fullTimeChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Contract') {
            if (!contractChecked) {
                setContractChecked(!contractChecked);
                setJobType([...jobType, value]);
            } else {
                setContractChecked(!contractChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Remote') {
            if (!remoteChecked) {
                setRemoteChecked(!remoteChecked);
                setJobType([...jobType, value]);
            } else {
                setRemoteChecked(!remoteChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Part Time') {
            if (!partTimeChecked) {
                setPartTimeChecked(!partTimeChecked);
                setJobType([...jobType, value]);
            } else {
                setPartTimeChecked(!partTimeChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Hourly') {
            if (!hourlyChecked) {
                setHourlyChecked(!hourlyChecked);
                setJobType([...jobType, value]);
            } else {
                setHourlyChecked(!hourlyChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Hourly') {
            if (!hourlyChecked) {
                setHourlyChecked(!hourlyChecked);
                setJobType([...jobType, value]);
            } else {
                setHourlyChecked(!hourlyChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Monthly') {
            if (!monthlyChecked) {
                setMonthlyChecked(!monthlyChecked);
                setJobType([...jobType, value]);
            } else {
                setMonthlyChecked(!monthlyChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        console.log('j..', jobType);
    }
    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover"/>
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
                
            {listings && listings.length > 0 ? <>

                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <form>
                                    <div className="grid grid-cols-1 gap-3">
                                        <div>
                                            <label htmlFor="searchname" className="font-semibold">Search Company</label>
                                            <div className="relative mt-2">
                                                <LuSearch className="text-lg absolute top-[5px] start-3" />
                                                <input name="search" id="searchname" type="text"
                                                    className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                                                    placeholder="Search" 
                                                    onChange={(e) => { setOrgName(e.target.value) }}/>
                                            </div>
                                        </div>

                                        <div>
                                            <label className="font-semibold">Categories</label>
                                            <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => setCategory(e.target.value)}>
                                                <option value="WD">Web Designer</option>
                                                <option value="WD">Web Developer</option>
                                                <option value="UI">UI / UX Desinger</option>
                                            </select>

                                        </div>

                                        <div>
                                            <label className="font-semibold">Location</label>
                                            <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => { setLocation(e.target.value) }}>
                                                <option value=""></option>
                                                {usaStates.split(',').map((st, index) => (<option key={index} value={st}>{st}</option>))}
                                            </select>
                                        </div>

                                        <div>
                                            <label className="font-semibold">Job Types</label>
                                            <div className="block mt-2">
                                                <div className="flex justify-between">
                                                    <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                            checked={fullTimeChecked}
                                                            onChange={(e) => { handleJobType('Full Time') }}  />
                                                        <span className="ms-2 text-slate-400">Full Time</span>
                                                    </label>

                                                    <span
                                                        className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">3</span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" 
                                                            onChange={(e) => { handleJobType('Contract') }}/>
                                                        <span className="ms-2 text-slate-400">Contract</span>
                                                    </label>

                                                    <span
                                                        className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">7</span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" 
                                                            onChange={(e) => { handleJobType('Part Time') }}/>
                                                        <span className="ms-2 text-slate-400">Part Time</span>
                                                    </label>

                                                    <span
                                                        className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">4</span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" 
                                                            onChange={(e) => { handleJobType('Remote') }}/>
                                                        <span className="ms-2 text-slate-400">Remote</span>
                                                    </label>

                                                    <span
                                                        className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">6</span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" 
                                                            onChange={(e) => { handleJobType('Hourly') }}/>
                                                        <span className="ms-2 text-slate-400">Hourly</span>
                                                    </label>

                                                    <span
                                                        className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">7</span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50" 
                                                            onChange={(e) => { handleJobType('Monthly') }}/>
                                                        <span className="ms-2 text-slate-400">Monthly</span>
                                                    </label>

                                                    <span
                                                        className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">44</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <label className="font-semibold">Salary</label>
                                            <div className="block mt-2">
                                                <div>
                                                    <label className="inline-flex items-center">
                                                        <input type="radio"
                                                            className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                            name="radio-colors" value="1"
                                                            defaultChecked={selectedOption === '1'}
                                                            onChange={handleOptionChange}x
                                                        />
                                                        <span className="ms-2 text-slate-400">10k - 15k</span>
                                                        <input type="radio"
                                                            className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                            name="radio-colors" value="2" defaultChecked={selectedOption === '2'}
                                                            onChange={handleOptionChange} />
                                                        <span className="ms-2 text-slate-400">15k - 25k</span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <label className="inline-flex items-center">
                                                        <input type="radio"
                                                            className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                            name="radio-colors" value="3" defaultChecked={selectedOption === '3'}
                                                            onChange={handleOptionChange} />
                                                        <span className="ms-2 text-slate-400">more than 25K</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <input type="submit"
                                                className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full"
                                                value="Apply Filter" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <JobListCompTwo listings={listings}/> */}
                        <JobList listings={listings} srcComponent={'mylistings'}/>
                    </div> 
                </div> </>:
                    <div className="container z-1" style={{ paddingBottom: 20 }}>
                        <MessageFullCard
                            headerTitle={'No listings posted so far.'}
                            text={'Post Job Listings'} 
                            linkTo={'jobpost'}
                            buttonText={'Post Job'}/>
                    </div>
                }
                {/* <Feature /> */}

            </section>
            <Footer />
        </>
    )
}
