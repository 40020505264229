export const listingsConstants = {
    RESET_LISTINGS_STATE: 'RESET_LISTINGS_STATE',
    LISTINGS_REQUEST: 'LISTINGS_REQUEST',
    LISTINGS_REQUEST_SUCCESS: 'LISTINGS_REQUEST_SUCCESS',
    LISTINGS_REQUEST_FAILURE: 'LISTINGS_REQUEST_FAILURE',
    POST_LISTINGS_REQUEST: 'POST_LISTINGS_REQUEST',
    POST_LISTINGS_SUCCESS: 'POST_LISTINGS_SUCCESS',
    POST_LISTINGS_FAILURE: 'POST_LISTINGS_FAILURE',
    GET_USER_LISTINGS:'GET_USER_LISTINGS',
    GET_USER_LISTINGS_SUCCESS:'GET_USER_LISTINGS_SUCCESS',
    GET_USER_LISTINGS_FAILURE:'GET_USER_LISTINGS_FAILURE',
    GET_ORG_LISTINGS:'GET_ORG_LISTINGS',
    GET_ORG_LISTINGS_SUCCESS:'GET_ORG_LISTINGS_SUCCESS',
    GET_ORG_LISTINGS_FAILURE:'GET_ORG_LISTINGS_FAILURE',
    APPLY_LISTING:'APPLY_LISTING',
    APPLY_LISTING_SUCCESS:'APPLY_LISTING_SUCCESS',
    APPLY_LISTING_FAILURE:'APPLY_LISTING_FAILURE',
    RESET_APPLY_LISTING_RESPONSE:'RESET_APPLY_LISTING_RESPONSE',
    GET_APPLIED_CANDIDATES:'GET_APPLIED_CANDIDATES',
    GET_APPLIED_CANDIDATES_SUCCESS:'GET_APPLIED_CANDIDATES_SUCCESS',
    GET_APPLIED_CANDIDATES_FAILURE:'GET_APPLIED_CANDIDATES_FAILURE',
    SEARCH_LISTINGS:'SEARCH_LISTINGS',
    SEARCH_LISTINGS_SUCCESS:'SEARCH_LISTINGS_SUCCESS',
    SEARCH_LISTINGS_FAILURE:'SEARCH_LISTINGS_FAILURE',
    UPDATE_APPLIED_STATUS:'UPDATE_APPLIED_STATUS',
    UPDATE_APPLIED_STATUS_SUCCESS:'UPDATE_APPLIED_STATUS_SUCCESS',
    UPDATE_APPLIED_STATUS_FAILURE:'UPDATE_APPLIED_STATUS_FAILURE',
    SEARCH_LISTING_BY_ID:'SEARCH_LISTING_BY_ID',
    SEARCH_LISTING_BY_ID_SUCCESS:'SEARCH_LISTING_BY_ID_SUCCESS',
    SEARCH_LISTING_BY_ID_FAILURE:'SEARCH_LISTING_BY_ID_FAILURE',
    DELETE_LISTING:'DELETE_LISTING',
    DELETE_LISTING_SUCCESS:'DELETE_LISTING_SUCCESS',
    DELETE_LISTING_FAILURE:'DELETE_LISTING_FAILURE',
    EDIT_LISTING:'EDIT_LISTING',
    EDIT_LISTING_SUCCESS:'EDIT_LISTING_SUCCESS',
    EDIT_LISTING_FAILURE:'EDIT_LISTING_FAILURE'
}