import { createAction } from '@reduxjs/toolkit';
import { serviceConstants, userConstants } from '../constants';


export const attemptLogin = createAction(
    userConstants.ATTEMPT_LOGIN,
    (payload) => ({ payload })
  );
  export const attemptLoginSuccess = createAction(
    userConstants.ATTEMPT_LOGIN_SUCCESS,
    (payload) => ({ payload })
  );
  export const attemptLoginFailure = createAction(
    userConstants.ATTEMPT_LOGIN_FAILURE,
    (payload) => ({ payload })
  );

  export const attemptOrgRegistration = createAction(
    userConstants.ATTEMPT_ORG_REG,
    (payload) => ({ payload })
  );
  export const attemptOrgRegistrationSuccess = createAction(
    userConstants.ATTEMPT_ORG_REG_SUCCESS,
    (payload) => ({ payload })
  );
  export const attemptOrgRegistrationFailure = createAction(
    userConstants.ATTEMPT_ORG_REG_FAILURE,
    (payload) => ({ payload })
  );

  export const attemptUserRegistration = createAction(
    userConstants.ATTEMPT_USER_REG,
    (payload) => ({ payload })
  );
  export const attemptUserRegistrationSuccess = createAction(
    userConstants.ATTEMPT_USER_REG_SUCCESS,
    (payload) => ({ payload })
  );
  export const attemptUserRegistrationFailure = createAction(
    userConstants.ATTEMPT_USER_REG_FAILURE,
    (payload) => ({ payload })
  );
  export const resetUserState = () => ({
    type: userConstants.RESET_USER_STATE,
  });
  export const attemptLogout = () => ({
    type: userConstants.ATTEMPT_LOGOUT,
  });
  export const resetLoginAttemptMessage = () => ({
    type: userConstants.RESET_LOGIN_ATTEMPT_MESSAGE,
  });
  export const userRegStatusRequest = () => ({
    type: userConstants.USER_REG_STATUS_REQUEST,
  });
  export const addCandidateRequest = createAction(
    userConstants.ADD_CANDIDATE_REQUEST,
    (payload) => ({ payload })
  );
  export const addCandidateRequestSuccess = createAction(
    userConstants.ADD_CANDIDATE_REQUEST_SUCCESS,
    (payload) => ({ payload })
  );
  export const addCandidateRequestFailure = createAction(
    userConstants.ADD_CANDIDATE_REQUEST_FAILURE,
    (payload) => ({ payload })
  );

  export const uploadFiles = createAction(
    userConstants.UPLOAD_FILES_REQUEST,
    (payload) => ({ payload })
  );
  
  export const uploadFilesSuccess = createAction(
    userConstants.UPLOAD_FILES_SUCCESS,
    (payload) => ({ payload })
  );
  
  export const uploadFilesFailure = createAction(
    userConstants.UPLOAD_FILES_FAILURE,
    (payload) => ({ payload })
  );
  export const resetUploadFilesStatus = createAction(
    userConstants.RESET_UPLOAD_FILES_STATUS,
    (payload) => ({ payload })
  );
  export const resetNewlyAddedCandidate = createAction(
    userConstants.RESET_NEWLY_ADDED_CANDIDATE_STATUS,
    (payload) => ({ payload })
  );
  export const getAllCandidates = createAction(
    userConstants.GET_ALL_CANDIDATES,
    (payload) => ({ payload })
  );
  export const getAllCandidatesSuccess = createAction(
    userConstants.GET_ALL_CANDIDATES_SUCCESS,
    (payload) => ({ payload })
  );
  export const getAllCandidatesFailure = createAction(
    userConstants.GET_ALL_CANDIDATES_FAILURE,
    (payload) => ({ payload })
  );
  export const getCandidateInfo = createAction(
    userConstants.GET_CANDIDATE_INFO,
    (payload) => ({ payload })
  );
  export const getCandidateInfoSuccess = createAction(
    userConstants.GET_CANDIDATE_INFO_SUCCESS,
    (payload) => ({ payload })
  );
  export const getCandidateInfoFailure = createAction(
    userConstants.GET_CANDIDATE_INFO_FAILURE,
    (payload) => ({ payload })
  );
  export const getUsers = createAction(
    userConstants.GET_USERS,
    (payload) => ({ payload })
  );
  export const getUsersSuccess = createAction(
    userConstants.GET_USERS_SUCCESS,
    (payload) => ({ payload })
  );
  export const getUsersFailure = createAction(
    userConstants.GET_USERS_FAILURE,
    (payload) => ({ payload })
  );
  export const createNewUser = createAction(
    userConstants.CREATE_NEW_USER,
    (payload) => ({ payload })
  );
  export const createNewUserSuccess = createAction(
    userConstants.CREATE_NEW_USER_SUCCESS,
    (payload) => ({ payload })
  );
  export const createNewUserFailure = createAction(
    userConstants.CREATE_NEW_USER_FAILURE,
    (payload) => ({ payload })
  );
  export const deleteUser = createAction(
    userConstants.DELETE_USER,
    (payload) => ({ payload })
  );
  export const deleteUserSuccess = createAction(
    userConstants.DELETE_USER_SUCCESS,
    (payload) => ({ payload })
  );
  export const deleteUserFailure = createAction(
    userConstants.DELETE_USER_FAILURE,
    (payload) => ({ payload })
  );
  export const activateUser = createAction(
    userConstants.ACTIVATE_USER,
    (payload) => ({ payload })
  );
  export const activateUserSuccess = createAction(
    userConstants.ACTIVATE_USER_SUCCESS,
    (payload) => ({ payload })
  );
  export const activateUserFailure = createAction(
    userConstants.ACTIVATE_USER_FAILURE,
    (payload) => ({ payload })
  );
  export const createSubscription = createAction(
    userConstants.STRIPE_CREATE_SUBSCRIPTION,
    (payload) => ({ payload })
  );
  export const createSubscriptionSuccess = createAction(
    userConstants.STRIPE_CREATE_SUBSCRIPTION_SUCCESS,
    (payload) => ({ payload })
  );
  export const createSubscriptionFailure = createAction(
    userConstants.STRIPE_CREATE_SUBSCRIPTION_FAILURE,
    (payload) => ({ payload })
  );
  export const updateUserInfo = createAction(
    userConstants.UPDATE_USER_INFO,
    (payload) => ({ payload })
  );
  export const updateUserInfoSuccess = createAction(
    userConstants.UPDATE_USER_INFO_SUCCESS,
    (payload) => ({ payload })
  );
  export const updateUserInfoFailure = createAction(
    userConstants.UPDATE_USER_INFO_FAILURE,
    (payload) => ({ payload })
  );
  export const changePassword = createAction(
    userConstants.CHANGE_PASSWORD,
    (payload) => ({ payload })
  );
  export const changePasswordSuccess = createAction(
    userConstants.CHANGE_PASSWORD_SUCCESS,
    (payload) => ({ payload })
  );
  export const changePasswordFailure = createAction(
    userConstants.CHANGE_PASSWORD_FAILURE,
    (payload) => ({ payload })
  );   
  export const resetPasswordChangeMessage = createAction(
    userConstants.RESET_PASSWORD_CHANGE_MESSAGE,
    (payload) => ({ payload })
  );
  export const postContactus = createAction(
    userConstants.POST_CONTACTUS,
    (payload) => ({ payload })
  );
  export const postContactusSuccess = createAction(
    userConstants.POST_CONTACTUS_SUCCESS,
    (payload) => ({ payload })
  );   
  export const postContactusFailure = createAction(
    userConstants.POST_CONTACTUS_FAILURE,
    (payload) => ({ payload })
  );
  export const cancelSubscription = createAction(
    userConstants.CANCEL_SUBSCRIPTION,
    (payload) => ({ payload })
  );
  export const cancelSubscriptionSuccess = createAction(
    userConstants.CANCEL_SUBSCRIPTION_SUCCESS,
    (payload) => ({ payload })
  );   
  export const cancelSubscriptionFailure = createAction(
    userConstants.CANCEL_SUBSCRIPTION_FAILURE,
    (payload) => ({ payload })
  );
  export const sendAddCandidate = createAction(
    userConstants.SEND_ADD_CANDIDATE_REQUEST,
    (payload) => ({ payload })
  );
  export const sendAddCandidateSuccess = createAction(
    userConstants.SEND_ADD_CANDIDATE_REQUEST_SUCCESS,
    (payload) => ({ payload })
  );   
  export const sendAddCandidateFailure = createAction(
    userConstants.SEND_ADD_CANDIDATE_REQUEST_FAILURE,
    (payload) => ({ payload })
  );
  export const searchCandidate = createAction(
    userConstants.SEARCH_CANDIDATE,
    (payload) => ({ payload })
  );
  export const searchCandidateSuccess = createAction(
    userConstants.SEARCH_CANDIDATE_SUCCESS,
    (payload) => ({ payload })
  );   
  export const searchCandidateFailure = createAction(
    userConstants.SEARCH_CANDIDATE_FAILURE,
    (payload) => ({ payload })
  );
  export const updateJobNotificaitonsStatus = createAction(
    userConstants.UPDATE_JOB_NOTIFICATIONS_STATUS,
    (payload) => ({ payload })
  );
  export const updateJobNotificaitonsStatusSuccess = createAction(
    userConstants.UPDATE_JOB_NOTIFICATIONS_STATUS_SUCCESS,
    (payload) => ({ payload })
  );   
  export const updateJobNotificaitonsStatusFailure = createAction(
    userConstants.UPDATE_JOB_NOTIFICATIONS_STATUS_FAILURE,
    (payload) => ({ payload })
  );
  export const getEmployerInfo = createAction(
    userConstants.GET_EMPLOYER_INFO,
    (payload) => ({ payload })
  );
  export const getEmployerInfoSuccess = createAction(
    userConstants.GET_EMPLOYER_INFO_SUCCESS,
    (payload) => ({ payload })
  );   
  export const getEmployerInfoFailure = createAction(
    userConstants.GET_EMPLOYER_INFO_FAILURE,
    (payload) => ({ payload })
  );