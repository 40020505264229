import React, { useEffect, useState } from 'react'
import "../../../node_modules/react-modal-video/scss/modal-video.scss";
import bg6 from '../../assets/images/hero/bg6.png';
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer';
import JobCounter from '../../components/Job-counter';
import { BiBriefcaseAlt2, BiCheckCircle, PiMapPin, } from "../../assets/icons/vander";
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { getSubscriptionCreatedSelector, getSubscriptionResponseSelector, getUserInfoSelector, isLoggedInSelector, userRegRequestSelector } from '../../reducers/selectors/user.selector';
import { products, serviceConstants } from '../../constants';
import { createSubscription, setLoader } from '../../actions';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import { Loader } from 'react-feather';



const stripePromise = loadStripe(serviceConstants.STRIPE_PUBLISH_KEY);

export const AddPaymentMethod = (props) => {
    const elements = useElements()
    const stripe = useStripe()

    return (
        <div style={{ width: "100%", alignSelf: "center" }}>
            <CardElement onChange={(e) => {
                // setError(e.error);
                // setCardComplete(e.complete);
                if (e.complete) {
                    console.log(e, "---", elements.getElement(CardElement))
                    console.log(stripe.createToken(elements.getElement(CardElement)).then(response => {
                        console.log("TOKEN RESPONSE ", response)
                        props.setNewCardDetails(response)
                    }).catch((e) => console.log("TOKEN ERROR ", e)))
                }
                console.log("CARD ON CHANGE ", e)
            }}
            />
        </div>
    )

}

export default function Pricing() {


    const dispatch = useDispatch();

    const userRegRequest = useSelector(userRegRequestSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);
    const navigate = useNavigate();
    const [newCardDetails, setNewCardDetails] = useState();
    const [selectedCard, setSelectedCard] = useState();
    const [cardMode, setCardMode] = useState('');
    const [pricingTerms, setPricingTerms] = useState(false);

    const userInfo = useSelector(getUserInfoSelector);
    const loader = useSelector(activateLoaderSelector);


    const [showCardSection, setShowCardSection] = useState(false);

    const subscriptionResponse = useSelector(getSubscriptionResponseSelector);

    useEffect(() => {
        if (userInfo && userInfo.userType === 'recr') {
            navigate('/mylistings');
        } else {
            console.log('pri...', userInfo.stripeStatus, userInfo.productSelected, subscriptionResponse?.id)
            if (userInfo && (userInfo.stripeStatus === 'trialing' || userInfo.stripeStatus === 'active' || userInfo.productSelected || subscriptionResponse?.id)) {
                navigate('/home');
            }
        }
    }, [userInfo, subscriptionResponse])

    const getStarted = (product) => {
        if (!isLoggedIn) {
            navigate('/signup')
        } else {
            setSelectedCard(product);
            setShowCardSection(true);
        }
    }
    const backToProducts = () => {
        setPricingTerms(false);
        setNewCardDetails('');
        setShowCardSection(false);
    }

    const subscribe = () => {
        if (newCardDetails && newCardDetails.token.id) {
            console.log('1...', newCardDetails && newCardDetails.token.id);
            const payload = {
                username: userInfo.userId,
                id: userInfo.id,
                userId: userInfo.userId,
                token: newCardDetails.token.id,
                currency: 'USD',
                productId: selectedCard.productId,
                productName: selectedCard.productName,
                trialPeriod: 90,
                couponId: 'pilot'
            }
            console.log('pay...', payload)
            dispatch(setLoader(true));
            dispatch(createSubscription(payload));
        }
    }

    const selectCard = (card) => {
        setCardMode(card);
    }

    return (
        <Elements stripe={stripePromise}>
            <Navbar navClass='justify-end' isContainerFluid={true} />
            {loader && <Loader />}
            <section className="relative table md:pt-40 pt-36 w-full bg-gradient-to-b from-emerald-600/20 dark:from-emerald-600/40 via-emerald-600/10 dark:via-emerald-600/20 to-transparent">
                {!showCardSection && <div className="container z-1">
                    <div className="counter-box text-center">
                        <div className="grid grid-cols-1 text-center relative">
                            <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">Please choose one of our awesome packages</h4>
                            {/* <p className="text-slate-400 text-lg max-w-xl mx-auto">Find Jobs, Employment & Career Opportunities.</p>
                            <div className="mt-4">
                                <span className="text-slate-400"><span className="text-slate-900 dark:text-white">Popular Searches :</span> Designer, Developer, Web, IOS, PHP Senior Engineer</span>
                            </div> */}
                        </div>
                    </div>
                    <div className="grid md:grid-cols-4 grid-cols-4 gap-[30px]">
                        {products && products.map((p, index) => (<div className="group border border-transparent relative shadow hover:shadow-md dark:shadow-gray-800 rounded-md bg-white dark:bg-slate-900 transition-all duration-500">
                            <div className="p-6 py-8">
                                <h6 className="text-lg font-bold uppercase mb-5 text-emerald-600">{p.productTitle}</h6>

                                <div className="flex mb-5">
                                    <span className="text-l font-semibold self-end mb-1">{p.noOfUsers + ' Users - '}</span>
                                    <span className="text-xl font-semibold">$</span>
                                    <span className="price text-4xl font-semibold mb-0">{p.productPrice}</span>
                                    <span className="text-xl font-semibold self-end mb-1">/mo</span>
                                </div>
                                {(p.productTitle === 'Standard' || p.productTitle === 'Essential') &&
                                    <><h3 className="text-sm font-bold text-red-600">Free First 3 Months</h3>
                                        <h2 className="text-sm mb-5 text-slate-600">(Offer ends by 30 APR 2024)</h2>
                                    </>}
                                {(p.productTitle === 'Executive' || p.productTitle === 'Premium') &&
                                    <><h2 className="text-sm font-bold mb-8 text-red-600">7 Days Free Trial</h2>
                                    </>}

                                <ul className="list-none text-slate-400 border-t border-gray-100 dark:border-gray-700 pt-5">
                                    {p.activities.map(a => (<li className="my-2 flex items-center"><BiCheckCircle className="text-emerald-600 text-xl me-2" /> {a}</li>))}
                                </ul>
                                <div className="mb-1">
                                    <input type="submit" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value={isLoggedIn ? "Subscribe" : "Get Started"}
                                        onClick={() => getStarted(p)} />
                                </div>
                                {/* <Link to="#" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md mt-5">Get Started</Link> */}
                            </div>
                        </div>))}
                    </div>
                </div>}
                {showCardSection && <div className="container z-1">


                    <div className="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px] z-1">
                        {/* <div className="counter-box text-center">
                            <div className="grid grid-cols-1 text-center relative">
                                <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">Built for corp-to-corp, <br /> all recruiters at one place</h4>
                                <p className="text-slate-400 text-lg max-w-xl mx-auto">Find Jobs, Employment & Career Opportunities.</p>
                                <div className="mt-4">
                                    <span className="text-slate-400"><span className="text-slate-900 dark:text-white">Popular Searches :</span> Designer, Developer, Web, IOS, PHP Senior Engineer</span>
                                </div>
                            </div>
                        </div> */}

                        <div className="counter-box text-center">

                        </div>

                        <div className="counter-box text-center">
                            <div className="p-6">
                                {/* <Link to="#">
                <img src={logo_dark} className="mx-auto h-[24px] block dark:hidden" alt="" />
                <img src={logo_light} className="mx-auto h-[24px] dark:block hidden" alt="" />
            </Link> */}
                                {/* <h5 className="my-6 text-xl font-semibold">Selected Prodcut: {selectedCard.productName}</h5> */}

                                {/* <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Select Card:</label>
                                        <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => selectCard(e.target.value)}>
                                                <option value=""></option>
                                            {userInfo?.paymentInfo?.cards?.map(card => (<option value={card.fingerPrint}>{card.lastFourDigits}</option>))}
                                            <option value="new">Add New Card</option>
                                        </select>
                                    </div> */}


                                <div className="grid grid-cols-1">
                                    <h5 className="my-6 text-xl font-semibold">Enter Card Details</h5>
                                    <AddPaymentMethod setNewCardDetails={setNewCardDetails} />
                                    <div className="flex justify-between mb-4">
                                        <div className="inline-flex items-center mb-0">
                                            <input className="form-checkbox rounded border-emerald-200 dark:border-emerald-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe"
                                                onClick={() => { setPricingTerms(!pricingTerms) }} />
                                            <label className="form-checkbox-label text-black-400" htmlFor="RememberMe">Accept Terms&Conditions. Secure Stripe Payment gateway</label>
                                        </div>
                                        <p className="text-slate-400 mb-0"></p>
                                    </div>

                                    <div className="mb-4">
                                        {/* <input type="submit" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value="Login / Sign in" /> */}
                                        {pricingTerms && newCardDetails?.token?.id && <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                                            onClick={() => subscribe()}>Subscribe</button>}
                                        {(!pricingTerms || !newCardDetails?.token?.id) && <button disabled={true} type="submit" id="submit" name="send" className="btn bg-slate-700 hover:bg-slate-800 text-black rounded-md"
                                            onClick={() => subscribe()}>Subscribe</button>}
                                    </div>
                                    <div className="mb-4">
                                        <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                                            onClick={() => backToProducts()}>Back</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>}
                <div className="container">
                    <div className="grid grid-cols-1">
                        <img src={bg6} alt="" />
                    </div>
                </div>
            </section>


            {/* <section className="relative md:pb-24 pb-16 overflow-hidden">
                <PopularJobsfour />
                <BestCompanies />
                <Feature />
                <News />

              <ExploreJob/>
            </section > */}
            <Footer />
        </Elements>
    )
}