import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import JobDetailComp from '../../components/job-detail-comp'
import Footer from '../../components/Footer'
import { Link, useNavigate, useParams } from "react-router-dom";
import { Book, Briefcase, Clock, Delete, DollarSign, MapPin, Monitor, Trash, UserCheck } from 'react-feather';
import { MdOutlineArrowForward } from "react-icons/md"
import ExploreJob from '../../components/Explore-job';
import { jobData } from '../../data/data';
import { useDispatch, useSelector } from 'react-redux';
import { deletedListingSelector, getListingsSelector } from '../../reducers/selectors/listings.selector';
import 'draft-js/dist/Draft.css';
import './edstyle.css'
import { getUserIdSelector, getUserInfoSelector, isLoggedInSelector } from '../../reducers/selectors/user.selector';
import { serviceConstants } from '../../constants';
import axios from 'axios';
import { deleteListing, searchListingById, setLoader } from '../../actions';
import ApplyListing from '../models/applylistingmodel';

export default function JobDetails() {
    const params = useParams();
    const id = params.id

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedListing, setSelectedListing] = useState();
    const listings = useSelector(getListingsSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);
    const userId = useSelector(getUserIdSelector);
    const userInfo = useSelector(getUserInfoSelector);
    const deleted = useSelector(deletedListingSelector);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn) {
            // dispatch(setLoader(true))
            // dispatch(searchListingById(id));
            if (listings && listings.length > 0) {
                const filteredListing = listings && listings.filter(l => l.id === id);
                if (filteredListing && filteredListing.length > 0) {
                    console.log('2...', filteredListing[0])
                    setSelectedListing(filteredListing[0])
                }
            }
        }else{
            axios.get(serviceConstants.LISTINGS_URL+'/searchbyid'+'/'+id)
            .then(response => {
                if(response?.data?.listings?.length > 0){
                    setSelectedListing(response.data.listings[0]);   
                }
            })
        }
    }, []);

    useEffect(() => {
        console.log('*&*&*&*&', deleted)
        if (deleted && selectedListing && deleted.id === selectedListing.id && deleted.status === 'deactive') {
            setSelectedListing(deleted);
        }
    }, [deleted])

    const openModal = (listing) => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const showApplications = (id) => {
        navigate('/applications/' + id);
    }

    const deleteSelectedListing = (id) => {
        const payload = { userId: userId, listingId: selectedListing.id }
        dispatch(setLoader(true))
        dispatch(deleteListing(payload))
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            {isModalOpen && <ApplyListing
                header={'Apply For the Job!'}
                userId={userId}
                closeModal={closeModal}
                closeButtonText={'Close'}
                applyButtonText={'Apply'}
                listing={selectedListing} />}
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>

                {isLoggedIn && <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        {userInfo?.userType !== 'recr' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/home">Listings</Link></li>}
                        {userInfo?.userType === 'recr' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/mylistings">Listings</Link></li>}
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">Job Detail</li>
                    </ul>
                </div>}
            </section>
            {selectedListing && <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="shadow dark:shadow-gray-700 rounded-md bg-white dark:bg-slate-900 sticky top-20">
                                <div className="p-6">
                                    <h5 className="text-lg font-semibold">Job Information</h5>
                                </div>
                                <div className="p-6 border-t border-slate-100 dark:border-t-gray-700">
                                    {selectedListing.listingType !== 'quick' && <ul className="list-none">
                                        <li className="flex items-center mt-3">
                                            <Monitor className="h-5 w-5"></Monitor>

                                            <div className="ms-4">
                                                <p className="font-medium">Job Title:</p>
                                                <span className="text-emerald-600 font-medium text-sm">{selectedListing?.title}</span>
                                            </div>
                                        </li>
                                        <li className="flex items-center">
                                            <UserCheck className="h-5 w-5"></UserCheck>

                                            <div className="ms-4">
                                                <p className="font-medium">Employee Type:</p>
                                                <span className="text-emerald-600 font-medium text-sm">{selectedListing?.jobType ? selectedListing?.jobType : "Full Time"}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <MapPin className="h-5 w-5"></MapPin>

                                            <div className="ms-4">
                                                <p className="font-medium">Location:</p>
                                                <span className="text-emerald-600 font-medium text-sm">{selectedListing?.city + ',' + selectedListing?.livingState}</span>
                                            </div>
                                        </li>



                                        <li className="flex items-center mt-3">
                                            <Briefcase className="h-5 w-5"></Briefcase>

                                            <div className="ms-4">
                                                <p className="font-medium">Experience:</p>
                                                <span className="text-emerald-600 font-medium text-sm">{selectedListing?.experience + "+"} years</span>
                                            </div>
                                        </li>

                                        {selectedListing?.qualifications && <li className="flex items-center mt-3">
                                            <Book className="h-5 w-5"></Book>

                                            <div className="ms-4">
                                                <p className="font-medium">Qualifications:</p>
                                                <span className="text-emerald-600 font-medium text-sm">{selectedListing?.qualifications?.join(',')}</span>
                                            </div>
                                        </li>}

                                        <li className="flex items-center mt-3">
                                            <DollarSign className="h-5 w-5"></DollarSign>

                                            <div className="ms-4">
                                                <p className="font-medium">Salary:</p>
                                                <span className="text-emerald-600 font-medium text-sm">{selectedListing?.salaryType === 'Hourly' ? '$' + selectedListing?.salaryRangeFrom + ' - $' + selectedListing?.salaryRangeTo + ' per Hour' : '$' + selectedListing?.salaryRangeFrom + ' - $' + selectedListing?.salaryRangeTo + ' per Month'}</span>
                                            </div>
                                        </li>

                                        <li className="flex items-center mt-3">
                                            <Clock className="h-5 w-5"></Clock>

                                            <div className="ms-4">
                                                <p className="font-medium">Date posted:</p>
                                                <span className="text-emerald-600 font-medium text-sm">{selectedListing?.postedDate.substring(0, selectedListing?.postedDate.indexOf('T'))}</span>
                                            </div>
                                        </li>
                                        {userId === selectedListing.userId && selectedListing?.status === 'deactive' && <li className="flex items-center mt-3">
                                            <Clock className="h-5 w-5"></Clock>

                                            <div className="ms-4">
                                                <p className="font-medium">Status:</p>
                                                <span className="text-emerald-600 font-medium text-sm">Deleted</span>
                                            </div>
                                        </li>}
                                        {userId === selectedListing.userId && (!selectedListing?.status || selectedListing?.status === 'active') && <li className="flex items-center mt-3">
                                            <Trash className="h-5 w-5"></Trash>

                                            <div className="ms-4">
                                                <button type="submit" id="submit" name="send" className="btn rounded-md bg-red-600 hover:bg-red-100 border-red-100 hover:border-red-100 text-white"
                                                    onClick={() => deleteSelectedListing(selectedListing.id)}>{'Delete'}</button>
                                            </div>
                                        </li>}
                                    </ul>}
                                    {selectedListing.listingType === 'quick' &&
                                        <ul className="list-none">
                                            <li className="flex items-center mt-3">
                                                <Monitor className="h-5 w-5"></Monitor>

                                                <div className="ms-4">
                                                    <p className="font-medium">Job Title:</p>
                                                    <span className="text-emerald-600 font-medium text-sm">{selectedListing?.title}</span>
                                                </div>
                                            </li>
                                            {userId === selectedListing.userId && (!selectedListing?.status || selectedListing?.status === 'active') && <li className="flex items-center mt-3">
                                                <Trash className="h-5 w-5"></Trash>

                                                <div className="ms-4">
                                                    <button type="submit" id="submit" name="send" className="btn rounded-md bg-red-600 hover:bg-red-100 border-red-100 hover:border-red-100 text-white"
                                                        onClick={() => deleteSelectedListing(selectedListing.id)}>{'Delete'}</button>
                                                </div>
                                            </li>}
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="lg:col-span-8 md:col-span-6">
                            <h5 className="text-lg font-semibold">Job Description:</h5>

                            {selectedListing?.description && <div><div className="jdetail" dangerouslySetInnerHTML={{ __html: selectedListing.description }}></div></div>}
                            {selectedListing.listingType !== 'quick' && <>
                                <h5 className="text-lg font-semibold mt-6">Required Experience, Skills and Qualifications: </h5>
                                <div className="jdetail" dangerouslySetInnerHTML={{ __html: selectedListing?.qualificationsAndSkills }}></div>

                                <h5 className="text-lg font-semibold mt-6">Responsibilities: </h5>
                                <div className="jdetail" dangerouslySetInnerHTML={{ __html: selectedListing?.responsibilities }}></div>
                            </>}
                            {userInfo?.userType !== 'recr' && selectedListing.status !== 'deactive' && <div className="mt-5">
                                <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-100 border-emerald-100 hover:border-emerald-100 text-white"
                                    onClick={() => openModal(selectedListing)}>Apply Now</button>
                            </div>}
                            {selectedListing?.appliedCandidates && <div className="mt-5">
                                <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-100 border-emerald-100 hover:border-emerald-100 text-white"
                                    onClick={() => showApplications(selectedListing.id)}>{'Applications  - ' + selectedListing?.appliedCandidates?.length}</button>
                            </div>}
                        </div>
                    </div>
                </div>
                {/* <JobDetailComp /> */}
                <ExploreJob />
            </section>}
            <Footer />
        </>
    )
}
export const style = {
    // ul:{
    //     'list-style': 'disc !important'
    // },
    // ol:{
    //     'list-style': 'number !important'
    // }
}