import { configureStore } from "@reduxjs/toolkit";
import rootReducer from '.';
import { sagaMiddleware } from "../sagas";
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage"
import { rootSaga } from "../sagas";

const persistConfig = {
    key: "root",
    storage: storage,
    whiteList: ["whitelistReducer"]
}
const persistedReducer = persistReducer(persistConfig, rootReducer());

export const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [sagaMiddleware],
});

export const persistor = persistStore(store);