import createSagaMiddleware from "redux-saga";
import {all} from "redux-saga/effects";
import sampleSaga from './sample.saga';
import userSaga from './user.saga';
import listingsSaga from './listing.saga';

export const sagaMiddleware = createSagaMiddleware();
export function* rootSaga(){
    yield all([
        sampleSaga(),
        userSaga(),
        listingsSaga()
    ]);
}