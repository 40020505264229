import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import logo_dark from '../../assets/images/logo.jpg';
import logo_light from '../../assets/images/logo.jpg';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginAttemptMessageSelector, isLoggedInSelector } from '../../reducers/selectors/user.selector';
import { attemptLogin, resetLoginAttemptMessage } from '../../actions/user.action';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import { setLoader } from '../../actions';
import Loader from '../../components/loader';
import Navbar from '../../components/Navbar';
import bg6 from '../../assets/images/hero/bg6.png';
import Footer from '../../components/Footer';

export default function Login() {


    const [uname, setUname] = useState('');
    const [pwd, setPwd] = useState('');
    const [loginAttemptStatus, setLoginAttemptStatus] = useState(true);

    const dispatch = useDispatch();
    const isLoggedIn = useSelector(isLoggedInSelector);
    const navigate = useNavigate();

    const loader = useSelector(activateLoaderSelector);
    const loginAttemptMessage = useSelector(getLoginAttemptMessageSelector);

    useEffect(() => {
        dispatch(resetLoginAttemptMessage());
    },[])
    const tryLogin = () => {
        console.log('1...', uname, pwd);
        dispatch(setLoader(true));
        dispatch(attemptLogin({ uname, pwd }));
    }

    useEffect(() => {
        console.log('1...')
        if(isLoggedIn)
        if (isLoggedIn) {
            navigate('/pricing');
        } else {
            console.log('2...')
            setLoginAttemptStatus(false);
        }
    }, [isLoggedIn])

    // if (loader) {
    //     return <Loader />
    // }

    return (
        <>
            <Navbar navClass='justify-end' isContainerFluid={true} />
            {loader && <Loader />}
            <section className="relative table md:pt-40 pt-36 w-full bg-gradient-to-b from-emerald-600/20 dark:from-emerald-600/40 via-emerald-600/10 dark:via-emerald-600/20 to-transparent">
                <div className="container z-1">


                    <div className="relative grid md:grid-cols-3 grid-cols-1 items-center gap-[30px] z-1">
                        <div className="counter-box text-center">
                            <div className="grid grid-cols-1 text-center relative">
                                <h4 className="lg:leading-normal leading-normal text-4xl lg:text-5xl mb-5 font-bold">Built for corp-to-corp, <br /> all recruiters at one place</h4>
                                <p className="text-slate-400 text-lg max-w-xl mx-auto">Find Jobs, Employment & Career Opportunities.</p>
                                <div className="mt-4">
                                    <span className="text-slate-400"><span className="text-slate-900 dark:text-white">Popular Searches :</span> Designer, Developer, Web, IOS, PHP Senior Engineer</span>
                                </div>
                            </div>
                        </div>

                        <div className="counter-box text-center">

                        </div>

                        <div className="counter-box text-center">
                        <div className="p-6">
                                {/* <Link to="#">
                                    <img src={logo_dark} className="mx-auto h-[24px] block dark:hidden" alt="" />
                                    <img src={logo_light} className="mx-auto h-[24px] dark:block hidden" alt="" />
                                </Link> */}
                                <h5 className="my-6 text-xl font-semibold">Login</h5>
                                <div className="grid grid-cols-1">
                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        {loginAttemptMessage !== '' && <><label className="text-red-600" htmlFor="LoginEmail">Invalid User Credentials</label><br /></>}
                                        <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                        <input id="LoginEmail" type="email" className="form-input mt-3 rounded-md" placeholder="name@example.com"
                                            onChange={(e) => { setUname(e.target.value) }} />
                                    </div>

                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginPassword">Password:</label>
                                        <input id="LoginPassword" type="password" className="form-input border border-black mt-3 rounded-md" placeholder="Password:"
                                            onChange={(e) => { setPwd(e.target.value) }} />
                                    </div>

                                    <div className="flex justify-between mb-4">
                                        <div className="inline-flex items-center mb-0">
                                            <input className="form-checkbox rounded border-black-200 dark:border-black-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50 me-2" type="checkbox" value="" id="RememberMe" />
                                            <label className="form-checkbox-label text-black-400" htmlFor="RememberMe">Remember me</label>
                                        </div>
                                        <p className="text-black-400 mb-0"><Link to="/passwordreset" className="text-black-400">Forgot password ?</Link></p>
                                    </div>

                                    <div className="mb-4">
                                        {/* <input type="submit" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value="Login / Sign in" /> */}
                                        <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md" onClick={() => tryLogin()}>Login</button>
                                    </div>

                                    <div className="text-center">
                                        <span className="text-black-400 me-2">Don't have an account ?</span> <Link to="/signup" className="text-black dark:text-white font-bold">Sign Up</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="grid grid-cols-1">
                        <img src={bg6} alt="" />
                    </div>
                </div>
            </section>


            {/* <section className="relative md:pb-24 pb-16 overflow-hidden">
                <PopularJobsfour />
                <BestCompanies />
                <Feature />
                <News />

              <ExploreJob/>
            </section > */}
            <Footer />
        </>
    )
}