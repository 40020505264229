import React, { useState } from 'react'
import contact from '../../assets/images/svg/contact.svg';
import Navbar from '../Navbar'
import Footer from '../Footer'
import { Link } from 'react-router-dom';
import {PiMapPin, BsTelephone, LuMail} from "../../assets/icons/vander"
import { useDispatch, useSelector } from 'react-redux';
import { postContactus, sendAddCandidate, setLoader } from '../../actions';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import Loader from '../loader';
import { toast,ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { getUserInfoSelector } from '../../reducers/selectors/user.selector';

export default function ReqAddCandidateForm() {

    
    const[email,setEmail] = useState('');
    const[firstName,setFirstName] = useState('');
    const[lastName,setLastName] = useState('');

    const loader = useSelector(activateLoaderSelector);
    const userInfo = useSelector(getUserInfoSelector);

    const dispatch = useDispatch();
    // JKq<H29g3=M67aA>
    const sendRequest = () => {
        const candidate = {
            orgId:userInfo.orgId,
            userId:userInfo.userId,
            email,
            firstName,
            lastName,
            status:'new'
        }
        const payload = {
            userId:userInfo.id,
            candidate
        }
        dispatch(setLoader(true));
        dispatch(sendAddCandidate(payload));
        toast.success("Your request to add candidate is sent. Thanks.",{position:toast.POSITION.TOP_RIGHT})
        resetState();
    }
    
    const resetState = () => {
        setEmail('');
        setFirstName('');
        setLastName('');
    }

    if(loader){
        <Loader/>
    }
    return (
        <>
            <Navbar navClass='justify-end' isContainerFluid={true}/>
            {/* <div className="container-fluid relative mt-20">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe title="contact-frame" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" style={{ border: 0 }} className="w-full h-[500px]" allowFullScreen></iframe>
                    </div>
                </div>
            </div> */}
            <section className="relative lg:py-24 py-16">
                <ToastContainer autoClose={8000}/>
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-semibold">Add Candidate By Request !</h3>

                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="font-semibold">First Name:</label>
                                                <input name="firstname" id="name" type="text" className="form-input mt-2" placeholder="First Name :" 
                                                onChange={(e) => {setFirstName(e.target.value)}}/>
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="font-semibold">Last Name:</label>
                                                <input name="lastname" id="lastname" type="text" className="form-input mt-2" placeholder="Last Name :" 
                                                onChange={(e) => {setLastName(e.target.value)}}/>
                                            </div>
                                        </div>
                                        

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="subject" className="font-semibold">Candidate Email:</label>
                                                <input name="email" type="email" value={email} id="subject" className="form-input mt-2" placeholder="john.wick@gmail.com" 
                                                onChange={(e) => setEmail(e.target.value)}/>
                                            </div>
                                        </div>
                                        <button disabled={!email || !firstName || !lastName}type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                                            onClick={() => sendRequest()}>Send Candidate Request</button>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
            </section>

            <Footer />
        </>
    )
}
