import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import Navbar from '../../components/Navbar'
import JobListCompTwo from '../../components/job-list-comp-two'
import Feature from '../../components/Feature'
import Footer from '../../components/Footer'
import { LuSearch } from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';

import { BiBriefcaseAlt2, PiMapPin } from "../../assets/icons/vander"
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux';
import { getListings, searchListings, setLoader } from '../../actions';
import { getListingsSelector } from '../../reducers/selectors/listings.selector';
import JobListCompThree from '../job-list-comp-three';
import JobListCompFour from '../job-list-comp-four';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import Loader from '../loader';
import JobList from '../jobslist';
import Toast from '../toast';
import { toast } from 'react-toastify';
import { getUserInfoSelector, isLoggedInSelector } from '../../reducers/selectors/user.selector';

const optionsOne = [
    { value: 'Alabama', label: 'Alabama' },
    { value: 'Alaska', label: ' Alaska' },
    { value: 'Arizona', label: 'Arizona' },
    { value: 'Arkansas', label: 'Arkansas' },
    { value: 'California', label: 'California' },
    { value: 'Colorado', label: 'Colorado' },
    { value: 'Connecticut', label: 'Connecticut' },
    { value: 'Delaware', label: 'Delaware' },
    { value: 'Florida', label: 'Florida' },
    { value: 'Georgia', label: 'Georgia' },
    { value: 'Hawaii', label: 'Hawaii' },
    { value: 'Idaho', label: 'Idaho' },
    { value: 'Illinois', label: 'Illinois' },
    { value: 'Indiana', label: 'Indiana' },
    { value: 'Iowa', label: 'Iowa' },
    { value: 'Kansas', label: 'Kansas' },
    { value: 'Kentucky', label: 'Kentucky' },
    { value: 'Louisiana', label: 'Louisiana' },
    { value: 'Maine', label: 'Maine' },
    { value: 'Maryland', label: 'Maryland' },
    { value: 'Massachusetts', label: 'Massachusetts' },
    { value: 'Michigan', label: 'Michigan' },
    { value: 'Minnesota', label: 'Minnesota' },
    { value: 'Mississippi', label: 'Mississippi' },
    { value: 'Missouri', label: 'Missouri' },
    { value: 'Montana', label: 'Montana' },
    { value: 'Nebraska', label: 'Nebraska' },
    { value: 'Nevada', label: 'Nevada' },
    { value: 'New Hampshire', label: 'New Hampshire' },
    { value: 'New Jersey', label: 'New Jersey' },
    { value: 'New Mexico', label: 'New Mexico' },
    { value: 'New York', label: 'New York' },
    { value: 'North Carolina', label: 'North Carolina' },
    { value: 'North Dakota', label: 'North Dakota' },
    { value: 'Ohio', label: 'Ohio' },
    { value: 'Oklahoma', label: 'Oklahoma' },
    { value: 'Oregon', label: 'Oregon' },
    { value: 'Pennsylvania', label: 'Pennsylvania' },
    { value: 'Rhode Island', label: 'Rhode Island' },
    { value: 'South Carolina', label: 'South Carolina' },
    { value: 'South Dakota', label: 'South Dakota' },
    { value: 'Tennessee', label: 'Tennessee' },
    { value: 'Texas', label: 'Texas' },
    { value: 'Utah', label: 'Utah' },
    { value: 'Vermont', label: 'Vermont' },
    { value: 'Virginia', label: 'Virginia' },
    { value: 'Washington', label: 'Washington' },
    { value: 'West Virginia', label: 'West Virginia' },
    { value: 'Wisconsin', label: 'Wisconsin' },
    { value: 'Wyoming', label: 'Wyoming' },
]

const optionsTwo = [
    { value: 'Contract', label: 'Contract' },
    { value: 'Part Time', label: 'Part Time' },
    { value: 'Remote Work', label: 'Remote Work' },
    { value: 'Hourly', label: 'Hourly' },
    { value: 'Monthly', label: 'Monthly' },
]

const usaStates = 'Alabama,Alaska,Arizona,Arkansas,California,Colorado,Connecticut,Delaware,Florida,Georgia,Hawaii,Idaho,Illinois,Indiana,Iowa,Kansas,Kentucky,Louisiana,Maine,Maryland,Massachusetts,Michigan,Minnesota,Mississippi,Missouri,Montana,Nebraska,Nevada,New Hampshire,New Jersey,New Mexico,New York,North Carolina,North Dakota,Ohio,Oklahoma,Oregon,Pennsylvania,Rhode Island,South Carolina,South Dakota,Tennessee,Texas,Utah,Vermont,Virginia,Washington,West Virginia,Wisconsin,Wyoming';

const jobCategories = 'Software Developer,Web Developer,Mobile App Developer,Frontend Developer,Backend Developer,Full Stack Developer,DevOps Engineer,Quality Assurance Engineer,Systems Engineer,Database Administrator,Cloud Engineer,Data Scientist,Machine Learning Engineer,UI/UX Designer,Product Manager,Scrum Master,Project Manager,Technical Writer,Security Engineer,Network Engineer,Support Engineer,Technical Support Specialist,Business Analyst,Solution Architect,Enterprise Architect,IT Consultant,Embedded Systems Engineer,Game Developer,IT Trainer,Data Analyst,IT Manager,Automation Engineer,Release Engineer,IT Operations Manager,Computer Programmer,Software Engineer in Test,AI Engineer,Robotics Engineer,IT Auditor,IT Compliance Officer,System Administrator,Network Administrator,Technical Recruiter,Agile Coach,IT Sales Representative,UX Researcher,Chatbot Developer,Blockchain Developer,IT Analyst,ITIL Specialist,Other';


export default function Home() {
    const [selectedOption, setSelectedOption] = useState('1');

    const [orgName, setOrgName] = useState('');
    const [category, setCategory] = useState('');
    const [location, setLocation] = useState('');
    const [jobType, setJobType] = useState([]);
    const [salary, setSalary] = useState('');
    const [listings, setListings] = useState([]);
    const [usaStatesList, setUsaStatesList] = useState([]);

    const [fullTimeChecked, setFullTimeChecked] = useState(false);
    const [contractChecked, setContractChecked] = useState(false);
    const [remoteChecked, setRemoteChecked] = useState(false);
    const [partTimeChecked, setPartTimeChecked] = useState(false);
    const [hourlyChecked, setHourlyChecked] = useState(false);
    const [monthlyChecked, setMonthlyChecked] = useState(false);

    const [searchKey, setSearchKey] = useState('');
    const [searchLocation, setSearchLocation] = useState('');
    const [searchListingType, setSearchListingType] = useState('');
    const [contractCount,setContractCount] = useState();
    const [parttimeCount,setParttimeCount] = useState();
    const [remoteCount,setRemoteCount] = useState();
    const [hourlyBasisCount,setHourlyBasisCount] = useState();
    const [monthlyCount,setMonthlyCount] = useState();

    const dispatch = useDispatch();
    const listingsOriginal = useSelector(getListingsSelector);
    const loader = useSelector(activateLoaderSelector);

    const isLoggedIn = useSelector(isLoggedInSelector);
    const userInfo = useSelector(getUserInfoSelector);

    const navigate = useNavigate();

    useEffect(() => {
        dispatch(setLoader(true));
        dispatch(getListings());
        setListings(listingsOriginal);
    }, []);

    useEffect(() => {
        setListings(listingsOriginal);
        if(listingsOriginal && listingsOriginal.length > 0){
            const contract = listingsOriginal.filter(l => l.jobType.toLowerCase() === 'contract');
            setContractCount(contract.length);
            const remote = listingsOriginal.filter(l => l.jobType.toLowerCase() === 'remote');
            setRemoteCount(remote.length);
            const parttime = listingsOriginal.filter(l => l.jobType.toLowerCase() === 'parttime');
            setParttimeCount(parttime.length);
            const hourly = listingsOriginal.filter(l => l.salaryType.toLowerCase() === 'hourly');
            setHourlyBasisCount(hourly.length);
            const monthly = listingsOriginal.filter(l => l.salaryType.toLowerCase() === 'monthly');
            setMonthlyCount(monthly.length);
        }
    },[listingsOriginal])

    const showToast = (type, message) => {
        toast[type](message, {
            position: 'top-right',
            autoClose: 3000, // 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    };

    const callToast = () => {
        console.log('1......')
        return (<Toast message={'hrllo.....'} />);
    }


    useEffect(() => {

        if (!orgName && !category && !location && !fullTimeChecked && !contractChecked && !partTimeChecked && !monthlyChecked && !hourlyChecked) {
            setListings(listingsOriginal);
        } else {

            let filteredListings = [];
            let dupListings = [];
            if (orgName) {
                filteredListings = listingsOriginal.filter(l => l.orgName.toLowerCase().includes(orgName.toLowerCase()));
                dupListings = [...dupListings, ...filteredListings];
            }
            if (category) {
                filteredListings = listingsOriginal.filter(l => l.categories.toLowerCase() === category.toLowerCase());
                dupListings = [...dupListings, ...filteredListings];
            }
            if (location) {
                filteredListings = listingsOriginal.filter(l => l.livingState.toLowerCase() === location.toLowerCase());
                dupListings = [...dupListings, ...filteredListings];
            }
            if (fullTimeChecked) {
                filteredListings = listingsOriginal.filter(li => li.jobType === 'Full Time');
                dupListings = [...dupListings, ...filteredListings];

            }
            if (partTimeChecked) {
                filteredListings = listingsOriginal.filter(li => li.jobType === 'Part Time');
                dupListings = [...dupListings, ...filteredListings];
            }
            if (contractChecked) {
                filteredListings = listingsOriginal.filter(li => li.jobType === 'Contract');
                dupListings = [...dupListings, ...filteredListings];

            }
            if (hourlyChecked) {
                filteredListings = listingsOriginal.filter(li => li.salaryType === 'Hourly');
                dupListings = [...dupListings, ...filteredListings];
            }
            if (monthlyChecked) {
                filteredListings = listingsOriginal.filter(li => li.salaryType === 'Monthly');
                dupListings = [...dupListings, ...filteredListings];
            }
            const uniqueIds = new Set();
            let finalListings = [];
            dupListings.filter(obj => {
                if (!uniqueIds.has(obj.id)) {
                    uniqueIds.add(obj.id);
                    finalListings = [...finalListings, obj]
                }
            });
            setListings(finalListings);

        }

    }, [orgName, category, location, salary, fullTimeChecked, contractChecked, partTimeChecked, monthlyChecked, hourlyChecked])

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleJobType = (value) => {
        console.log('e....', value)
        if (value === 'Full Time') {
            if (!fullTimeChecked) {
                setFullTimeChecked(!fullTimeChecked);
                setJobType([...jobType, value]);
            } else {
                setFullTimeChecked(!fullTimeChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Contract') {
            if (!contractChecked) {
                setContractChecked(!contractChecked);
                setJobType([...jobType, value]);
            } else {
                setContractChecked(!contractChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Remote') {
            if (!remoteChecked) {
                setRemoteChecked(!remoteChecked);
                setJobType([...jobType, value]);
            } else {
                setRemoteChecked(!remoteChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Part Time') {
            if (!partTimeChecked) {
                setPartTimeChecked(!partTimeChecked);
                setJobType([...jobType, value]);
            } else {
                setPartTimeChecked(!partTimeChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Hourly') {
            if (!hourlyChecked) {
                setHourlyChecked(!hourlyChecked);
                setJobType([...jobType, value]);
            } else {
                setHourlyChecked(!hourlyChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Hourly') {
            if (!hourlyChecked) {
                setHourlyChecked(!hourlyChecked);
                setJobType([...jobType, value]);
            } else {
                setHourlyChecked(!hourlyChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        if (value === 'Monthly') {
            if (!monthlyChecked) {
                setMonthlyChecked(!monthlyChecked);
                setJobType([...jobType, value]);
            } else {
                setMonthlyChecked(!monthlyChecked);
                const modifiedJobType = jobType.filter(str => str !== value);
                setJobType(modifiedJobType);
            }
        }
        console.log('j..', jobType);
    }

    if (loader) {
        return <Loader />
    }

    const handleListingType = (selectedOption) => {
        setSearchListingType(selectedOption);
    }

    const handleLocation = (selectedOption) => {
        setSearchLocation(selectedOption);
    }
    const searchListingsLocal = () => {
        console.log('1....##...', searchKey, searchLocation?.value, searchListingType?.value)
        if(searchKey === '' && !searchLocation && !searchListingType ){
            console.log('2...##...')
            dispatch(setLoader(true));
            dispatch(getListings());
        }else if (searchKey || searchLocation || searchListingType) {
            dispatch(setLoader(true));
            dispatch(searchListings({ searchKey, 'location': searchLocation?.value || '', 'listingType': searchListingType?.value || '' }))
        }
    }
    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
                <div className="container z-1" style={{ paddingBottom: 20 }}>
                    <div className="d-flex" id="reserve-form">
                        <div className="md:w-5/6 mx-auto">
                            <div className="lg:col-span-10">
                                <div className="bg-white dark:bg-slate-900 border-0 shadow rounded-md p-3">
                                    <div className="registration-form text-dark text-start">
                                        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                            <div className="filter-search-form relative filter-border">
                                                <BiBriefcaseAlt2 className="icons" />
                                                <input name="name" type="text" value={searchKey} id="job-keyword" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" placeholder="Search your Keywords"
                                                    onChange={(e) => setSearchKey(e.target.value)} />
                                            </div>

                                            <div className="filter-search-form relative filter-border">
                                                <PiMapPin className="icons" />
                                                <Select className="filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={optionsOne}
                                                    onChange={handleLocation} 
                                                    value={searchLocation}/>
                                            </div>

                                            <div className="filter-search-form relative filter-border">
                                                <BiBriefcaseAlt2 className="icons" />
                                                <Select className="filter-input-box bg-gray-50 dark:bg-slate-800 border-0" options={optionsTwo}
                                                    onChange={handleListingType}
                                                    value={searchListingType} />
                                            </div>

                                            <button id="search" name="search" style={{ height: '60px' }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100" value="Search"
                                                onClick={() => searchListingsLocal()} >Search</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-4 md:col-span-6">
                            <div className="shadow dark:shadow-gray-700 p-6 rounded-md bg-white dark:bg-slate-900 sticky top-20">

                                <div className="grid grid-cols-1 gap-3">
                                    <div>
                                        <label htmlFor="searchname" className="font-semibold">Search Company</label>
                                        <div className="relative mt-2">
                                            <LuSearch className="text-lg absolute top-[5px] start-3" />
                                            <input name="search" id="searchname" type="text"
                                                className="form-input border border-slate-100 dark:border-slate-800 ps-10"
                                                placeholder="Search"
                                                onChange={(e) => { setOrgName(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div>
                                        <label className="font-semibold">Categories</label>
                                        <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => setCategory(e.target.value)}>
                                            <option value=""></option>
                                            {jobCategories && jobCategories.split(',').map((c, index) => (<option key={index} value={c}>{c}</option>))}
                                        </select>

                                    </div>


                                    <div>
                                        <label className="font-semibold">Location</label>
                                        <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => { setLocation(e.target.value) }}>
                                            <option value=""></option>
                                            {usaStates.split(',').map((st, index) => (<option key={index} value={st}>{st}</option>))}
                                        </select>
                                    </div>

                                    <div>
                                        <label className="font-semibold">Job Types</label>
                                        <div className="block mt-2">
                                            {/* <div className="flex justify-between">
                                                    <label className="inline-flex items-center">
                                                        <input type="checkbox"
                                                            className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                            checked={fullTimeChecked}
                                                            onChange={(e) => { handleJobType('Full Time') }} />
                                                        <span className="ms-2 text-slate-400">Full Time</span>
                                                    </label>

                                                    <span
                                                        className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">3</span>
                                                </div> */}
                                            <div className="flex justify-between">
                                                <label className="inline-flex items-center">
                                                    <input type="checkbox"
                                                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        onChange={(e) => { handleJobType('Contract') }}
                                                    />
                                                    <span className="ms-2 text-slate-400">Contract</span>
                                                </label>

                                                <span
                                                    className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">{contractCount}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <label className="inline-flex items-center">
                                                    <input type="checkbox"
                                                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        onChange={(e) => { handleJobType('Part Time') }}
                                                    />
                                                    <span className="ms-2 text-slate-400">Part Time</span>
                                                </label>

                                                <span
                                                    className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">{parttimeCount}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <label className="inline-flex items-center">
                                                    <input type="checkbox"
                                                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        onChange={(e) => { handleJobType('Remote') }}
                                                    />
                                                    <span className="ms-2 text-slate-400">Remote</span>
                                                </label>

                                                <span
                                                    className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">{remoteCount}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <label className="inline-flex items-center">
                                                    <input type="checkbox"
                                                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        onChange={(e) => { handleJobType('Hourly') }}
                                                    />
                                                    <span className="ms-2 text-slate-400">Hourly Basis</span>
                                                </label>

                                                <span
                                                    className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">{hourlyBasisCount}</span>
                                            </div>
                                            <div className="flex justify-between">
                                                <label className="inline-flex items-center">
                                                    <input type="checkbox"
                                                        className="form-checkbox rounded border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        onChange={(e) => { handleJobType('Monthly') }}
                                                    />
                                                    <span className="ms-2 text-slate-400">Monthly</span>
                                                </label>

                                                <span
                                                    className="bg-emerald-600/10 text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full h-5">{monthlyCount}</span>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div>
                                        <label className="font-semibold">Salary</label>
                                        <div className="block mt-2">
                                            <div>
                                                <label className="inline-flex items-center">
                                                    <input type="radio"
                                                        className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        name="radio-colors" value="1"
                                                        defaultChecked={selectedOption === '1'}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <span className="ms-2 text-slate-400">10k - 15k</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="inline-flex items-center">
                                                    <input type="radio"
                                                        className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        name="radio-colors" value="2" defaultChecked={selectedOption === '2'}
                                                        onChange={handleOptionChange} />
                                                    <span className="ms-2 text-slate-400">15k - 25k</span>
                                                </label>
                                            </div>
                                            <div>
                                                <label className="inline-flex items-center">
                                                    <input type="radio"
                                                        className="form-radio border-gray-200 dark:border-gray-800 text-emerald-600 focus:border-emerald-300 focus:ring focus:ring-offset-0 focus:ring-emerald-200 focus:ring-opacity-50"
                                                        name="radio-colors" value="3" defaultChecked={selectedOption === '3'}
                                                        onChange={handleOptionChange} />
                                                    <span className="ms-2 text-slate-400">more than 25K</span>
                                                </label>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div>
                                        <input type="submit"
                                            className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full"
                                            value="Apply Filter"
                                            onClick={() => showToast('success', 'hello...')} />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <JobListCompTwo listings={listings}/> */}
                        <JobList
                            listings={listings}
                            srcComponent={'home'} />
                    </div>
                </div>

                <Feature />
                <div>
                    <ExploreJob />
                </div>
            </section>
            <Footer />
        </>
    )
}
