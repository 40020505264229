import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import onlylogo from '../../assets/images/onlylogo.jpg';
import { changePassword, resetPasswordChangeMessage, setLoader } from '../../actions';
import { useDispatch, useSelector } from 'react-redux';
import { getPasswordChangeMessageSelector } from '../../reducers/selectors/user.selector';

export default function PasswordReset() {

    const [email, setEmail] = useState('');
    const [resetMessage, setResetMessage] = useState('');

    const dispatch = useDispatch();
    const passwordChangeMessage = useSelector(getPasswordChangeMessageSelector);

    useEffect(() => {
        dispatch(resetPasswordChangeMessage());
    }, [])

    useEffect(() => {
        if (passwordChangeMessage && passwordChangeMessage.message) {
            if (passwordChangeMessage.message.code === '200') {
                setEmail('');
                setResetMessage('Password Reset Url Sent to Your email.');
            } else {
                setResetMessage('Password Reset Failed. Please enter valid email.');
            }

        }
    }, [passwordChangeMessage])

    const changeUserPassword = () => {
        console.log('cp....', email)
        dispatch(setLoader(true))
        dispatch(changePassword(email));
    }

    return (
        <section className="h-screen flex items-center justify-center relative overflow-hidden bg-[url('../../assets/images/hero/bg3.jpg')] bg-no-repeat bg-left-bottom bg-cover">
            <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black"></div>
            <div className="container">
                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
                    <div className="relative overflow-hidden bg-white dark:bg-slate-900 shadow-md dark:shadow-gray-800 rounded-md">
                        <div className="p-6">
                            <Link to="/index">
                                <img src={onlylogo} className="mx-auto h-[24px] block dark:hidden" alt="" />
                                <img src={onlylogo} className="mx-auto h-[24px] dark:block hidden" alt="" />
                            </Link>
                            <h5 className="my-6 text-xl font-semibold">Reset Your Password</h5>
                            {resetMessage && <h5 className="my-6 text-xl font-semibold text-emerald">{resetMessage}</h5>}
                            <div className="grid grid-cols-1">
                                <p className="text-slate-400 mb-6">Please enter your email address. You will receive a link to create a new password via email.</p>

                                <div className="grid grid-cols-1">
                                    <div className="mb-4 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="LoginEmail">Email Address:</label>
                                        <input id="LoginEmail" type="email" className="form-input mt-3 rounded-md" placeholder="name@example.com"
                                            onChange={(e) => { setEmail(e.target.value) }} />
                                    </div>

                                    <div className="mb-4">
                                        <button className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md w-full" value="Send"
                                            onClick={() => changeUserPassword()}>Reset Password</button>
                                    </div>

                                    <div className="text-center">
                                        <span className="text-slate-400 me-2">Remember your password ? </span><Link to="/login" className="text-black dark:text-white font-bold">Sign in</Link>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="px-6 py-2 bg-slate-50 dark:bg-slate-800 text-center">
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
