import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { getAllCandidatesSelector } from '../../reducers/selectors/user.selector';
import { applyListings, getAllCandidates, resetApplyListingResponse, setLoader } from '../../actions';
import { getAppliedListingResponseSelector } from '../../reducers/selectors/listings.selector';


export default function ApplyListing(props) {

    const dispatch = useDispatch();
    const [selectedCandidate, setSelectedCandidate] = useState('');
    const [terms, setTerms] = useState(false)
    const [appliedStatus,setAppliedStatus] = useState(false);

    const candidateslist = useSelector(getAllCandidatesSelector);
    const appliedListingResponse = useSelector(getAppliedListingResponseSelector);

    useEffect(() => {
        dispatch(getAllCandidates(props.userId));
        dispatch(resetApplyListingResponse());
    }, [])

    useEffect(() => {
        console.log('s..c..',selectedCandidate,props.listing)
        if(props.listing && props.listing.appliedCandidates && props.listing.appliedCandidates.includes(selectedCandidate)){
            setAppliedStatus(true);
        }else{
            setAppliedStatus(false);
        }
    },[selectedCandidate]);

    useEffect(() => {
        if(appliedListingResponse && appliedListingResponse.message){
            console.log('4...',appliedListingResponse,appliedListingResponse.message);
            if(appliedListingResponse.message.code === '200'){
                setAppliedStatus('Applied Succesfuly.');
                // props.closeModal();
            }else if(appliedListingResponse.message.code === '500'){
                setAppliedStatus('Something went wrong. Please try again.');
            }else if(appliedListingResponse.message.code === '404'){
                console.log('404...');
                setAppliedStatus('Candidate already applied for this Position.');
            }
        }
    },[appliedListingResponse]);

    const applyForPosition = () => {
        const payload = {
            listingId:props.listing.id,
            candidateId:selectedCandidate,
            terms,
            userId:props.userId,
        }
        console.log('1...',payload)
        dispatch(setLoader(true));
        dispatch(applyListings(payload));
        // props.closeModal();
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center" style={{ zIndex: 1 }}>
            <div className="absolute bg-gray-800 opacity-75 top-0 left-0 w-full h-full" onClick={props.closeModal}></div>

            <div className="bg-white p-8 rounded shadow-lg z-10" style={{ height: '70%', width: '50%' }}>

                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                    <h3 className="mb-6 text-2xl leading-normal font-semibold">{props.header}</h3>


                    {!candidateslist || candidateslist.length === 0 && <div className="grid lg:grid-cols-6 lg:gap-6">
                        
                        <div className="lg:col-span-6 mb-5">
                            <label htmlFor="name" className="font-semibold">No Candidates Found</label><br/>
                            <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                                onClick={props.apply}>{'Add Candidate'}</button>
                        </div>
                        <button type="submit" id="submit" name="send" className="btn bg-red-600 hover:bg-emerald-700 text-white rounded-md"
                        onClick={props.closeModal}>{props.closeButtonText}</button>
                    </div>}

                    {candidateslist && candidateslist.length > 0 && <><div className="grid lg:grid-cols-6 lg:gap-6">
                        <div className="lg:col-span-6 mb-5">
                            <label htmlFor="name" className="font-semibold">Select Candidate</label>
                            <select className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                            onChange={(e) => {setSelectedCandidate(e.target.value)}}>
                                <option value=""></option>
                                {candidateslist && candidateslist.map(c => (<option value={c.id}>{c.firstName + ' ' + c.lastName + ' - ' + c.skills}</option>))}
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-1">
                        <div className="mb-5">
                            
                            <label htmlFor="comments">
                                Applying this position means to show interest on this position for a candidate.
                                The Corporaiton which is posted this position may or may not consider Candidate Profile.
                                </label>
                        </div>
                        <div className="mb-5">
                            <input className="form-checkbox text-emerald-600 rounded w-4 h-4 me-2 border border-inherit" type="checkbox" value="" id="AcceptT&C"
                                onChange={() => setTerms(!terms)} />
                            <label className="form-check-label text-slate-400" htmlFor="AcceptT&C">I Accept <Link to="#" className="text-emerald-600">Terms And Condition</Link></label>
                        </div>
                    </div>
                    {appliedStatus && <div className="grid grid-cols-1">
                        <div className="mb-5">
                            <h4 className="text-emerald-600 font-semibold ">
                                {'Candidate is already applied to this position.'}
                                </h4>
                        </div>
                    </div>}
                    <button type="submit" id="submit" name="send" className="btn bg-red-600 hover:bg-emerald-700 text-white rounded-md"
                        onClick={props.closeModal}>{props.closeButtonText}</button>
                    {terms && selectedCandidate && !appliedStatus && <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 text-white rounded-md"
                        onClick={() => {applyForPosition()}}>{props.applyButtonText}</button>}
                    {(!terms || !selectedCandidate || appliedStatus) && <button disabled type="submit" id="submit" name="send" className="btn bg-slate-100 hover:bg-slate-100 text-black rounded-md"
                        >{props.applyButtonText}</button>}    
                    </>}
                </div>
            </div>
        </div>
    );
}