import logo_light from '../assets/images/logo-light.png';
import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from "react-router-dom";

import logo_icon_40 from '../assets/images/logo-icon-40.png';
import logo_icon_40_white from '../assets/images/logo-icon-40-white.png';
import logo_dark from '../assets/images/logo-dark.png';
import logo_white from '../assets/images/logo-white.png';
import logo from '../assets/images/logo.jpg';
import onlylogo from '../assets/images/onlylogo.jpg';

import image from '../assets/images/team/05.jpg';
import { LuSearch } from "../assets/icons/vander";
import { User, Settings, Lock, LogOut } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { attemptLogout, resetUserState } from '../actions/user.action';
import { getSubscriptionResponseSelector, getUserInfoSelector, isLoggedInSelector } from '../reducers/selectors/user.selector';
import { resetListingsState } from '../actions';

export default function Navbar(props) {
    const [isDropdown, openDropdown] = useState(true);
    const { navClass, topnavClass, isContainerFluid } = props;
    const [isOpen, setMenu] = useState(true);
    const [goodSubscription, setGoodSubscription] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoggedIn = useSelector(isLoggedInSelector);
    const [mylistingId, setMylistingId] = useState();
    const userInfo = useSelector(getUserInfoSelector);
    const subscriptionResponse = useSelector(getSubscriptionResponseSelector);

    const currentPageUrl = window.location.pathname;
    const location = useLocation();


    useEffect(() => {
        console.log('1....')
        if (!isLoggedIn && location) {
            console.log('2....', location, location.pathname)
            if (location.pathname !== 'pricing') {
                // navigate('/welcome');
            }
        }
    }, []);

    useEffect(() => {
        console.log('3...', isLoggedIn)
        if (!isLoggedIn && location) {
            console.log('3...')
            if (location.pathname !== 'pricing') {
                // navigate('/welcome');
            }
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (userInfo.role === 'admin') {
            setMylistingId(userInfo.orgId);
        } else {
            setMylistingId(userInfo.userId);
        }
        if(userInfo?.stripeStatus === 'active' || userInfo?.stripeStatus === 'trialing' || subscriptionResponse?.id){
            setGoodSubscription(true);
        }
    }, [userInfo])

    // window.addEventListener("scroll", windowScroll);
    useEffect(() => {
        // const navbar = document.getElementById("topnav");
        // navbar?.classList.add("nav-sticky");
        windowScroll();
        activateMenu();
    }, []);


    function windowScroll() {
        const navbar = document.getElementById("topnav");
        navbar?.classList.add("nav-sticky");
        // if (
        //     document.body.scrollTop >= 50 ||
        //     document.documentElement.scrollTop >= 50
        // ) {
        //     if (navbar !== null) {
        //         navbar?.classList.add("nav-sticky");
        //     }
        // } else {
        //     if (navbar !== null) {
        //         navbar?.classList.add("nav-sticky");

        //         // navbar?.classList.remove("nav-sticky");
        //     }
        // }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }


    const logout = () => {
        dispatch(resetUserState());
        dispatch(resetListingsState());
        dispatch(attemptLogout());
    }
    const toggleMenu = () => {
        setMenu(!isOpen)
        if (document.getElementById("navigation")) {
            const anchorArray = Array.from(document.getElementById("navigation").getElementsByTagName("a"));
            anchorArray.forEach(element => {
                element.addEventListener('click', (elem) => {
                    const target = elem.target.getAttribute("href")
                    if (target !== "") {
                        if (elem.target.nextElementSibling) {
                            var submenu = elem.target.nextElementSibling.nextElementSibling;
                            submenu.classList.toggle('open');
                        }
                    }
                })
            });
        }
    }


    const getClosest = (elem, selector) => {

        // Element.matches() polyfill
        if (!Element.prototype.matches) {
            Element.prototype.matches =
                Element.prototype.matchesSelector ||
                Element.prototype.mozMatchesSelector ||
                Element.prototype.msMatchesSelector ||
                Element.prototype.oMatchesSelector ||
                Element.prototype.webkitMatchesSelector ||
                function (s) {
                    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
                        i = matches.length;
                    while (--i >= 0 && matches.item(i) !== this) { }
                    return i > -1;
                };
        }

        // Get the closest matching element
        for (; elem && elem !== document; elem = elem.parentNode) {
            if (elem.matches(selector)) return elem;
        }
        return null;

    };

    const activateMenu = () => {
        var menuItems = document.getElementsByClassName("sub-menu-item");
        if (menuItems) {

            var matchingMenuItem = null;
            for (var idx = 0; idx < menuItems.length; idx++) {
                if (menuItems[idx].href === window.location.href) {
                    matchingMenuItem = menuItems[idx];
                }
            }

            if (matchingMenuItem) {
                matchingMenuItem.classList.add('active');


                var immediateParent = getClosest(matchingMenuItem, 'li');

                if (immediateParent) {
                    immediateParent.classList.add('active');
                }

                var parent = getClosest(immediateParent, '.child-menu-item');
                if (parent) {
                    parent.classList.add('active');
                }

                var parent = getClosest(parent || immediateParent, '.parent-menu-item');

                if (parent) {
                    parent.classList.add('active');

                    var parentMenuitem = parent.querySelector('.menu-item');
                    if (parentMenuitem) {
                        parentMenuitem.classList.add('active');
                    }

                    var parentOfParent = getClosest(parent, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                } else {
                    var parentOfParent = getClosest(matchingMenuItem, '.parent-parent-menu-item');
                    if (parentOfParent) {
                        parentOfParent.classList.add('active');
                    }
                }
            }
        }
    }
    return (
        <nav id="topnav" className={`is-sticky ${topnavClass}`}>
            <div className={`${isContainerFluid === true ? 'container-fluid md:px-8 px-3' : 'container'}`}>
                <Link className="logo" to="/">

                    <div className="block sm:hidden">
                        <img src={onlylogo} className="h-10 inline-block dark:hidden" alt="" />
                        <img src={onlylogo} className="h-10 hidden dark:inline-block" alt="" />
                    </div>

                    {navClass && navClass.includes('nav-light') ?

                        <div className="sm:block hidden">
                            <span className="inline-block dark:hidden">
                                <img src={logo} className="h-[24px] l-dark" alt="" />
                                <img src={logo} className="h-[24px] l-light" alt="" />
                            </span>
                            <img src={logo} className="h-[24px] hidden dark:inline-block" alt="" />
                        </div> :
                        <div className="sm:block hidden">
                            <img src={logo} className="h-[24px] inline-block dark:hidden" alt="" />
                            <img src={logo} className="h-[24px] hidden dark:inline-block" alt="" />
                        </div>
                    }
                </Link>

                <div className="menu-extras">
                    <div className="menu-item">
                        <Link to="#" className="navbar-toggle" id="isToggle" onClick={toggleMenu}>
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </Link>
                    </div>
                </div>
                {/* <!-- End Mobile Toggle --> */}

                {/* <!--Login button Start--> */}
                <ul className="buy-button list-none mb-0">
                    {/* <li className="inline-block mb-0">
                        <div className="relative">
                            <LuSearch className="text-lg absolute top-[10px] end-3"/>
                            <input type="text" className="form-input h-9 pe-10 rounded-3xl sm:w-44 w-36 border-gray-100 dark:border-slate-800 bg-white dark:bg-slate-900" name="s" id="searchItem" placeholder="Search..." />
                        </div>
                    </li> */}

                    {isLoggedIn && <li className="dropdown inline-block relative ps-1">
                        <button onClick={() => openDropdown(!isDropdown)} data-dropdown-toggle="dropdown" className="dropdown-toggle btn btn-icon rounded-full bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white inline-flex" type="button">
                            {/* <img src={image} className="rounded-full" alt="" /> */}
                            <svg class="absolute w-8 h-8 text-sky-400 -left-1" fill="currentColor" viewBox="0 0 20 20"><path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd"></path></svg>
                        </button>

                        <div className={`dropdown-menu absolute end-0 m-0 mt-4 z-10 w-44 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 ${isDropdown ? 'hidden' : 'block'}`} >
                            <ul className="py-2 text-start">
                                <li>
                                    <Link to="/profile" className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Profile</Link>
                                </li>
                                {/* <li>
                                    <Link to="/candidate-profile-setting" className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><Settings className="h-4 w-4 me-2" />Settings</Link>
                                </li> */}
                                {userInfo && userInfo.userType === 'admin' && goodSubscription && <li>
                                    <Link to="/users" className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Users</Link>
                                </li>}
                                {userInfo && userInfo.userType === 'admin' && goodSubscription && <li>
                                    <Link to="/adduser" className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><User className="h-4 w-4 me-2" />Add User</Link>
                                </li>}
                                <li className="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                                {/* <li>
                                    <Link to="/lock-screen" className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"><Lock className="h-4 w-4 me-2" />Lockscreen</Link>
                                </li> */}
                                <li>
                                    <Link to="/login" className="flex items-center font-medium py-2 px-4 dark:text-white/70 hover:text-emerald-600 dark:hover:text-white"
                                        onClick={() => { logout() }}><LogOut className="h-4 w-4 me-2" />Logout</Link>
                                </li>
                            </ul>
                        </div>
                    </li>}
                </ul>


                <div id="navigation" className={`${isOpen === true ? 'hidden' : 'block'}`}>

                    {!isLoggedIn && <ul className={`navigation-menu ${navClass}`}>
                        <li><Link to="/welcome" className="sub-menu-item text-black">Home</Link></li>
                        <li><Link to="/pricing" className="sub-menu-item text-black">Pricing</Link></li>
                        <li><Link to="/signup" className="sub-menu-item text-black">Create Account</Link></li>
                        <li><Link to="/login" className="sub-menu-item text-black">Login</Link></li></ul>
                    }
                    {isLoggedIn && <ul className={`navigation-menu ${navClass}`}>
                        {/* <li className="has-submenu parent-menu-item">
                            <Link to="#">Home</Link><span className="menu-arrow"></span>
                            <ul className="submenu">
                                <li><Link to="/index" className="sub-menu-item">Hero One</Link></li>
                                <li><Link to="/index-two" className="sub-menu-item">Hero Two</Link></li>
                                <li><Link to="/index-three" className="sub-menu-item">Hero Three</Link></li>
                                <li><Link to="/index-four" className="sub-menu-item">Hero Four</Link></li>
                                <li><Link to="/index-five" className="sub-menu-item">Hero Five</Link></li>
                                <li><Link to="/index-six" className="sub-menu-item">Hero Six </Link></li>
                                <li><Link to="/index-seven" className="sub-menu-item">Hero Seven </Link></li>
                                <li><Link to="/index-eight" className="sub-menu-item">Hero Eight  <span className="bg-yellow-500 inline-block text-white text-[10px] font-bold px-2.5 py-0.5 rounded h-5 ms-1">New</span></Link></li>
                            </ul> 
                        </li> */}
                        {userInfo?.userType !== 'recr' && !goodSubscription && <li><Link to="/pricing" className="sub-menu-item text-black">Pricing</Link></li>}

                        {userInfo?.userType !== 'recr' && goodSubscription && <li><Link to="/home" className="sub-menu-item text-black">Job Search</Link></li>}
                        {userInfo?.userType === 'recr' && <li><Link to="/mylistings" className="sub-menu-item text-black">My Listings</Link></li>}
                        {(userInfo?.userType === 'recr' || goodSubscription) && <li><Link to="/searchcandidates" className="sub-menu-item">Candidate Search</Link></li>}
                        {userInfo?.userType === 'recr' && <li><Link to="/jobpost" className="sub-menu-item">Post Job</Link></li>}
                       {goodSubscription && 
                       <>
                       {userInfo?.userType !== 'recr' && <li className="has-submenu parent-parent-menu-item"><Link to="#"> Jobs </Link><span className="menu-arrow"></span>
                           <ul className="submenu">
                               <li><Link to="/jobpost" className="sub-menu-item">Post Job</Link></li>
                               <li><Link to="/mylistings" className="sub-menu-item">My Listings</Link></li>
                           </ul>
                       </li>}
                       {userInfo?.userType !== 'recr' && <li className="has-submenu parent-menu-item">
                           <Link to="#">Candidates</Link><span className="menu-arrow"></span>
                           <ul className="submenu">
                           <li><Link to="/allcandidates" className="sub-menu-item">My Candidates</Link></li>
                               <li><Link to="/addcandidate" className="sub-menu-item">Add Candidate</Link></li>
                               <li><Link to="/reqaddcandidateform" className="sub-menu-item">Send Candidate Request</Link></li>
                           </ul>
                       </li>}
                       <li><Link to="/jobpost" className="sub-menu-item">Post Job</Link></li>
                       </>} 
                        
                    </ul>}
                </div>
            </div>
        </nav>

    )
}
