import { userConstants } from "../constants";


const initialState = {
    email: '',
    firstName: '',
    lastName: '',
    dob: '',
    isLoggedIn: false,
    userInfo: {},
    userDetails: {}
}

export function whitelistReducer(state = initialState, action) {

    switch (action.type) {
        
        default:
            return state;
    }
}