export const userConstants = {
    RESET_USER_STATE: 'RESET_USER_STATE',
    ATTEMPT_LOGIN: 'ATTEMPT_LOGIN',
    ATTEMPT_LOGIN_SUCCESS: 'ATTEMPT_LOGIN_SUCCESS',
    ATTEMPT_LOGIN_FAILURE: 'ATTEMPT_LOGIN_FAILURE',
    ATTEMPT_LOGOUT: 'ATTEMPT_LOGOUT',
    RESET_LOGIN_ATTEMPT_MESSAGE:'RESET_LOGIN_ATTEMPT_MESSAGE',
    ATTEMPT_USER_REG: 'ATTEMPT_USER_REG',
    ATTEMPT_USER_REG_SUCCESS: 'ATTEMPT_USER_REG_SUCCESS',
    ATTEMPT_USER_REG_FAILURE: 'ATTEMPT_USER_REG_FAILURE',
    ATTEMPT_ORG_REG: 'ATTEMPT_ORG_REG',
    ATTEMPT_ORG_REG_SUCCESS: 'ATTEMPT_ORG_REG_SUCCESS',
    ATTEMPT_ORG_REG_FAILURE: 'ATTEMPT_ORG_REG_FAILURE',
    SAVE_DOB: 'SAVE_DOB',
    USER_REG_STATUS_REQUEST:'USER_REG_STATUS_REQUEST',
    ADD_CANDIDATE_REQUEST:'ADD_CANDIDATE_REQUEST',
    ADD_CANDIDATE_REQUEST_SUCCESS:'ADD_CANDIDATE_REQUEST_SUCCESS',
    ADD_CANDIDATE_REQUEST_FAILURE:'ADD_CANDIDATE_REQUEST_FAILURE',
    UPLOAD_FILES_REQUEST: 'UPLOAD_FILES_REQUEST',
    UPLOAD_FILES_SUCCESS: 'UPLOAD_FILES_SUCCESS',
    UPLOAD_FILES_FAILURE: 'UPLOAD_FILES_FAILURE',
    RESET_UPLOAD_FILES_STATUS: 'RESET_UPLOAD_FILES_STATUS',
    RESET_NEWLY_ADDED_CANDIDATE_STATUS: 'RESET_NEWLY_ADDED_CANDIDATE_STATUS',
    GET_ALL_CANDIDATES:'GET_ALL_CANDIDATES',
    GET_ALL_CANDIDATES_SUCCESS:'GET_ALL_CANDIDATES_SUCCESS',
    GET_ALL_CANDIDATES_FAILURE:'GET_ALL_CANDIDATES_FAILURE',
    GET_CANDIDATE_INFO:'GET_CANDIDATE_INFO',
    GET_CANDIDATE_INFO_SUCCESS:'GET_CANDIDATE_INFO_SUCCESS',
    GET_CANDIDATE_INFO_FAILURE:'GET_CANDIDATE_INFO_FAILURE',
    GET_USERS:'GET_USERS',
    GET_USERS_SUCCESS:'GET_USERS_SUCCESS',
    GET_USERS_FAILURE:'GET_USERS_FAILURE',
    CREATE_NEW_USER:'CREATE_NEW_USER',
    CREATE_NEW_USER_SUCCESS:'CREATE_NEW_USER_SUCCESS',
    CREATE_NEW_USER_FAILURE:'CREATE_NEW_USER_FAILURE',
    DELETE_USER:'DELETE_USER',
    DELETE_USER_SUCCESS:'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE:'DELETE_USER_FAILURE',
    ACTIVATE_USER:'ACTIVATE_USER',
    ACTIVATE_USER_SUCCESS:'ACTIVATE_USER_SUCCESS',
    ACTIVATE_USER_FAILURE:'ACTIVATE_USER_FAILURE',
    STRIPE_CREATE_SUBSCRIPTION:'STRIPE_CREATE_SUBSCRIPTION',
    STRIPE_CREATE_SUBSCRIPTION_SUCCESS:'STRIPE_CREATE_SUBSCRIPTION_SUCCESS',
    STRIPE_CREATE_SUBSCRIPTION_FAILURE:'STRIPE_CREATE_SUBSCRIPTION_FAILURE',
    UPDATE_USER_INFO:'UPDATE_USER_INFO',
    UPDATE_USER_INFO_SUCCESS:'UPDATE_USER_INFO_SUCCESS',
    UPDATE_USER_INFO_FAILURE:'UPDATE_USER_INFO_FAILURE',
    CHANGE_PASSWORD:'CHANGE_PASSWORD',
    CHANGE_PASSWORD_SUCCESS:'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE:'CHANGE_PASSWORD_FAILURE',
    RESET_PASSWORD_CHANGE_MESSAGE:'RESET_PASSWORD_CHANGE_MESSAGE',
    POST_CONTACTUS:'POST_CONTACTUS',
    POST_CONTACTUS_SUCCESS:'POST_CONTACTUS_SUCCESS',
    POST_CONTACTUS_FAILURE:'POST_CONTACTUS_FAILURE',
    CANCEL_SUBSCRIPTION:'CANCEL_SUBSCRIPTION',
    CANCEL_SUBSCRIPTION_SUCCESS:'CANCEL_SUBSCRIPTION_SUCCESS',
    CANCEL_SUBSCRIPTION_FAILURE:'CANCEL_SUBSCRIPTION_FAILURE',
    SEND_ADD_CANDIDATE_REQUEST:'SEND_ADD_CANDIDATE_REQUEST',
    SEND_ADD_CANDIDATE_REQUEST_SUCCESS:'SEND_ADD_CANDIDATE_REQUEST_SUCCESS',
    SEND_ADD_CANDIDATE_REQUEST_FAILURE:'SEND_ADD_CANDIDATE_REQUEST_FAILURE',
    SEARCH_CANDIDATE:'SEARCH_CANDIDATE',
    SEARCH_CANDIDATE_SUCCESS:'SEARCH_CANDIDATE_SUCCESS',
    SEARCH_CANDIDATE_FAILURE:'SEARCH_CANDIDATE_FAILURE',
    UPDATE_JOB_NOTIFICATIONS_STATUS:'UPDATE_JOB_NOTIFICATIONS_STATUS',
    UPDATE_JOB_NOTIFICATIONS_STATUS_SUCCESS:'UPDATE_JOB_NOTIFICATIONS_STATUS_SUCCESS',
    UPDATE_JOB_NOTIFICATIONS_STATUS_FAILURE:'UPDATE_JOB_NOTIFICATIONS_STATUS_FAILURE',
    GET_EMPLOYER_INFO:'GET_EMPLOYER_INFO',
    GET_EMPLOYER_INFO_SUCCESS:'GET_EMPLOYER_INFO_SUCCESS',
    GET_EMPLOYER_INFO_FAILURE:'GET_EMPLOYER_INFO_FAILURE'
}