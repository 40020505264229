import { createAction } from '@reduxjs/toolkit';
import { sampleConstants } from '../constants/sample.constants';


export const saveMyEmail = createAction(
    sampleConstants.SAVE_MY_EMAIL,
    (payload) => ({payload})
);

export const saveDob = createAction(
    sampleConstants.SAVE_DOB,
    (payload) => ({payload})
);