import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Book, EyeOff, Mail, MessageCircle, Phone, User } from 'react-feather';
import {MdOutlineArrowForward, BsQuestionCircle, BsJournalBookmark, HiOutlineCog6Tooth} from "../../assets/icons/vander"
import { useDispatch, useSelector } from 'react-redux';
import { getUserSubscriptionPlanId, userRegRequestSelector } from '../../reducers/selectors/user.selector';
import { attemptUserRegistration, setLoader, userRegStatusRequest } from '../../actions';
import { getOrgIdOfuserSelector, getUserIdSelector, getUserInfoSelector } from '../../reducers/selectors/user.selector';
import { products, serviceConstants } from '../../constants';
import { toast,ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

export default function AddUser() {
    
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [pwdCheck, setPwdCheck] = useState(false);
    const [valid, setValid] = useState(true);
    const [status,setStatus] = useState(false);

    const dispatch = useDispatch();
    const orgId = useSelector(getOrgIdOfuserSelector);
    const userRegReq = useSelector(userRegRequestSelector);
    const userInfo = useSelector(getUserInfoSelector)
    const subscriptionPlanId = useSelector(getUserSubscriptionPlanId);
    const [addUserEligibility, setAddUserEligibility] = useState(false);

    useEffect(() => {
        dispatch(userRegStatusRequest());
        if(subscriptionPlanId){
            const subscribedPlan = products.filter(p=> p.productId === subscriptionPlanId);
            if(subscribedPlan && subscribedPlan[0] && subscribedPlan[0] && userInfo.numOfUsersCreated < subscribedPlan[0].noOfUsers){
                setAddUserEligibility(true)
            }
        }
        
    },[]);
    useEffect(() => {
        if(status){
            if(userRegReq){
                resetAddUser();
                toast.success("User added successfully",{position:toast.POSITION.TOP_RIGHT});
                dispatch(userRegStatusRequest());
            }else{
                toast.error("There is some problem adding User. Please try again.",{position:toast.POSITION.TOP_RIGHT});
            }
            setStatus(false);
        }
        
    },[userRegReq]);

    useEffect(() => {
        console.log('1,,1.,',subscriptionPlanId)

        const subscribedPlan = products.filter(p=> p.productId === subscriptionPlanId);
        console.log('2,,2.,',subscribedPlan)
            if(userInfo.numOfUsersCreated < subscribedPlan.noOfUsers){
                setAddUserEligibility(true)
            }
    },[subscriptionPlanId])
    

    useEffect(() => {
        if(!password && !confirmPassword){
            setPwdCheck(false);
        }
        if(password !== confirmPassword){
            setPwdCheck(true);
        }else{
            setPwdCheck(false);
        }
    },[password, confirmPassword]);

    const resetAddUser = () => {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPassword('');
        setConfirmPassword('');
        setPhone('');
    }

    const createUser = () => {
        console.log('1...',firstName, lastName, email, password, phone, orgId)
        if(subscriptionPlanId){
            if(addUserEligibility){
                if(firstName && lastName && email && password && phone && orgId){
                    console.log('2...')
                    const payload = {
                        firstName,
                        lastName,
                        'orgId':orgId,
                        'emailId': email,
                        'mobile': phone,
                        'password': password,
                        'role': 'user',
                        'createdBy':userInfo.userId
                    }
                    setStatus(true);
                    dispatch(setLoader(true));
                    dispatch(attemptUserRegistration(payload));
                }
            }
        }
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <ToastContainer autoClose={8000}/>
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover"></section>
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px] mx-auto text-center">
                        <div className="lg:col-start-3 lg:col-span-8 md:col-start-2 md:col-span-10">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800 p-6">
                                
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-left">
                                                <label htmlFor="name" className="font-semibold">First Name:</label>
                                                <div className="form-icon relative mt-2">
                                                    <User className="w-4 h-4 absolute top-3 start-4"></User>
                                                    <input name="firstname" id="firstname" type="text" className="form-input ps-11" placeholder="John" required
                                                    value={firstName}
                                                    onChange={(e) => setFirstName(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <div className="text-left">
                                                <label htmlFor="email" className="font-semibold">Last Name:</label>
                                                <div className="form-icon relative mt-2">
                                                <User className="w-4 h-4 absolute top-3 start-4"></User>
                                                    <input name="lastname" id="lastname" type="text" className="form-input ps-11" placeholder="Smith" required
                                                    value={lastName}
                                                    onChange={(e) => setLastName(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <div className="text-left">
                                                <label htmlFor="subject" className="font-semibold">Email (Note: User will receive email for account activation):</label>
                                                <div className="form-icon relative mt-2">
                                                <Mail className="w-4 h-4 absolute top-3 start-4"></Mail>
                                                    <input name="email" id="email" type="email" className="form-input ps-11" placeholder="Email :" required
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-left">
                                                <label htmlFor="subject" className="font-semibold">Mobile:</label>
                                                <div className="form-icon relative mt-2">
                                                <Phone className="w-4 h-4 absolute top-3 start-4"></Phone>
                                                    <input name="phone" id="phone" type="phone" className="form-input ps-11" placeholder="Phone :" required
                                                    value={phone}
                                                    onChange={(e) => setPhone(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-left">
                                                <label htmlFor="subject" className="font-semibold">Password:</label>
                                                <div className="form-icon relative mt-2">
                                                <EyeOff className="w-4 h-4 absolute top-3 start-4"></EyeOff>
                                                    <input name="password" id="password" type="password" className="form-input ps-11" placeholder="" 
                                                    required
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-5">
                                            <div className="text-left">
                                                <label htmlFor="subject" className="font-semibold">Confirm Password: {pwdCheck ? <h5 className="text-red-600">'Passwords should match'</h5>:''}</label>
                                                <div className="form-icon relative mt-2">
                                                <EyeOff className="w-4 h-4 absolute top-3 start-4"></EyeOff>
                                                    <input name="confirmpassword" id="confirmpassword" type="password" className="form-input ps-11" placeholder="" 
                                                    required
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {addUserEligibility && <button type="submit" id="submit" name="send" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white rounded-md justify-center flex items-center"
                                    onClick={() => createUser()}>Create User</button>}
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <Footer />
        </>
    )
}
