import React, { useEffect } from 'react'
import Select from 'react-select'
import Navbar from '../Navbar'
import JobListComp from '../job-list-comp'
import Feature from '../Feature'
import Footer from '../Footer'

import { BiBriefcaseAlt2, PiMapPin } from "../../assets/icons/vander"
import ExploreJob from '../Explore-job';
import { useDispatch, useSelector } from 'react-redux'
import { getAllCandidatesSelector } from '../../reducers/selectors/user.selector'
import { getUserIdSelector } from '../../reducers/selectors/user.selector'
import { getAllCandidates, setLoader } from '../../actions'
import { activateLoaderSelector } from '../../reducers/selectors/util.selector'
import Loader from '../loader'
import CandidatesList from './candidateslist'
import { useNavigate } from 'react-router-dom'
import MessageFullCard from '../MessageFullCard'


export default function AllCandidates() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector(activateLoaderSelector);
    const allCandidates = useSelector(getAllCandidatesSelector);
    const userId = useSelector(getUserIdSelector);

    useEffect(() => {
        dispatch(setLoader(true))
        dispatch(getAllCandidates(userId));
    }, [])

    if (loader) {
        return <Loader />
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover">
            </section>
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
               
                {allCandidates && allCandidates.length > 0 && <CandidatesList
                allCandidates={allCandidates} 
                compFrom={'all'}/>}
                {allCandidates && allCandidates.length == 0 && <div className="container z-1" style={{ paddingBottom: 20 }}>
                        <MessageFullCard
                            headerTitle={'No Candidates Added.'}
                            text={'Add Candidate to apply Job.'} 
                            linkTo={'addcandidate'}
                            buttonText={'Add Candidate'}/>
                    </div>}
            </section>
            
            <br />
            <br />
            <Footer />



        </>
    )
}
