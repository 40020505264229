import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import bg5 from '../../assets/images/hero/bg5.jpg';
import image from '../../assets/images/team/01.jpg';
import shree_logo from '../../assets/images/company/shree-logo.png';
import circle_logo from '../../assets/images/company/circle-logo.png';
import Navbar from '../../components/Navbar'
import CandidateDetailComp from '../../components/candidate-detail-comp';
import Footer from '../../components/Footer';
import { Settings, FileText, Gift, Globe, Home, Mail, MapPin, Phone, Server, Loader } from 'react-feather';
import { LuMail, BiLogoDribbble, AiOutlineBehance, BiLogoLinkedin, FaFacebookF, IoLogoTwitter, FaInstagram, FiFileText } from "../../assets/icons/vander"
import ExploreJob from '../../components/Explore-job';
import { CandidateList } from '../../data/data';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCandidates, getCandidateInfo, getEmployerInfo, setLoader, updateAppliedStatus, uploadFiles } from '../../actions';
import { getAllCandidatesSelector, getEmployerInfoSelector, getSelectedCandidateSelector, getUserIdSelector, isLoggedInSelector } from '../../reducers/selectors/user.selector';
import './canstyle.css'
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';

export default function CandidateDetails() {
    const params = useParams();
    const id = params.id
    const listingId = params.listingId
    const compFrom = params.compFrom
    const location = useLocation();
    let { state } = location;

    useEffect(() => {
        console.log('state..',location,location.state)
    },[state])

    // const candidateList = useSelector(getAllCandidatesSelector);
    const candidate = useSelector(getSelectedCandidateSelector);
    const candidateEmployer = useSelector(getEmployerInfoSelector);

    useEffect(() => {
        console.log('0...', id);
        dispatch(setLoader(true));
        dispatch(getCandidateInfo(id));
    }, [])

    useEffect(() => {
        if(candidate?.userId){
            dispatch(setLoader(true));
            dispatch(getEmployerInfo(candidate.userId));
        }
    },[candidate])

    const data = CandidateList.find((jobs) => jobs.id === parseInt(id));
    const dispatch = useDispatch();
    const userId = useSelector(getUserIdSelector);
    const navigate = useNavigate();
    const loader = useSelector(activateLoaderSelector);
    const isLoggedIn = useSelector(isLoggedInSelector);

    const onFileUpload = (e, docType) => {
        console.log("ON FILE UPDLAO ");
        const formData = new FormData();
        // const encodedFile = fileEncoder(selectedFile)

        if (e.target.files && e.target.files[0] && e.target.files[0].name) {
            formData.append("doc", e.target.files[0], e.target.files[0].name);
        }

        dispatch(setLoader(true));
        dispatch(
            uploadFiles({
                userId,
                candidateId: candidate.id,
                docType: docType,
                formData,
            })
        );
        // navigate('/allcandidates');
    };

    const updateApplied = (status) => {
        dispatch(setLoader(true));
        const payload = {
            candidateId: candidate.id,
            userId: candidate.userId,
            listingId,
            status
        }
        dispatch(updateAppliedStatus(payload));
    }

    const getListingStatus = () => {
        const l = candidate?.appliedListings?.filter(a => a.listingId === listingId);
        console.log('l...', l)
        if(l && l.length > 0){
            return l[0].status;
        }else{
            return null;
        }
    }
    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            {loader && <Loader />}
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover">
                <div className="absolute inset-0 bg-emerald-900/90"></div>

                {isLoggedIn && <div className="absolute text-center z-10 bottom-5 start-0 end-0 mx-3">
                    <ul className="breadcrumb tracking-[0.5px] breadcrumb-light mb-0 inline-block">
                        {compFrom === 'srch' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/searchcandidates">Search Candidates</Link></li>}
                        {compFrom === 'app' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to={`/applications/${listingId}`}>Applications</Link></li>}
                        {compFrom === 'all' && <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white/50 hover:text-white"><Link to={`/allcandidates`}>All Candidates</Link></li>}
                        <li className="inline breadcrumb-item text-[15px] font-semibold duration-500 ease-in-out text-white" aria-current="page">{candidate?.firstName+' Details'} </li>
                    </ul>
                </div>}
            </section>

            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
                <div className="container md:pb-24 pb-16 ">
                    <div className="grid md:grid-cols-12 grid-cols-1 gap-[30px]">
                        <div className="lg:col-span-8 md:col-span-7">
                            <h5 className="text-xl font-semibold">{candidate?.firstName + ' ' + candidate?.lastName}</h5>
                            <div className="canstyle" dangerouslySetInnerHTML={{ __html: candidate?.writeUpOnCandidate }}></div>


                        </div>

                        <div className="lg:col-span-4 md:col-span-5">
                            {listingId === '0' && candidate?.userId === userId && candidate?.appliedListings?.length > 0 && <div className="flex justify-between mb-10">
                                {/* Element on the left edge */}
                                <div className="text-left">
                                    <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-600 border-emerald-600 hover:border-emerald-600 text-white"
                                    >Show Applied Jobs</button>
                                </div>
                            </div>}
                            {userId !== candidate?.userId && listingId !== '0' && getListingStatus() === 'new' && <div className="flex justify-between mb-10">
                                {/* Element on the left edge */}
                                <div className="text-left">
                                    <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-600 border-emerald-600 hover:border-emerald-600 text-white"
                                        onClick={() => updateApplied('accept')}>Accept To Screen</button>
                                </div>

                                {/* Element on the right edge */}
                                <div className="text-right">
                                    <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-100 border-emerald-100 hover:border-emerald-100 text-white"
                                        onClick={() => updateApplied('ignore')}>Ignore</button>

                                </div>
                            </div>}
                            {userId !== candidate?.userId && listingId !== '0' && getListingStatus() !== 'new' && <div className="flex justify-between mb-10">
                                {/* Element on the left edge */}
                                <div className="text-left">
                                    <button type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-600 border-emerald-600 hover:border-emerald-600 text-white"
                                    >{getListingStatus() === 'accept' ? 'Accepted to Screen' : 'Ignored'}</button>
                                </div>
                            </div>}
                            <div className="bg-slate-50 dark:bg-slate-800 rounded-md shadow dark:shadow-gray-700 p-6 sticky top-20 mt-2">
                                
                                {(!candidate?.idDocAttached || !candidate?.resumeAttached || !candidate?.resumeAttached) && <h4 className="mt-6 text-sm text-red-600 font-semibold">*Documents are pending</h4>}
                                <h5 className="text-lg font-semibold">Candidate Details:</h5>
                                <ul className="list-none mt-4">
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail><span className="text-slate-400 me-3">Email  :</span></span>

                                        <span><p className="line-through">{'- - - - - - - -'}</p></span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift><span className="text-slate-400 me-3">D.O.B. :</span></span>

                                        <span>{candidate?.dob}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Home className="h-4 w-4 text-slate-400 me-3 inline"></Home><span className="text-slate-400 me-3">Address :</span></span>

                                        <span>{candidate?.addressLineOne}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><MapPin className="h-4 w-4 text-slate-400 me-3 inline"></MapPin><span className="text-slate-400 me-3">City :</span></span>

                                        <span>{candidate?.city + ' - ' + candidate?.state}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Globe className="h-4 w-4 text-slate-400 me-3 inline"></Globe><span className="text-slate-400 me-3">Country :</span></span>

                                        <span>{candidate?.country}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Server className="h-4 w-4 text-slate-400 me-3 inline"></Server><span className="text-slate-400 me-3">Postal Code :</span></span>

                                        <span>{candidate?.pincode}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Phone className="h-4 w-4 text-slate-400 me-3 inline"></Phone><span className="text-slate-400 me-3">Mobile :</span></span>

                                        <span><p className="line-through">{'- - - - - - - -'}</p></span>
                                    </li>

                                    <li className="flex justify-between mt-3">
                                        <span className="text-slate-400 font-medium">LinkedIn:{candidate?.linkedInUrl}</span>

                                        {/* <ul className="list-none ltr:text-right rtl:text-left space-x-0.5">
                                            <li className="inline"><Link to="https://dribbble.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><BiLogoDribbble/></Link></li>
                                            <li className="inline"><Link to="https://www.behance.net/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><AiOutlineBehance/></Link></li>
                                            <li className="inline"><Link to="http://linkedin.com/company/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><BiLogoLinkedin/></Link></li>
                                            <li className="inline"><Link to="https://www.facebook.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><FaFacebookF/></Link></li>
                                            <li className="inline"><Link to="https://www.instagram.com/shreethemes/" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><FaInstagram/></Link></li>
                                            <li className="inline"><Link to="https://twitter.com/shreethemes" target="_blank" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><IoLogoTwitter/></Link></li>
                                            <li className="inline"><Link to="mailto:support@shreethemes.in" className="btn btn-icon btn-sm border-2 border-gray-200 dark:border-gray-700 rounded-md hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600 hover:text-white dark:text-white text-slate-400"><LuMail/></Link></li>
                                        </ul> */}
                                    </li>

                                    {candidateEmployer && <>
                                    <h5 className="text-lg font-semibold">Employer Details:</h5>
                                <ul className="list-none mt-4">
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Mail className="h-4 w-4 text-slate-400 me-3 inline"></Mail><span className="text-slate-400 me-3">Organization Name  :</span></span>

                                        <span>{candidateEmployer?.orgName}</span>
                                    </li>
                                    {candidateEmployer.firstName && candidateEmployer.lastName && 
                                        <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift><span className="text-slate-400 me-3">Email :</span></span>

                                        <span>{candidateEmployer?.firstName+' '+candidateEmployer?.lastName}</span>
                                    </li>
                                    }
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Gift className="h-4 w-4 text-slate-400 me-3 inline"></Gift><span className="text-slate-400 me-3">Email :</span></span>

                                        <span>{candidateEmployer?.userEmail}</span>
                                    </li>
                                    <li className="flex justify-between mt-3 items-center font-medium">
                                        <span><Home className="h-4 w-4 text-slate-400 me-3 inline"></Home><span className="text-slate-400 me-3">Phone :</span></span>

                                        <span>{candidateEmployer?.userPhone}</span>
                                    </li>
                                    </ul>
                                </>}

                                    {candidate?.idDocAttached && <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">ID Document</span>
                                        </div>

                                        <a href={candidate.idDocUrl} target="_blank" rel="noopener noreferrer" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full flex items-center justify-center" download><FiFileText className='me-2' />Download ID</a>
                                    </li>}
                                    {!candidate?.idDocAttached && <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">Pending - ID Document</span>
                                        </div>
                                        {candidate?.userId === userId && <input id="idcopy" type="file" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder=""
                                            onChange={(e) => onFileUpload(e, 'iddoc')}
                                        />}
                                        {/* <Link to="assets/images/calvin-carlo-resume.pdf" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full flex items-center justify-center" download><FiFileText className='me-2'/> Upload</Link> */}
                                    </li>}
                                    {candidate?.visaCopyAttached && <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">Visa Copy</span>
                                        </div>

                                        <a href={candidate.visaDocUrl} target="_blank" rel="noopener noreferrer" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full flex items-center justify-center" download><FiFileText className='me-2' /> Download Visa Copy</a>
                                    </li>}
                                    {!candidate?.visaCopyAttached && <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">Pending - Visa Copy</span>
                                        </div>

                                        {candidate?.userId === userId && <input id="idcopy" type="file" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder=""
                                            onChange={(e) => onFileUpload(e, 'visacopy')}
                                        />}
                                    </li>}
                                    {candidate?.resumeAttached && <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">Resume</span>
                                        </div>

                                        <a href={candidate.resumeDocUrl} target="_blank" rel="noopener noreferrer" className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 dark:border-emerald-600 text-white rounded-md w-full flex items-center justify-center" download><FiFileText className='me-2' /> Download Resume</a>
                                    </li>}
                                    {!candidate?.resumeAttached && <li className="mt-3 w-full bg-white dark:bg-slate-900 p-3 rounded-md shadow dark:shadow-gray-700">
                                        <div className="flex items-center mb-3">
                                            <FileText className="h-8 w-8 text-slate-400"></FileText>
                                            <span className="font-medium ms-2">Pending - Resume Document</span>
                                        </div>

                                        {candidate?.userId === userId && <input id="idcopy" type="file" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder=""
                                            onChange={(e) => onFileUpload(e, 'resume')}
                                        />}
                                    </li>}

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <CandidateDetailComp /> */}

            </section>
            {/* <section className='md:pb-24 pb-16'>
                <ExploreJob/>
            </section> */}
            <Footer />


        </>
    )
}
