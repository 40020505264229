import facebook_logo from '../assets/images/company/facebook-logo.png';
import google_logo from '../assets/images/company/google-logo.png';
import android from '../assets/images/company/android.png';
import lenovo_logo from '../assets/images/company/lenovo-logo.png';
import spotify from '../assets/images/company/spotify.png';
import linkedin from '../assets/images/company/linkedin.png';
import skype from '../assets/images/company/skype.png';
import snapchat from '../assets/images/company/snapchat.png';
import telegram from '../assets/images/company/telegram.png';
import circle_logo from '../assets/images/company/circle-logo.png';
import shree_logo from '../assets/images/company/shree-logo.png';
import whatsapp from '../assets/images/company/whatsapp.png';

import image from '../assets/images/team/01.jpg';
import image1 from '../assets/images/team/02.jpg';
import image2 from '../assets/images/team/03.jpg';
import image3 from '../assets/images/team/04.jpg';
import image4 from '../assets/images/team/05.jpg';
import image5 from '../assets/images/team/06.jpg';
import image6 from '../assets/images/team/07.jpg';
import image7 from '../assets/images/team/08.jpg';

import blog1 from '../assets/images/blog/01.jpg';
import blog2 from '../assets/images/blog/02.jpg';
import blog3 from '../assets/images/blog/03.jpg';
import blog4 from '../assets/images/blog/04.jpg';
import blog5 from '../assets/images/blog/05.jpg';
import blog6 from '../assets/images/blog/06.jpg';
import blog7 from '../assets/images/blog/07.jpg';
import blog8 from '../assets/images/blog/08.jpg';
import blog9 from '../assets/images/blog/09.jpg';

export const jobData = [
    {
        id:1,
        image: facebook_logo,
        title: 'Facebook',
        day: '2 days ago',
        jobtype: 'Full Time',
        heading: 'Web Designer / Developer',
        Location: 'Australia',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        rating: true,
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:2,
        image: google_logo,
        title: 'Google',
        day: '2 days ago',
        jobtype: 'Part Time',
        heading: 'Marketing Director',
        Location: 'USA',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:3,
        image: android,
        title: 'Android',
        day: '2 days ago',
        jobtype: 'Remote',
        heading: 'Application Developer',
        Location: ' China',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        rating: true,
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:4,
        image: lenovo_logo,
        title: 'Lenovo',
        day: '2 days ago',
        jobtype: 'WFH',
        heading: 'Senior Product Designer',
        Location: 'Dubai',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:5,
        image: spotify,
        title: ' Spotify',
        day: '2 days ago',
        jobtype: 'Full Time',
        heading: 'Web Designer / Developer',
        Location: 'Australia',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:6,
        image: linkedin,
        title: ' Linkedin',
        day: '2 days ago',
        jobtype: 'Remote Time',
        heading: 'Php Developer',
        Location: 'Pakistan',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        rating: true,
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:7,
        image: circle_logo,
        title: ' Circle CI',
        day: '2 days ago',
        jobtype: 'Full Time',
        heading: 'Web Designer / Developer',
        Location: 'Australia',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        rating: true,
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:8,
        image: skype,
        title: ' Skype',
        day: '2 days ago',
        jobtype: 'Full Time',
        heading:  'Marketing Director',
        Location: 'USA',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:9,
        image: snapchat,
        title: ' Snapchat',
        day: '2 days ago',
        jobtype: 'Remote',
        heading:  'Application Developer',
        Location: 'China',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        rating: true,
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:10,
        image: shree_logo,
        title: 'Shreethemes',
        day: '2 days ago',
        jobtype: 'WFT',
        heading:  'Senior Product Designer',
        Location: 'Dubai',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:11,
        image: telegram,
        title: 'C++ Developer',
        day: '2 days ago',
        jobtype: 'Full Time',
        heading:  'C++ Developer',
        Location: 'India',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },
    {
        id:12,
        image: whatsapp,
        title: 'Php Developer',
        day: '2 days ago',
        jobtype: 'Full Time',
        heading:  'Php Developer',
        Location: 'India',
        vacancy: '21 applied of 40 vacancy',
        date: ' 20th Feb 2023',
        price: '$4,000 - $4,500',
        deta: 'Est. time:',
        deta1: ' 1 to 3 months',
        deta2: ' Hourly:',
        deta3: '$16 - $20',
        headline: 'Looking for an experienced Web Designer for an our company.',
        btn: '01,02,03,04,05,06,07',
        verify: 'Verified',
        apply: 'Apply Now',
        detail: 'Total Openings: 1',
    },

]

export const companiesData = [
    {
        id:1,
        image: facebook_logo,
        title: 'Facebook',
        vacancy: 2,
        country: 'Australia',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:2,
        image: google_logo,
        title: 'google_logo',
        vacancy: 2,
        country: 'USA',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:3,
        image: android,
        title: 'Android',
        vacancy: 2,
        country: 'China',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:4,
        image: lenovo_logo,
        title: 'Lenovo',
        vacancy: 2,
        country: 'Dubai',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:5,
        image: spotify,
        title: 'spotify',
        vacancy: 2,
        country: 'Pakistan',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:6,
        image: linkedin,
        title: 'Linkedin',
        vacancy: 5,
        country: 'India',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:7,
        image: circle_logo,
        title: ' Circle CI',
        vacancy: 5,
        country: 'Australia',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:8,
        image: skype,
        title: 'skype',
        vacancy: 2,
        country: 'Rush',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:9,
        image: snapchat,
        title: 'snapchat',
        vacancy: 5,
        country: 'Turkey',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:10,
        image: shree_logo,
        title: 'Shreethemes',
        vacancy: 5,
        country: 'Dubai',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:11,
        image: telegram,
        title: 'C++ Developer',
        vacancy: 5,
        country: 'India',
        desc: 'Digital Marketing Solutions for Tomorrow',
    },
    {
        id:12,
        image: whatsapp,
        title: 'Php Developer',
        vacancy: 5,
        country: 'India',
        desc: 'Digital Marketing Solutions for Tomorrow',
    }
];
 export  const CandidateList = [
        {
            id:1,
            image: image,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        },
        {
            id:2,
            image: image1,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        },
        {
            id:3,
            image: image2,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        },
        {
            id:4,
            image: image3,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        },
        {
            id:5,
            image: image4,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        },
        {
            id:6,
            image: image5,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        },
        {
            id:7,
            image: image6,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        },
        {
            id:8,
            image: image7,
            name: 'Steven Townsend',
            title: 'Web Designer',
            deta1: 'Design',
            deta2: 'UI',
            deta3: 'Digital',
            Salery: 'Salery:',
            price: '$4k - $4.5k',
            Experience: 'Experience:',
            year: '2 Years',
            Profile: 'Profile',
            icon: 'hipchat'
        }
]
export const blogData = [
    {
        id:1,
        image: blog1,
        title: 'arts',
        date: '20th February, 2023',
        time: '5 min read',
        headline: '11 Tips to Help You Get New Clients Through Cold Calling',
        powerdby: ' Google'
    },
    {
        id:2,
        image: blog2,
        title: 'Illustration',
        date: '20th February, 2023',
        time: '5 min read',
        headline: 'DigitalOcean launches first Canadian data centre in Toronto',
        powerdby: ' Facebook'
    },
    {
        id:3,
        image: blog3,
        title: 'Music',
        date: '20th February, 2023',
        time: '5 min read',
        headline: 'Using Banner Stands To Increase Trade Show Traffic',
        powerdby: ' Linkedin'
    },
    {
        id:4,
        image: blog4,
        title: 'arts',
        date: '20th February, 2023',
        time: '5 min read',
        headline: '11 Tips to Help You Get New Clients Through Cold Calling',
        powerdby: ' Google'
    },
    {
        id:5,
        image: blog5,
        title: 'Illustration',
        date: '20th February, 2023',
        time: '5 min read',
        headline: 'DigitalOcean launches first Canadian data centre in Toronto',
        powerdby: ' Facebook'
    },
    {
        id:6,
        image: blog6,
        title: 'Music',
        date: '20th February, 2023',
        time: '5 min read',
        headline: 'Using Banner Stands To Increase Trade Show Traffic',
        powerdby: ' Linkedin'
    },
    {
        id:7,
        image: blog7,
        title: 'arts',
        date: '20th February, 2023',
        time: '5 min read',
        headline: '11 Tips to Help You Get New Clients Through Cold Calling',
        powerdby: ' Google'
    },
    {
        id:8,
        image: blog8,
        title: 'Illustration',
        date: '20th February, 2023',
        time: '5 min read',
        headline: 'DigitalOcean launches first Canadian data centre in Toronto',
        powerdby: ' Facebook'
    },
    {
        id:9,
        image: blog9,
        title: 'Music',
        date: '20th February, 2023',
        time: '5 min read',
        headline: 'Using Banner Stands To Increase Trade Show Traffic',
        powerdby: ' Linkedin'
    }
]