import { createAction } from '@reduxjs/toolkit';
import { listingsConstants } from '../constants';


export const getListings = createAction(
    listingsConstants.LISTINGS_REQUEST
  );
  export const listingsSuccess = createAction(
    listingsConstants.LISTINGS_REQUEST_SUCCESS,
    (payload) => ({ payload })
  );
  export const listingsFailure = createAction(
    listingsConstants.LISTINGS_REQUEST_FAILURE,
    (payload) => ({ payload })
  );

  export const postListings = createAction(
    listingsConstants.POST_LISTINGS_REQUEST,
    (payload) => ({ payload })
  );
  export const postListingsSuccess = createAction(
    listingsConstants.POST_LISTINGS_SUCCESS,
    (payload) => ({ payload })
  );
  export const postListingsFailure = createAction(
    listingsConstants.POST_LISTINGS_FAILURE,
    (payload) => ({ payload })
  ); 
  export const getUserListings = createAction(
    listingsConstants.GET_USER_LISTINGS,
    (payload) => ({ payload })
  );
  export const getUserListingsSuccess = createAction(
    listingsConstants.GET_USER_LISTINGS_SUCCESS,
    (payload) => ({ payload })
  );
  export const getUserListingsFailure = createAction(
    listingsConstants.GET_USER_LISTINGS_FAILURE,
    (payload) => ({ payload })
  ); 
  export const getOrgListings = createAction(
    listingsConstants.GET_ORG_LISTINGS,
    (payload) => ({ payload })
  );
  export const getOrgListingsSuccess = createAction(
    listingsConstants.GET_ORG_LISTINGS_SUCCESS,
    (payload) => ({ payload })
  );
  export const getOrgListingsFailure = createAction(
    listingsConstants.GET_ORG_LISTINGS_FAILURE,
    (payload) => ({ payload })
  ); 
  export const applyListings = createAction(
    listingsConstants.APPLY_LISTING,
    (payload) => ({ payload })
  );
  export const applyListingsSuccess = createAction(
    listingsConstants.APPLY_LISTING_SUCCESS,
    (payload) => ({ payload })
  );
  export const applyListingsFailure = createAction(
    listingsConstants.APPLY_LISTING_FAILURE,
    (payload) => ({ payload })
  ); 
  export const resetApplyListingResponse = createAction(
    listingsConstants.RESET_APPLY_LISTING_RESPONSE,
    (payload) => ({ payload })
  ); 
  export const getAppliedCandidates = createAction(
    listingsConstants.GET_APPLIED_CANDIDATES,
    (payload) => ({ payload })
  );
  export const getAppliedCandidatesSuccess = createAction(
    listingsConstants.GET_APPLIED_CANDIDATES_SUCCESS,
    (payload) => ({ payload })
  ); 
  export const getAppliedCandidatesFailure = createAction(
    listingsConstants.GET_APPLIED_CANDIDATES_FAILURE,
    (payload) => ({ payload })
  );
  export const searchListings = createAction(
    listingsConstants.SEARCH_LISTINGS,
    (payload) => ({ payload })
  );
  export const searchListingsSuccess = createAction(
    listingsConstants.SEARCH_LISTINGS_SUCCESS,
    (payload) => ({ payload })
  ); 
  export const searchListingsFailure = createAction(
    listingsConstants.SEARCH_LISTINGS_FAILURE,
    (payload) => ({ payload })
  );  
  export const resetListingsState = () => ({
    type: listingsConstants.RESET_LISTINGS_STATE,
  });
  export const updateAppliedStatus = createAction(
    listingsConstants.UPDATE_APPLIED_STATUS,
    (payload) => ({ payload })
  ); 
  export const updateAppliedStatusSuccess = createAction(
    listingsConstants.UPDATE_APPLIED_STATUS_SUCCESS,
    (payload) => ({ payload })
  );  
  export const updateAppliedStatusFailure = createAction(
    listingsConstants.UPDATE_APPLIED_STATUS_FAILURE,
    (payload) => ({ payload })
  );
  export const searchListingById = createAction(
    listingsConstants.SEARCH_LISTING_BY_ID,
    (payload) => ({ payload })
  ); 
  export const searchListingByIdSuccess = createAction(
    listingsConstants.SEARCH_LISTING_BY_ID_SUCCESS,
    (payload) => ({ payload })
  );  
  export const searchListingByIdFailure = createAction(
    listingsConstants.SEARCH_LISTING_BY_ID_FAILURE,
    (payload) => ({ payload })
  );
  export const deleteListing = createAction(
    listingsConstants.DELETE_LISTING,
    (payload) => ({ payload })
  ); 
  export const deleteListingSuccess = createAction(
    listingsConstants.DELETE_LISTING_SUCCESS,
    (payload) => ({ payload })
  );  
  export const deleteListingFailure = createAction(
    listingsConstants.DELETE_LISTING_FAILURE,
    (payload) => ({ payload })
  );
  export const editListing = createAction(
    listingsConstants.EDIT_LISTING,
    (payload) => ({ payload })
  ); 
  export const editListingSuccess = createAction(
    listingsConstants.EDIT_LISTING_SUCCESS,
    (payload) => ({ payload })
  );  
  export const editListingFailure = createAction(
    listingsConstants.EDIT_LISTING_FAILURE,
    (payload) => ({ payload })
  );