import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import Navbar from '../Navbar'
import JobListComp from '../job-list-comp'
import Feature from '../Feature'
import Footer from '../Footer'

import { BiBriefcaseAlt2, LuSearch, MdKeyboardArrowLeft, MdKeyboardArrowRight, PiMapPin } from "../../assets/icons/vander"
import ExploreJob from '../Explore-job';
import { useDispatch, useSelector } from 'react-redux'
import { getAllCandidatesSelector, getHasMoreSearchCandidatesSelector } from '../../reducers/selectors/user.selector'
import { getUserIdSelector } from '../../reducers/selectors/user.selector'
import { getAllCandidates, searchCandidate, setLoader } from '../../actions'
import { activateLoaderSelector } from '../../reducers/selectors/util.selector'
import Loader from '../loader'

import { useNavigate } from 'react-router-dom'
import MessageFullCard from '../MessageFullCard'
import CandidatesList from '../allcandidates/candidateslist'
import { User } from 'react-feather'


export default function SearchCandidates() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const loader = useSelector(activateLoaderSelector);
    const allCandidates = useSelector(getAllCandidatesSelector);
    const hasMoreSearchCandidates = useSelector(getHasMoreSearchCandidatesSelector);
    const userId = useSelector(getUserIdSelector);

    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState('');
    const [fromNum, setFromNum] = useState(0);
    const [toNum, setToNum] = useState(0);

    const [numOfListingsPerPage, setNumOfListingsPerPage] = useState(5);
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(4);
    const [numOfPages, setNumOfPages] = useState(1);

    useEffect(() => {
        const payload = {
            pageNum:pageNum-1,
            pageSize,
            searchStr
        }
        dispatch(setLoader(true))
        dispatch(searchCandidate(payload));
    }, [])

    useEffect(() => {
        if(pageNum == 1){
            if(allCandidates.length == pageSize){
                setFromNum(1);
                setToNum(pageSize);
            }else{
                setFromNum(1);
                setToNum(allCandidates.length)
            }
            
        }
        if(pageNum > 1){
            setFromNum(((pageNum-1)*pageSize)+1);
            
            if(allCandidates.length < pageSize){
                setToNum(( (pageNum-1) * pageSize) + allCandidates.length);
            }else{
                setToNum(pageNum * pageSize);
            }
            
        }
        const payload = {
            pageNum:pageNum-1,
            pageSize,
            searchStr
        }
        dispatch(setLoader(true))
        dispatch(searchCandidate(payload));
    },[pageNum])

    useEffect(() => {
        if(pageNum == 1){
            if(allCandidates.length == pageSize){
                setFromNum(1);
                setToNum(pageSize);
            }else{
                setFromNum(1);
                setToNum(allCandidates.length)
            }
            
        }
        if(pageNum > 1){
            setFromNum(((pageNum-1)*pageSize)+1);
            
            if(allCandidates.length < pageSize){
                setToNum(( (pageNum-1) * pageSize) + allCandidates.length);
            }else{
                setToNum(pageNum * pageSize);
            }
            
        }
    },[allCandidates])

    const searchCandidatgeStr = () => {
        const payload = {
            pageNum:pageNum-1,
            pageSize,
            searchStr
        }
        dispatch(setLoader(true))
        dispatch(searchCandidate(payload));
    }

    const increasePageNum = () => {
        setStartIndex((pageNum + 1));
        setPageNum(pageNum + 1);
        
    }
    const decreasePageNum = () => {
        if (pageNum > 0) {
            setPageNum(pageNum - 1);
        }
    }

    return (
        <>
            <Navbar navClass='justify-end nav-light' />
            {loader && <Loader/>}
            <section className="relative table w-full py-16 bg-top bg-no-repeat bg-cover">
            </section>
            <section className="relative bg-slate-50 dark:bg-slate-800 md:py-24 py-16">
            <div className="container z-1" style={{ paddingBottom: 20 }}>
                    <div className="d-flex" id="reserve-form">
                        <div className="md:w-5/6 mx-auto">
                            <div className="lg:col-span-10">
                                <div className="bg-white dark:bg-slate-900 border-0 shadow rounded-md p-3">
                                    <div className="registration-form text-dark text-start">
                                        <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 lg:gap-0 gap-6">
                                            <div className="filter-search-form relative filter-border">
                                                <User className="icons" />
                                                <input name="name" type="text" value={searchStr} id="job-keyword" className="form-input filter-input-box bg-gray-50 dark:bg-slate-800 border-0" placeholder="Search your Keywords like java"
                                                    onChange={(e) => setSearchStr(e.target.value)} />
                                            </div>
                                            <button id="search" name="search" style={{ height: '60px' }} className="btn bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white searchbtn submit-btn w-100" value="Search"
                                                onClick={() => searchCandidatgeStr()} >Search Candidate</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {allCandidates && allCandidates.length > 0 && <div className="flex justify-between mb-10">
                    {/* Element on the left edge */}
                    <div className="text-left">
                    {pageNum > 1 && <button to="#" className="w-[90px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-s-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                            onClick={() => decreasePageNum()}>
                            <MdKeyboardArrowLeft className="text-[20px] rtl:rotate-180 rtl:-mt-1" /> PREV
                        </button>}
                    </div>

                    {allCandidates && allCandidates.length > 0 && <div className="text-center">
                        <p>{'Candidates ( '+fromNum+' - '+toNum+' )'}</p>
                    </div>}

                    {/* Element on the right edge */}
                    <div className="text-right">
                        {hasMoreSearchCandidates && <button to="#" className="w-[90px] h-[40px] inline-flex justify-center items-center text-slate-400 bg-white dark:bg-slate-900 rounded-e-3xl hover:text-white border border-gray-100 dark:border-gray-800 hover:border-emerald-600 dark:hover:border-emerald-600 hover:bg-emerald-600 dark:hover:bg-emerald-600"
                            onClick={() => increasePageNum()}>
                            NEXT <MdKeyboardArrowRight className="text-[20px] rtl:rotate-180 rtl:-mt-1" />
                        </button>}
                    </div>
                </div>}
                {allCandidates && allCandidates.length > 0 && <CandidatesList
                    allCandidates={allCandidates} 
                    compFrom={'srch'}/>}
                {allCandidates && allCandidates.length == 0 && <div className="container z-1" style={{ paddingBottom: 20 }}>
                    <MessageFullCard
                        headerTitle={'No Candidates Added.'}
                        text={'Add Candidate to apply Job.'}
                        linkTo={'addcandidate'}
                        buttonText={'Add Candidate'} />
                </div>}
            </section>

            <br />
            <br />
            <Footer />



        </>
    )
}
