import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import { Bookmark } from 'react-feather';
import {LuMapPin,AiOutlineClockCircle,AiOutlineStar} from "../../assets/icons/vander"
import { jobData } from '../../data/data';



export default function CandidatesList(props) {
   
    const navigate = useNavigate();
    const candidateDetails = (candidateId) => {
        navigate('/cprofile/'+candidateId);
    }

    const getCandidateStatus = (candidateId) => {
        const can = props.allCandidates && props.allCandidates.filter(c => c.id === candidateId);
        if(can && can[0].appliedListings){
            const applied = can[0].appliedListings.filter(a => a.listingId === props.listingId)
            return applied && applied.length > 0 ? applied[0].status : '';
        }
        return ""
    }
    const state = {
        compFrom :'candidateslist'
    }
    return (
        <>

            <div className="container mt-10">
                <div className="grid grid-cols-1 gap-[30px]">
                    {props.allCandidates && props.allCandidates.map((item, index) => (
                        <div className="group relative overflow-hidden md:flex justify-between items-center rounded shadow hover:shadow-md dark:shadow-gray-700 transition-all duration-500 p-5" key={index}>
                            <div className="flex items-center">
                                <div className="w-14 h-14 flex items-center justify-center bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                    <img src={item.image} className="h-8 w-8" alt="" />
                                </div>
                                <Link to={`/cprofile/${item.id}/${props.listingId?props.listingId:'0'}/${props.compFrom}`} className="text-lg hover:text-emerald-600 font-semibold transition-all duration-500 ms-3 min-w-[180px]">{item.firstName+' '+item.lastName}</Link>
                            </div>

                            <div className="md:block flex justify-between md:mt-0 mt-4">
                                <span className="block"><span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">{item.skills && item.skills.join(',')+' ('+item.experience+' Years)'}</span></span>
                                <span className="flex items-center text-slate-400 text-sm md:mt-1 mt-0"><AiOutlineClockCircle className='me-1'/>{item.qualifications && item.qualifications.join(',')}</span>
                            </div>

                            <div className="md:block flex justify-between md:mt-0 mt-2">
                            <span className="block"><span className="bg-emerald-600/10 inline-block text-emerald-600 text-xs px-2.5 py-0.5 font-semibold rounded-full">{'Visa: '+item.visaType}</span></span>
                                <span className="block font-semibold md:mt-1 mt-0">{(!item.city && !item.livingState && !item.country)?'Location not specified':item.city?item.city+'-':''+item.livingState?item.livingState+',':''+item.country?item.country:''}</span>
                            </div>

                            <div className="md:mt-0 mt-4">
                                {!item.bookmarked && <Link to="#" className="btn btn-icon rounded-full bg-emerald-600/5 hover:bg-emerald-600 border-emerald-600/10 hover:border-emerald-600 text-emerald-600 hover:text-white md:relative absolute top-0 end-0 md:m-0 m-3"><Bookmark className="h-4 w-4"></Bookmark></Link>}
                                {item.bookmarked && <Link to="#" className="btn btn-icon rounded-full bg-emerald-600 hover:bg-emerald-600 border-emerald-600 hover:border-emerald-600 text-white hover:text-white md:relative absolute top-0 end-0 md:m-0 m-3"><Bookmark className="h-4 w-4"></Bookmark></Link>}
                                <Link to={`/cprofile/${item.id}/${props.listingId?props.listingId:'0'}/${props.compFrom}`} className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full md:w-auto"
                                 >{'Details'}</Link>
                                 {props.listingId && getCandidateStatus(item.id) !== '' && <Link to={`/cprofile/${item.id}/${props.listingId?props.listingId:'0'}`} className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white md:ms-2 w-full md:w-auto"
                                 >{getCandidateStatus(item.id) === 'accept' ? 'Accepted to Screen':getCandidateStatus(item.id)}</Link>}
                            </div>

                            {item.rating === true ? <span className="w-24 text-white p-1 text-center absolute ltr:-rotate-45 rtl:rotate-45 -start-[30px] top-3 bg-yellow-400 flex justify-center"><AiOutlineStar/></span> :""}
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
