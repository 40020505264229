export function getListingsSelector({ listingsReducer }) {
    if (listingsReducer && listingsReducer.listings && listingsReducer.listings.length > 0) return listingsReducer.listings;
    else return [];
}
export function getOrgListingsSelector({ listingsReducer }) {
    if (listingsReducer && listingsReducer.orgListings && listingsReducer.orgListings.length > 0) return listingsReducer.orgListings;
    else return [];
}
export function getUserListingsSelector({ listingsReducer }) {
    if (listingsReducer && listingsReducer.userListings && listingsReducer.userListings.length > 0) return listingsReducer.userListings;
    else return [];
}
export function getAppliedListingResponseSelector({ listingsReducer }) {
    if (listingsReducer && listingsReducer.appliedListingResponse ) return listingsReducer.appliedListingResponse;
    else return {};
}
export function getAppliedCandidatesSelector({ listingsReducer }) {
    if (listingsReducer && listingsReducer.appliedCandidates ) return listingsReducer.appliedCandidates;
    else return [];
}
export function deletedListingSelector({ listingsReducer }) {
    if (listingsReducer && listingsReducer.deletedListing ) return listingsReducer.deletedListing;
    else return '';
}
export function updatedListingSelector({ listingsReducer }) {
    if (listingsReducer && listingsReducer.updatedListing ) return listingsReducer.updatedListing;
    else return '';
}