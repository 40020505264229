import React, { useEffect, useState } from 'react'
import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'
import { Link, useNavigate, useParams } from "react-router-dom";
import { DollarSign } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';
import { getcandidateInfoSelector } from '../../reducers/selectors/user.selector';
import { addCandidateRequest, postListings, resetcandidateInfo, setLoader, uploadFiles } from '../../actions';
import { activateLoaderSelector } from '../../reducers/selectors/util.selector';
import Loader from '../loader';
import { getOrgIdOfuserSelector, getUserIdSelector } from '../../reducers/selectors/user.selector';
import { Editor, EditorState, convertFromHTML, convertToRaw } from 'draft-js';
import 'draft-js/dist/Draft.css';
import DraftEditor from '../drafteditor';
import Toolbar from '../drafteditor/toolbar';
import { stateToHTML } from 'draft-js-export-html';
import NavbarIndividual from '../NavbarIndividual';
import { serviceConstants } from '../../constants';
import Transport from '../../transport/rabbitboard.transport';
import axios from 'axios';
import { upload } from '@testing-library/user-event/dist/upload';

const getCandidateInfo = async(candidateId) => {
    // const response = await Transport.genericOperation(
    //     serviceConstants.USER_URL +
    //     `/candidate/${candidateId}`,
    //     '',
    //     {},
    //     "GET"
    //   );

     const r =  axios.get(serviceConstants.USER_URL +
        '/candidate/'+candidateId)
      .then(response => {
            return response.data.candidate;
      })
      .catch(err => {
        // Handle errors
        console.error('Error:', err);
        // setError(err);
      });
      return r;
}
export default function ReqAddCandidate() {

    const params = useParams();
    const candidateId = params.candidateId;

    const [candidateInfo, setCandidateInfo] = useState();

    
    const [id, setId] = useState('');
    const [orgId, setOrgId] = useState('');
    const [userId, setUserId] = useState('');
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] =    useState('');
    const [phone, setPhone] = useState('');
    const [dob, setDob] = useState('');
    const [identityType, setIdentityType] = useState('');
    const [identity, setIdentity] = useState('');
    const [social, setSocial] = useState('');
    const [visaType, setVisaType] = useState('');
    const [visaExp, setVisaExp] = useState('');
    const [ifPassportRequired, setIfPassportRequired] = useState(false);
    const [passport, setPassport] = useState('');

    const [description, setDescription] = useState('');
    const [jobType, setJobType] = useState('');
    const [categories, setCategories] = useState('');
    const [postedDate, setPostedDate] = useState('');
    const [salaryType, setSalaryType] = useState('');
    const [salaryRangeFrom, setSalaryRangeFrom] = useState();
    const [salaryRangeTo, setSalaryRangeTo] = useState();
    const [skills, setSkills] = useState('');
    const [qualifications, setQualifications] = useState('');
    const [experience, setExperience] = useState('');
    const [industries, setIndustries] = useState('');
    const [addressLineOne, setAddressLineOne] = useState('');
    const [addressLineTwo, setAddressLineTwo] = useState('');
    const [city, setCity] = useState('');
    const [livingState, setLivingState] = useState('');
    const [country, setCountry] = useState('');
    const [writeUpOnCandidate, setWriteUpOnCandidate] = useState('');

    const [selectedIDFile, setSelectedIDFile] = useState();
    const [selectedVisaFile, setSelectedVisaFile] = useState();
    const [selectedResumeFile, setSelectedResumeFile] = useState();

    const loader = useSelector(activateLoaderSelector);
    const [documentStage, setDocumentStage] = useState(false);
    const [verifyDisable, setVerifyDisable] = useState(true);
    const [candidateInProgress, setCandidateInprogress] = useState();
    const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
    const [status,setStatus] = useState();

    const dispatch = useDispatch();

    const usaStates = 'Alabama, Alaska, Arizona, Arkansas, California, Colorado, Connecticut, Delaware, Florida, Georgia, Hawaii, Idaho, Illinois, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maine, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Montana, Nebraska, Nevada, New Hampshire, New Jersey, New Mexico, New York, North Carolina, North Dakota, Ohio, Oklahoma, Oregon, Pennsylvania, Rhode Island, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont, Virginia, Washington, West Virginia, Wisconsin, Wyoming';

    useEffect(() => {
        setDocumentStage(false);
        dispatch(setLoader(false));

        const r =  axios.get(serviceConstants.USER_URL +
            '/candidate/'+candidateId)
          .then(response => {
            setCandidateInfo(response.data.candidate);
          })
          .catch(err => {
            setCandidateInfo('');
          });
    }, []);

    useEffect(() => {
        if(candidateInfo){
            setId(candidateInfo.id);
            setUserId(candidateInfo.userId);
            setOrgId(candidateInfo.orgId);
        setFirstName(candidateInfo.firstName);
        setLastName(candidateInfo.lastName);
        setMiddleName(candidateInfo.middleName);
        setEmail(candidateInfo.email);
        setDob(candidateInfo.dob);
        setIdentity(candidateInfo.identity);
        setIdentityType(candidateInfo.identityType);
        setSocial(candidateInfo.setSocial);
        setVisaType(candidateInfo.visaType);
        setVisaExp(candidateInfo.visaExp);
        setIfPassportRequired(candidateInfo.ifPassportRequired);
        setPassport(candidateInfo.passport);
        setAddressLineOne(candidateInfo.addressLineOne);
        setAddressLineTwo(candidateInfo.addressLineTwo);
        setCategories(candidateInfo.categories);
        setCity(candidateInfo.city);
        setCountry(candidateInfo.country);
        setExperience(candidateInfo.experience);
        setIndustries(candidateInfo.industries);
        setPostedDate(candidateInfo.postedDate);
        setSalaryRangeFrom(candidateInfo.salaryRangeFrom);
        setSalaryRangeTo(candidateInfo.salaryRangeTo);
        setQualifications(candidateInfo.qualifications);
        setSalaryType(candidateInfo.salaryType);
        setLivingState(candidateInfo.livingState);
        setWriteUpOnCandidate(candidateInfo.writeUpOnCandidate);

        if(candidateInfo.status === 'documentstage' && 
            candidateInfo.idDocAttached && candidateInfo.visaCopyAttached && candidateInfo.resumeAttached){
                candidateInfo.status = 'completed';
                updateFinal(candidateInfo);
        }
        }
        
    },[candidateInfo])

    useEffect(() => {
        if (visaType && visaType !== 'c' && visaType !== 'g') {
            setIfPassportRequired(true);
        } else {
            setIfPassportRequired(false);
        }
    }, [visaType]);

    useEffect(() => {
        if (firstName && lastName && dob
            && identityType !== ''
            && identity !== ''
            && addressLineOne !== ''
            && city !== ''
            && livingState !== ''
        ) {

            setVerifyDisable(false);
        }
    }, [firstName, lastName, dob, identityType, identity, addressLineOne, city, livingState])

    const updateFinal = (candidate) => {
        
        const payload = {
            userId,
            candidate
        }
        dispatch(setLoader(true));

        axios.post(serviceConstants.USER_URL+'/updatecandidate/'+candidateInfo.id,payload)
        .then(response => {
            setCandidateInfo(response.data.candidate);
        })
        .catch(err => {
            console.error('Error:', err);
          });

        dispatch(setLoader(false));

    }

    const updateCandidate = (stage) => {
        const candidate = {
            id,
            orgId,
            userId,
            firstName,
            lastName,
            middleName,
            phone,
            dob,
            identityType,
            identity,
            visaType,
            visaExp,
            "postedDate": new Date(),
            salaryType,
            salaryRangeFrom,
            salaryRangeTo,
            "skills": skills.split(','),
            "qualifications": qualifications.split(','),
            experience,
            "industries": industries.split(','),
            addressLineOne,
            addressLineTwo,
            city,
            state: livingState,
            "country": 'USA',
            'bookmarked': false,
            writeUpOnCandidate,
            'status':stage
        }
        const payload = {
            userId,
            candidate
        }
        dispatch(setLoader(true));

        axios.post(serviceConstants.USER_URL+'/updatecandidate/'+candidateInfo.id,payload)
        .then(response => {
            setCandidateInfo(response.data.candidate);
        })
        .catch(err => {
            console.error('Error:', err);
          });

        dispatch(setLoader(false));

    }

    const resetJobForm = () => {
        setMiddleName('');
        setDob('');
        setIdentity('');
        setIdentityType('');
        setSocial('');
        setVisaType('');
        setVisaExp('');
        setIfPassportRequired(false);
        setPassport('');
        setAddressLineOne('');
        setAddressLineTwo('');
        setCategories('');
        setCity('');
        setCountry('');
        setExperience('');
        setIndustries('');
        setPostedDate('');
        setSalaryRangeFrom('');
        setSalaryRangeTo('');
        setQualifications('');
        setSalaryType('');
        setLivingState('');
    }

    const getVisaType = (val) => {
        if (val === 'c') {
            return 'Citizenship';
        } else if (val === 'g') {
            return 'Green Card';
        } else if (val === 'h1b') {
            return 'H1B';
        } else if (val === 'h4ead') {
            return 'H4EAD';
        } else if (val === 'l2s') {
            return 'L2S';
        } else if (val === 'other') {
            return '';
        }
    }

    const getIdentityType = (val) => {
        if (val === 'c') {
            return 'Citizenship';
        } else if (val === 'g') {
            return 'Green Card';
        } else if (val === 'dl') {
            return 'Drivers License';
        } else if (val === 'sid') {
            return 'State ID';
        }
    }

    const onIDFileChange = (e) => {
        const formData = new FormData();
        if (e.target.files && e.target.files[0] && e.target.files[0].name) {
            formData.append("doc", e.target.files[0], e.target.files[0].name);
        }
        const payload = {
            userId,
            candidateId: id,
            docType:'iddoc',
            formData
        }
        dispatch(setLoader(true));
        uploadFile(payload);
        dispatch(setLoader(false));
    };

    const uploadFile = (payload) =>{

        axios.put(serviceConstants.USER_URL +
          "/uploaddocument/" +
          payload.userId +
          "/" +
          payload.candidateId +
          "/" +
          payload.docType, payload.formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
          .then(response => {
            console.log('File uploaded successfully:', response.data);

          })
          .catch(error => {
            console.error('Error uploading file:', error.message);
          });
      }

    const onVisaFileChange = (e) => {
        const formData = new FormData();
        if (e.target.files && e.target.files[0] && e.target.files[0].name) {
            formData.append("doc", e.target.files[0], e.target.files[0].name);
        }
        const payload = {
            userId,
            candidateId: id,
            docType:'visaCopy',
            formData
        }
        dispatch(setLoader(true));
        uploadFile(payload);
        dispatch(setLoader(false));
    };

    const onResumeFileChange = (e) => {
        const formData = new FormData();
        if (e.target.files && e.target.files[0] && e.target.files[0].name) {
            formData.append("doc", e.target.files[0], e.target.files[0].name);
        }
        const payload = {
            userId,
            candidateId: id,
            docType:'resume',
            formData
        }
        dispatch(setLoader(true));
        uploadFile(payload);
        dispatch(setLoader(false));
    };

    const navigate = useNavigate();

    const onFileUpload = () => {
        const formData = new FormData();
        // const encodedFile = fileEncoder(selectedFile)

        if (selectedIDFile && selectedIDFile.name) {
            formData.append("idCopy", selectedIDFile, selectedIDFile.name);
        }
        if (selectedVisaFile && selectedVisaFile.name) {
            formData.append("visaCopy", selectedVisaFile, selectedVisaFile.name);
        }
        if (selectedResumeFile && selectedResumeFile.name) {
            formData.append("resumeCopy", selectedResumeFile, selectedResumeFile.name);
        }

        dispatch(setLoader(true));
        dispatch(
            uploadFiles({
                userId,
                candidateId: id,
                formData,
            })
        );
        navigate('/allcandidates');
    };

    const navigateToAll = () => {
        navigate('/allcandidates');
    }

    const handleEditor = (newEditorState) => {
        setEditorState(newEditorState);
    };

    // if (loader) {
    //     return <Loader />
    // }

    return (
        <>
            <NavbarIndividual navClass='justify-end' isContainerFluid={true} />
            {loader && <Loader/>}
            <section className="relative bg-white-50 dark:bg-white-800 lg:py-24 py-16">
                <div className="container">
                    <div className="lg:flex justify-center">
                        {candidateInfo && candidateInfo.status === 'new' && <div className="lg:w-2/3">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <div className="grid grid-cols-1">
                                    <h5 className="text-lg font-semibold">Candidate Details:</h5>
                                </div>
                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">First Name:</label>
                                        <input value={firstName} required id="fristName" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder=""
                                            onChange={(e) => { setFirstName(e.target.value) }} />

                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Middle Name:</label>
                                        <input value={middleName} required id="middleName" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder=""
                                            onChange={(e) => { setMiddleName(e.target.value) }} />

                                    </div>


                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Last Name:</label>
                                        <input value={lastName} required id="lastName" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder=""
                                            onChange={(e) => { setLastName(e.target.value) }} />

                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">email:</label>
                                        <input disabled={true} value={email} required id="email" type="email" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder="email"
                                            onChange={(e) => { setEmail(e.target.value) }} />

                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Phone:</label>
                                        <input value={phone} required id="phone" type="number" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder="2121212121"
                                            onChange={(e) => { setPhone(e.target.value) }} />

                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Date of Birth:</label>
                                        <input value={dob} required id="middleName" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder="MM-DD-YYYY"
                                            onChange={(e) => { setDob(e.target.value) }} />
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">ID Type:</label>
                                        <select value={identityType} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => setIdentityType(e.target.value)}>
                                            <option value=""></option>
                                            <option value="dl">Drivers License</option>
                                            <option value="sid">State ID</option>
                                            <option value="c">Citizenship Card</option>
                                            <option value="g">Green Card</option>
                                        </select>

                                    </div>
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">ID:</label>
                                        <input value={identity} required id="identity" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder=""
                                            onChange={(e) => { setIdentity(e.target.value) }} />
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Visa:</label>
                                        <select value={visaType} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => { setVisaType(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="c">Citizen</option>
                                            <option value="g">Green Card</option>
                                            <option value="h1b">H1B</option>
                                            <option value="h4ead">H4EAD</option>
                                            <option value="l2s">L2S</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Visa Expiry Date:</label>
                                        <input value={visaExp} required id="visaExp" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder="MM-DD-YYYY"
                                            onChange={(e) => { setVisaExp(e.target.value) }} />
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Last 4 digits of Social :</label>
                                        <input value={social} required id="visaExp" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder=""
                                            onChange={(e) => { setSocial(e.target.value) }} />
                                    </div>

                                    {ifPassportRequired && <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Passport Number :</label>
                                        <input value={passport} required id="visaExp" type="text" className="form-input border border-slate-100 dark:border-slate-800 block w-full mt-1" placeholder=""
                                            onChange={(e) => { setPassport(e.target.value) }} />
                                    </div>}
                                </div>
                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Expected Salary Range:</label>
                                        <select value={salaryType} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => { setSalaryType(e.target.value) }}>
                                            <option value=""></option>
                                            <option value="Hourly">Hourly</option>
                                            <option value="Monthly">Monthly</option>
                                        </select>

                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold md:invisible md:block hidden">Min:</label>
                                        <div className="relative mt-1">
                                            <span className="w-10 h-10 bg-slate-50 dark:bg-slate-800 border border-slate-100 dark:border-slate-800 absolute top-0 start-0 overflow-hidden rounded">
                                                <DollarSign className="w-4 h-4 absolute top-3 start-3"></DollarSign>
                                            </span>
                                            <input value={salaryRangeFrom} type="number" className="form-input border border-slate-100 dark:border-slate-800 ps-12" placeholder="min" name="minsalary"
                                                onChange={(e) => { setSalaryRangeFrom(e.target.value) }} />
                                        </div>
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold md:invisible md:block hidden">Max:</label>
                                        <div className="relative mt-1">
                                            <span className="w-10 h-10 bg-slate-50 dark:bg-slate-800 border border-slate-100 dark:border-slate-800 absolute top-0 start-0 overflow-hidden rounded">
                                                <DollarSign className="w-4 h-4 absolute top-3 start-3"></DollarSign>
                                            </span>
                                            <input value={salaryRangeTo} type="number" className="form-input border border-slate-100 dark:border-slate-800 ps-12" placeholder="max" name="maxsalary"
                                                onChange={(e) => { setSalaryRangeTo(e.target.value) }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 mt-8">
                                    <h5 className="text-lg font-semibold">Skills & Experience:</h5>
                                </div>

                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="Skillname">Candidate Skills:</label>
                                        <input value={skills} id="Skillname" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Web Developer"
                                            onChange={(e) => { setSkills(e.target.value) }} />
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="Qualificationname">Qualifications:</label>
                                        <input value={qualifications} id="Qualificationname" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Bachelors,Masters"
                                            onChange={(e) => { setQualifications(e.target.value) }} />
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="Experiencename">Candidate Experience:</label>
                                        <input value={experience} id="Experiencename" type="number" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Experience"
                                            onChange={(e) => { setExperience(e.target.value) }} />
                                    </div>

                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Industries Worked:</label>
                                        <input value={industries} id="Experiencename" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Banking"
                                            onChange={(e) => { setIndustries(e.target.value) }} />
                                    </div>
                                    <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="RegisterName">Write up on Candidate (atleast 10 to 20 lines would help):</label>
                                        <Toolbar editorState={editorState} setEditorState={setEditorState} />
                                        <div style={{ border: '1px solid black', minHeight: 200 }}>
                                            <Editor customStyleMap={{ border: '1px solid black' }} editorState={editorState} onChange={handleEditor} />
                                        </div>
                                    </div>
                                </div>

                                <div className="grid grid-cols-1 mt-8">
                                    <h5 className="text-lg font-semibold">Current Address:</h5>
                                </div>

                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="Address">Address Line 1:</label>
                                        <input value={addressLineOne} id="Address1" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Address Line 1"
                                            onChange={(e) => { setAddressLineOne(e.target.value) }} />
                                    </div>
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold" htmlFor="Address">Address Line 2:</label>
                                        <input value={addressLineTwo} id="Address2" type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="Address Line 2"
                                            onChange={(e) => { setAddressLineTwo(e.target.value) }} />
                                    </div>
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">City:</label>
                                        <input id="city" value={city} type="text" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder="New York"
                                            onChange={(e) => { setCity(e.target.value) }} />
                                    </div>
                                    <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">State:</label>
                                        <select value={livingState} className="form-select form-input border border-slate-100 dark:border-slate-800 block w-full mt-1"
                                            onChange={(e) => { setLivingState(e.target.value) }}>
                                            <option value=""></option>
                                            {usaStates && usaStates.split(',').map(st => (<option value={st}>{st}</option>))}
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-12 gap-4 mt-4">
                                {verifyDisable && <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                    <button disabled={verifyDisable} type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-100 border-emerald-100 hover:border-emerald-100 text-white"
                                        onClick={() => updateCandidate('documentstage')}>Update Your Information</button>
                                </div>}
                                {!verifyDisable && <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                    <button disabled={verifyDisable} type="submit" id="submit" name="send" className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white"
                                        onClick={() => updateCandidate('documentstage')}>Update Your Information</button>
                                </div>}
                                <div className="md:col-span-6 col-span-12 ltr:text-left rtl:text-right">
                                    <button type="submit" id="submit" name="reset" className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white"
                                        onClick={() => resetJobForm()}>Reset</button>
                                </div>
                            </div>
                        </div>}
                        {candidateInfo && candidateInfo.status === 'documentstage' && <div className="lg:w-2/3">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <div className="grid grid-cols-1 mt-8">
                                    <h5 className="text-lg font-semibold">Upload Documents:</h5>
                                </div>
                                <div className="grid grid-cols-12 gap-4 mt-4">
                                {candidateInfo && !candidateInfo.idDocAttached && <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Identity{candidateInfo.identityType && getIdentityType(candidateInfo.identityType) !== 'other' ? ' (' + getIdentityType(candidateInfo.identityType) + ')' : ''}:</label>
                                        <input id="idcopy" type="file" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder=""
                                            onChange={(e) => onIDFileChange(e)} />
                                    </div>}
                                    {candidateInfo && candidateInfo.idDocAttached && <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Identity{candidateInfo.identityType && getIdentityType(candidateInfo.identityType) !== 'other' ? ' (' + getIdentityType(candidateInfo.identityType) + ')' : ''}:</label>
                                        <a href={candidateInfo.idDocUrl} className="underline">Identity Proof</a>
                                    </div>}    
                                    {candidateInfo && !candidateInfo.visaCopyAttached && <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Visa Copy {candidateInfo.visaType && getVisaType(candidateInfo.visaType) !== 'other' ? ' (' + getVisaType(candidateInfo.visaType) + ')' : ''}:</label>
                                        <input id="visacopy" type="file" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder=""
                                            onChange={(e) => onVisaFileChange(e)} />
                                    </div>}
                                    {candidateInfo && candidateInfo.visaCopyAttached && <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Visa Copy {candidateInfo.visaType && getVisaType(candidateInfo.visaType) !== 'other' ? ' (' + getVisaType(candidateInfo.visaType) + ')' : ''}:</label>
                                        <a href={candidateInfo.visaDocUrl} className="underline">Visa Document</a>
                                    </div>}
                                    {candidateInfo && !candidateInfo.resumeDocUrl &&  <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Resume:</label>
                                        <input id="resume" type="file" className="form-input border border-slate-100 dark:border-slate-800 mt-1" placeholder=""
                                            onChange={(e) => onResumeFileChange(e)} />
                                    </div>}
                                    {candidateInfo && candidateInfo.resumeDocUrl &&  <div className="col-span-12 ltr:text-left rtl:text-right">
                                        <label className="font-semibold">Resume:</label>
                                        <a href={candidateInfo.resumeDocUrl} className="underline">Resume</a>
                                    </div>}
                                </div>
                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className="md:col-span-4 col-span-12 ltr:text-left rtl:text-right">
                                        <button type="submit" id="submit" name="reset" className="btn rounded-md bg-emerald-600 hover:bg-emerald-700 border-emerald-600 hover:border-emerald-700 text-white"
                                            onClick={() => {setStatus('completed')}}>Complete</button>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {(candidateInfo && candidateInfo.status === 'completed' || status === 'completed' )&& <div className="lg:w-2/3">
                            <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-700 rounded-md">
                                <div className="grid grid-cols-1 mt-8">
                                    <h5 className="text-lg font-semibold">Upload Documents:</h5>
                                </div>
                                
                                <div className="grid grid-cols-12 gap-4 mt-4">
                                    <div className="md:col-span-4 col-span-12 ltr:text-left rtl:text-right">
                                        This Form request is completed.
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}