import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Toast = ({ message, type }) => {
  const showToast = () => {
    toast[type](message, {
      position: 'top-right',
      autoClose: 3000, // 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <button onClick={showToast} className={`bg-${type}-500 hover:bg-${type}-700 text-white font-bold py-2 px-4 rounded`}>
      Show {type} Toast
    </button>
  );
};

export default Toast;