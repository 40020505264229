import { put, takeLatest } from "redux-saga/effects";
import { userConstants, serviceConstants } from "../constants";
import Transport from "../transport/rabbitboard.transport";
import * as userActions from '../actions/user.action';
import axios from "axios";
import { setLoader } from "../actions";

function* attemptLoginSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/login?uname=${action.payload.uname}&pwd=${action.payload.pwd}`,
      '',
      {},
      "GET"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.attemptLoginSuccess(response));
    } else {
      yield put(userActions.attemptLoginFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.attemptLoginFailure(e));
    yield put(setLoader(false))
    throw e;
  }
}

function* attemptOrgRegistrationSaga(action) {
  try {
    const reqObj = {
      orgName: action.payload.orgName,
      emailId: action.payload.emailId,
      mobile: action.payload.mobile,
      password: action.payload.password,
      role: action.payload.role
    };
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL + `/newaccount`,
      '',
      reqObj,
      "POST"
    );

    if (response && response.message && response.message.code === "200") {
      yield put(userActions.attemptOrgRegistrationSuccess(reqObj.emailId));
    } else {
      yield put(
        userActions.attemptOrgRegistrationFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Registration Request Failed, Please check details and try again"
        )
      );
    }
    yield put(setLoader(false));
  } catch (e) {
    yield put(
      userActions.attemptOrgRegistrationFailure("Request Failed, Please try again.")
    );
    yield put(setLoader(false));
  }
}

function* attemptUserRegistrationSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL + `/newaccount`,
      '',
      action.payload,
      "POST"
    );

    if (response && response.message && response.message.code === "200") {
      yield put(userActions.attemptUserRegistrationSuccess(response));
    } else {
      yield put(
        userActions.attemptUserRegistrationFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Registration Request Failed, Please check details and try again"
        )
      );
    }
    yield put(setLoader(false));
  } catch (e) {
    yield put(
      userActions.attemptUserRegistrationFailure("Request Failed, Please try again.")
    );
  }
}

function* addCandidateSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL + `/addcandidate`,
      '',
      action.payload,
      "POST"
    );

    if (response && response.message && response.message.code === "200") {
      yield put(userActions.addCandidateRequestSuccess(response));
    } else {
      yield put(
        userActions.addCandidateRequestFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Add Candidate Request Failed, Please check details and try again"
        )
      );
    }
    yield put(setLoader(false));
  } catch (e) {
    yield put(
      userActions.addCandidateRequestFailure("Add Candidate Request Failed, Please try again.")
    );
  }
}

function* updateUserInfoSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL + `/modifyuser/${action.payload.userId}`,
      '',
      action.payload,
      "PUT"
    );

    if (response && response.message && response.message.code === "200") {
      yield put(userActions.updateUserInfoSuccess(response));
    } else {
      yield put(
        userActions.updateUserInfoFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Update user info is failed, Please check details and try again"
        )
      );
    }
    yield put(setLoader(false));
  } catch (e) {
    yield put(
      userActions.updateUserInfoFailure("Update user info is Failed, Please try again.")
    );
  }
}

function* updateJobNotificationStatusSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL + `/updatejobnotification/${action.payload}`,
      '',
      {},
      "PUT"
    );

    if (response && response.message && response.message.code === "200") {
      yield put(userActions.updateJobNotificaitonsStatusSuccess(response));
    } else {
      yield put(
        userActions.updateJobNotificaitonsStatusFailure(
          response && response.message && response.message.message
            ? response.message.message
            : "Update job notifications failed, Please check details and try again"
        )
      );
    }
    yield put(setLoader(false));
  } catch (e) {
    yield put(
      userActions.updateJobNotificaitonsStatusFailure("Update job notifications is Failed, Please try again.")
    );
  }
}

function* changePasswordSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL + `/changepassword/${action.payload}`,
      '',
      '',
      "POST"
    );

    if (response && response.message && response.message.code === "200") {
      yield put(userActions.changePasswordSuccess(response));
    } else {
      yield put(
        userActions.changePasswordFailure(response)
      );
    }
    yield put(setLoader(false));
  } catch (e) {
  }
}

function* uploadFilesSaga(action) {

  axios.put(serviceConstants.USER_URL +
    "/uploaddocument/" +
    action.payload.userId +
    "/" +
    action.payload.candidateId +
    "/" +
    action.payload.docType, action.payload.formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
    .then(response => {
      console.log('File uploaded successfully:');
    })
    .catch(error => {
      console.error('Error uploading file:');
    });

  // try {
  //   const response = yield Transport.fileUpload(
  //     serviceConstants.USER_URL +
  //     "/uploaddocument/" +
  //     action.payload.userId +
  //     "/" +
  //     action.payload.candidateId+
  //     "/" +
  //     action.payload.docType,
  //     '',
  //     action.payload.formData,
  //     "POST"
  //   );

  //   console.log("response uploadChildProfilePicSaga ", response);
  //   yield put(
  //     userActions.uploadFilesSuccess(
  //       "Uploaded Successfully"
  //     )
  //   );
  //   yield put(setLoader(false));
  // } catch (e) {
  //   console.error("uploadDocumentSaga Saga ERROR ", e);
  //   yield put(
  //     userActions.uploadFilesFailure(
  //       "PUpload Failed, Please try again."
  //     )
  //   );
  // }
}

function* getAllCandidatesSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/allcandidates/${action.payload}`,
      '',
      {},
      "GET"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.getAllCandidatesSuccess(response));
    } else {
      yield put(userActions.getAllCandidatesFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.getAllCandidatesFailure(e));
    throw e;
  }
}

function* searchCandidatesSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/searchcandidates?pageNum=${action.payload.pageNum}&pageSize=${action.payload.pageSize}&searchStr=${action.payload.searchStr}`,
      '',
      {},
      "GET"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.searchCandidateSuccess(response));
    } else {
      yield put(userActions.searchCandidateFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.searchCandidateFailure(e));
    throw e;
  }
}

function* getCandidateSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/candidate/${action.payload}`,
      '',
      {},
      "GET"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.getCandidateInfoSuccess(response));
    } else {
      yield put(userActions.getCandidateInfoFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.getCandidateInfoFailure(e));
    yield put(userActions.getCandidateInfoFailure(e));
    throw e;
  }
}

function* getUsersSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/users/${action.payload}`,
      '',
      {},
      "GET"
    );
    if (response) {
      yield put(userActions.getUsersSuccess(response));
    } else {
      yield put(userActions.getUsersFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.getUsersFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* getEmployerInfoSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/employer/${action.payload}`,
      '',
      {},
      "GET"
    );
    if (response) {
      yield put(userActions.getEmployerInfoSuccess(response));
    } else {
      yield put(userActions.getEmployerInfoFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.getEmployerInfoFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* deleteUserSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/deleteuser/${action.payload.adminId}/${action.payload.userId}`,
      '',
      '',
      "PUT"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.deleteUserSuccess(response));
    } else {
      yield put(userActions.deleteUserFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.deleteUserFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* activateUserSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/activateuser/${action.payload.adminId}/${action.payload.userId}`,
      '',
      '',
      "PUT"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.deleteUserSuccess(response));
    } else {
      yield put(userActions.deleteUserFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.deleteUserFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* createSubscriptionSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/payment/subscribe`,
      '',
      action.payload,
      "POST"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.createSubscriptionSuccess(response));
    } else {
      yield put(userActions.createSubscriptionFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.createSubscriptionFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* cancelSubscriptionSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/cancelsubscription/${action.payload}`,
      '',
      {},
      "POST"
    );
    if (response) {
      yield put(userActions.cancelSubscriptionSuccess(response));
    } else {
      yield put(userActions.cancelSubscriptionFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.cancelSubscriptionFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* postContactusSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/contactus`,
      '',
      action.payload,
      "POST"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.postContactusSuccess(response));
    } else {
      yield put(userActions.postContactusFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.postContactusFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* sendAddCandidateRequestSaga(action) {
  try {
    const response = yield Transport.genericOperation(
      serviceConstants.USER_URL +
      `/sendcandidatereq`,
      '',
      action.payload,
      "POST"
    );
    if (response && response.message && response.message.code === '200') {
      yield put(userActions.sendAddCandidateSuccess(response));
    } else {
      yield put(userActions.sendAddCandidateFailure(response));
    }
    yield put(setLoader(false))
  } catch (e) {
    yield put(userActions.sendAddCandidateFailure(e));
    yield put(setLoader(false));
    throw e;
  }
}

function* attemptLogoutSaga() {
  userActions.attemptLogout();
}

export default function* loginSaga() {
  try {
    yield takeLatest(userActions.attemptLogin, attemptLoginSaga);
    yield takeLatest(userActions.attemptOrgRegistration, attemptOrgRegistrationSaga);
    yield takeLatest(userActions.attemptUserRegistration, attemptUserRegistrationSaga);
    yield takeLatest(userActions.attemptLogout, attemptLogoutSaga);
    yield takeLatest(userActions.addCandidateRequest, addCandidateSaga);
    yield takeLatest(userActions.uploadFiles, uploadFilesSaga);
    yield takeLatest(userActions.getAllCandidates, getAllCandidatesSaga);
    yield takeLatest(userActions.getCandidateInfo, getCandidateSaga);
    yield takeLatest(userActions.getUsers, getUsersSaga);
    yield takeLatest(userActions.deleteUser, deleteUserSaga);
    yield takeLatest(userActions.activateUser, activateUserSaga);
    yield takeLatest(userActions.createSubscription, createSubscriptionSaga);
    yield takeLatest(userActions.updateUserInfo, updateUserInfoSaga);
    yield takeLatest(userActions.changePassword, changePasswordSaga);
    yield takeLatest(userActions.postContactus, postContactusSaga);
    yield takeLatest(userActions.cancelSubscription, cancelSubscriptionSaga);
    yield takeLatest(userActions.sendAddCandidate, sendAddCandidateRequestSaga);
    yield takeLatest(userActions.searchCandidate, searchCandidatesSaga);
    yield takeLatest(userActions.updateJobNotificaitonsStatus, updateJobNotificationStatusSaga);
    yield takeLatest(userActions.getEmployerInfo, getEmployerInfoSaga);
  } catch {
    yield;
  }
}